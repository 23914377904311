import Parse from "parse"

import { getSection} from "../../reducers/Section/SectionsGeneric"
import { actionWithLoader, getReplacingPath, push, onEnter } from "../Utils/utils"
import { parseLimitRequest } from "../../utils"
import { getSelectedSection} from "../../reducers/Section/SectionsGeneric"
import { createOrUpdateProductionStep, createSection, createStep } from "../../utils/recipes"

const Section = Parse.Object.extend("Section")

export function updateSelectedSectionGeneric(values) {
    return async (dispatch, getState) => {
        const state = getState()
        const selectedSection = getSelectedSection(state) || {}
        dispatch({
            type: "SELECTED_SECTION_GENERIC_UPDATED",
            selectedSectionGeneric: {
                ...selectedSection,
                ...values
            }
        })
    }
}

export function createSectionGeneric(values, sectionGeneric) {
    return actionWithLoader(async (dispatch) => {
        if (values.sections && values.sections.length === 1){
            if (!sectionGeneric) {
                sectionGeneric = await createSection(values.sections[0])
            }
            else {
                sectionGeneric.set("name", values.sections[0].name)
                sectionGeneric.set("description", values.sections[0].description)
                const steps = await createStep(values.sections[0].steps)
                sectionGeneric.set("steps", steps)
                const [productionSteps] = await createOrUpdateProductionStep(values.sections[0].productionSteps, false, true) // isRecipe
                sectionGeneric.set("productionSteps", productionSteps)
                
                sectionGeneric.set("reusable", values.sections[0].reusable)
                sectionGeneric.set("print", values.sections[0].print)
                await sectionGeneric.save(null, global.USE_MASTER_KEY)
            }
        }

        sectionGeneric = await new Parse.Query(Section)
            .equalTo("objectId", sectionGeneric.id)
            .include("steps.ingredients.supplierItem")
            .include("steps.ingredients.cookingMode")
            .first()

        dispatch({
            type: "SECTION_GENERIC_SAVED",
            selectedSectionGeneric: sectionGeneric
        })
        dispatch(loadSectionsGeneric())
    })
}
export function clearSelectedSectionGeneric() {
    return dispatch => {
        dispatch({
            type: "SECTION_GENERIC_SELECTED",
            selectedSectionGeneric: null,
        })
    }
}
export function loadSectionsGeneric() {
    return actionWithLoader(async (dispatch, getState) => {
      const sectionsGeneric = await new Parse.Query(Section)
          .notEqualTo("deleted", true)
          .equalTo("reusable", true)
          .include("steps.ingredients.supplierItem")
          .include("steps.ingredients.cookingMode")
          .ascending("name")
          .limit(parseLimitRequest)
          .find()

        const state = getState()
        const oldSelectedSection = getSelectedSection(state)
        const newSelectedSection = oldSelectedSection ? getSection(state, oldSelectedSection.id) : null

        dispatch({
            type: "SECTIONS_GENERIC_LOADED",
            sectionsGeneric,
            selectedSectionGeneric: newSelectedSection
        })
    })
}

export function onEnterSectionsGeneric(store) {
    return onEnter({
        store,
        actionThunk: loadSectionsGeneric,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function deleteSectionGeneric(section) {
    return actionWithLoader(async (dispatch) => {
        await section.destroy()
        dispatch({
            type: "SECTION_GENERIC_REMOVED"
        })
        dispatch(loadSectionsGeneric())
    })
}

export function onEnterSingleSectionGeneric(store) {
    return async(nextState, replace, callback) => {
        const { params } = nextState
        await store.dispatch(loadSectionsGeneric())
        store.dispatch({
            type: "EXIT_AFTER_SAVE",
            exit: false
        })
        store.dispatch(clearSelectedSectionGeneric())
        if (params) {
            const state = store.getState()
            const section = getSection(state, params.id)

            const relatedSectionsCount = (params.id) ?
                await new Parse.Query(Section)
                    .notEqualTo("deleted", true)
                    .equalTo("reusable", false)
                    .equalTo("parentId", params.id)
                    .include("steps.ingredients.supplierItem")
                    .include("steps.ingredients.cookingMode")
                    .count() :
                null

            store.dispatch({
                type: "SECTION_GENERIC_SELECTED",
                selectedSectionGeneric: section,
                relatedSectionsCount
            })
        }
        callback()
    }
}

/*
 ** Routers
 */
export function showSectionsGenericList() {
    return push("/settings/resources/sections")
}

export function showSectionGeneric (sectionId) {
    if (sectionId)
        return push(`/settings/resources/section/${sectionId}`)
    else
        return push("/settings/resources/section")
}


