import {getData} from ".."
import { packagingCategories, packagingTypes} from "../../utils/packaging"

const defaultState = {
    packagingList: [],
    packagings: [],
    categories: packagingCategories,
    types: packagingTypes,
    // @TODO deprecated, it a parse object, should be a json
    selectedPackaging: null,
    // the json version of selectedPackaging
    packaging: null,
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "PACKAGING_LOADED":
            return {
                ...state,
                packagingList: action.packagingList,
                selectedPackaging: action.selectedPackaging
            }
        case "PACKAGINGS_LOADED":
            return {
                ...state,
                packagings: action.packagings,
            }
        case "PACKAGING_SAVED":
            return {
                ...state,
                selectedPackaging: action.selectedPackaging
            }
        case "SINGLE_PACKAGING_LOADED":
            return {
                ...state,
                packaging: action.packaging
            }
        case "PACKAGING_SELECTED":
            return {
                ...state,
                selectedPackaging: action.selectedPackaging
            }
        case "SELECTED_PACKAGING_UPDATED":
            return {
                ...state,
                selectedPackaging: action.selectedPackaging
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//

export const getPackagingListSelector = (state, errorIfNotFound = false) => {
    return getData(state, "packagingList.packagingList", errorIfNotFound && "No packagings found in packagingList reducer")
}

export const getPackagingsSelector = (state) => state.packagingList.packagings

export const getPackagingSelector = (state) => {
    const packaging = state.packagingList.selectedPackaging
    if (!packaging) return
    return packaging
}

// TODO: rename this later
export const getPackagingJSONSelector = (state) => {
    return state.packagingList.packaging
}

export function getPackaging(state, id) {
    return state.packagingList.packagingList.find(packaging => packaging.id === id)
}

export const getPackagingCategoriesSelector = (state) => {
    return state.packagingList.categories
}

export const getPackagingTypesSelector = (state) => {
    return state.packagingList.types
}
