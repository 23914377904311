import React from "react"

import {
  StyledStepBodyCell,
  StyledStepFirstBodyColumn,
  StyledStepText
} from "../StyledSectionComponents"
import { PRODUCTION_STEPS_COL_WIDTHS, COLS_OFFSETS } from "../../../../utils/constant"
import {COLORS, roundNumber} from "../../../../utils"
import { Box, Stack } from "@mui/material"
import { convertKilosIntoGrams } from "../../../../utils/ressources"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const StepComponentPreview = ({ stepComponent, supplierItem, subComponent = false }) => {
  return (
    <>
      <StyledStepFirstBodyColumn className="flexRow center" leftStep={subComponent ? COLS_OFFSETS.priorStepComponents : COLS_OFFSETS.stepComponents} style={{backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE}}>
        <Stack spacing={1}>
          <Box sx={{ height: 30 }} className="flexRow center">
            <Stack direction="row" spacing={4}>
              <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent} firstCol>{supplierItem && supplierItem.name || "-"}</StyledStepText>
            </Stack>
          </Box>
        </Stack>
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>
          { stepComponent.grossWeight ? roundNumber(convertKilosIntoGrams(stepComponent.grossWeight), 3) : "-" }
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{!subComponent && supplierItem && supplierItem.pricePerKg ? roundNumber(supplierItem.pricePerKg, 2) + " €" : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>
          {!subComponent && (stepComponent.realCost || stepComponent.realCost === 0) ? `${roundNumber(stepComponent.realCost, 3)} €` : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{stepComponent.transformationMode && stepComponent.transformationMode.name ? stepComponent.transformationMode.name : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{stepComponent.transformRate || stepComponent.transformRate === 0 ? stepComponent.transformRate + " %" : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>
          {stepComponent.netWeight || stepComponent.netWeight === 0 ? roundNumber(convertKilosIntoGrams(stepComponent.netWeight), 2) : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledStepText weight={0} fontSize={subComponent ? 12 : 14} disabled={subComponent}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
    </>
  )
}

export default StepComponentPreview
