import React, { useCallback } from "react"

import { Field } from "formik"
import { Autocomplete, Box, Button, FormHelperText, MenuItem, Select, Stack, styled } from "@mui/material"
import { cloneDeep, uniqBy } from "lodash"
import { v4 as uuidv4 } from "uuid"

import { StyledProductionStepInputBase, StyledProductionStepTextField, StyledProductionStepsTextarea, StyledStepBodyCell, StyledStepFirstBodyColumn, StyledStepText } from "../StyledSectionComponents"
import StepNameDescription from "./StepNameDescription"
import { roundNumber } from "../../../../utils"
import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../../utils/constant"
import { TRANSFORMATION_TYPES, getTransformationTypeLabel } from "../../../../utils/supplierItemUtils"
import { STEP_DURATION_UNITS, computeRecipeData, computeSectionData, computeStepData, getDefaultReusableSteps, getDefaultSectionProductionStep, getProductionStepPointerObj, priorStepNoName } from "../../../../utils/recipes"
import RemoveColumn from "../RemoveColumn"
import { convertKilosIntoGrams } from "../../../../utils/ressources"
import { computeProductionStepsNumbers, computeReusableProductionStepsNumbers } from "../../../../actions/Utils/utils"
import FormikErrorMessage from "../../../FormikErrorMessage"
import {
  STEP_DATES,
  getStepDateLabelByValue,
  END_STEP_WEIGHING,
  getEndStepWeighingLabelByValue
} from "../../../../utils/productionStepExecution"
import ReusableStepsAutocomplete from "./ReusableStepsAutocomplete"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const StyledTextFieldName = styled(StyledProductionStepTextField)({
  width: 460,
  "& .MuiInputBase-root": {
    height: "100%"
  }
})

const FormikTextField = ({ field, ...props }) => (
  <StyledProductionStepTextField {...field} {...props} />
)

const FormikTextFieldName = ({ field, ...props }) => (
  <StyledTextFieldName {...field} {...props} />
)

const FormikTextarea = ({ field, ...props }) => (
  <StyledProductionStepsTextarea {...field} {...props} />
)

const autocompleteSx = {
  textField: {
    "& .MuiInput-input": {
      cursor: "pointer"
    }
  }
}

const FormikAutocomplete = ({ form, field, readOnly = false, ...props }) => {
  const { name, value } = field
  const { setFieldValue } = form

  return (
    <Autocomplete
      {...props}
      sx={{ flex: 1, pointer: "cursor" }}
      options={props.options}
      value={value}
      onChange={(_, newValue) => {
        setFieldValue(name, newValue)
      }}
      renderInput={(params) => (
        <StyledProductionStepTextField
          {...params}
          variant="standard"
          fullWidth
          inputProps={{ ...params.inputProps, readOnly }}
          sx={autocompleteSx.textField}
        />
      )}
    />
  )
}

const FormikSelect = ({ form, field, children, handleInputChange, ...props }) => {
  const { name } = field
  const { setFieldValue } = form
  const { handleChange } = props
  const value = props.value || field.value

  const onChange = (e) => {
    const inputValue = e.target.value

    if (handleInputChange) {
      handleInputChange(inputValue)
    }
    if (handleChange) {
      handleChange(inputValue)
      return
    }
    setFieldValue(name, inputValue)
  }

  return (
    <Select
      {...props}
      name={name}
      value={value}
      onChange={onChange}
      variant="standard"
      input={<StyledProductionStepInputBase />}
    >
      {children}
    </Select>
  )
}

const EditableStep = ({
  steps,
  step,
  index,
  isEdition,
  sectionIndex,
  isHover,
  onFieldFocus,
  onFieldBlur,
  onKeyUp,
  hasError,
  machineTypes,
  kitchenAreas,
  setFieldValue,
  computeReusableStepsFormValues,
  isReusable,
  allReusableSteps,
  onClearFocus,
  formValues,
  setValues,
  fromRecipe,
  stepNumbers
}) => {
  // check if the selected value is the same as the option
  const isPointersOptionEqualToValue = (option, value) => {
    if (!value) return false
    return option.objectId === value.objectId
  }

  const _stopPropagation = (event) => event && event.stopPropagation()

  const getFieldName = useCallback(
    (fieldName) => {
      if (isReusable) {
        return `productionSteps[${index}].${fieldName}`
      }
      return `sections[${sectionIndex}].productionSteps[${index}].step.${fieldName}`
    },
    [index, sectionIndex, isReusable]
  )
  
  // change the reusable step name by the last step child name
  const handleNameBlur = (event) => {
    if (isReusable) {
      const lastStep = steps[steps.length - 1]
      const currentStep = steps[index]

      if (currentStep.name && lastStep?.index === currentStep.index) {
        const name = steps[index].name
        setFieldValue("name", name)
      }
    }

    if (!event) return
    onFieldBlur(event)
  }

  const handleAddStep = (index, event = null, defaultStepValues) => {
    const newSteps = [...steps]
    const defaultStep = isReusable ? getDefaultReusableSteps() : getDefaultSectionProductionStep(defaultStepValues)
    newSteps.splice(index + 1, 0, defaultStep)

    // update production steps and step components data
    const newStep = newSteps[index + 1]
    if (newStep) {
      computeStepData(newStep, "stepComponents", !isReusable)
    }

    isReusable ? computeReusableProductionStepsNumbers(newSteps, stepNumbers, 1) : computeProductionStepsNumbers(newSteps, stepNumbers, 1)
    // ------- reusable steps fields ------- //
    if (isReusable) {
      setFieldValue("productionSteps", newSteps)

      // the reusable step name is the second last fullfield step
      if (newSteps[newSteps.length - 2]) {
        const name = newSteps[newSteps.length - 2].name
        setFieldValue("name", name)
      }
    } else {

      // ------- recipe steps fields ------- //
      setFieldValue(`sections[${sectionIndex}].productionSteps`, newSteps)
    }

    _stopPropagation(event)

    return newSteps
  }

  const _removeStep = (index, event = null) => {

    const newSteps = [...steps]

    newSteps.splice(index, 1)
    if (!newSteps.length) {
      const defaultStep = fromRecipe ? getDefaultSectionProductionStep() : getDefaultReusableSteps()
      newSteps.splice(0, 0, defaultStep)
    }

    if (computeReusableStepsFormValues) {
      computeReusableStepsFormValues(newSteps)
    }

    isReusable ? computeReusableProductionStepsNumbers(newSteps, stepNumbers, 1) : computeProductionStepsNumbers(newSteps, stepNumbers, 1)
    // the reusable step name should always be the last step name
    if (fromRecipe) {
      // update current section
      const newFormValues = cloneDeep(formValues)
      const section = { ...newFormValues.sections[sectionIndex], productionSteps: newSteps }
      computeSectionData(section, "productionSteps", "stepComponents", true)
      newFormValues.sections[sectionIndex] = section
      if (fromRecipe) {
        computeRecipeData(newFormValues)
      }
      setValues(newFormValues)
    } else {
      setFieldValue("productionSteps", newSteps)
      const newLastStep = newSteps[newSteps.length - 1]
      // the reusable step name should be the last step
      if (newLastStep) {
        setFieldValue("name", newLastStep?.name)
      }
    }

    if (!event) return
    _stopPropagation(event)
  }

  const handleTransformationChange = (index, value) => {
    if (value !== "COOKING") return

    const defaultValues = {
      transformation: "COOLING",
      stepDate: 0
    }

    // pre-fill the fields
    const createdStep = handleAddStep(index, null, defaultValues)
    const copiedSteps = cloneDeep(createdStep)
    let currentStep = copiedSteps[index]

    // step may be step or step.step
    currentStep = getProductionStepPointerObj(currentStep, fromRecipe)
    currentStep.category = "PRIOR_STEPS"
    const lastStep = copiedSteps[index + 1]
    if (lastStep) {
      const newStepComponents = (currentStep.stepComponents || []).map((stepComponent) => {
        return {
          ...stepComponent,
          index: uuidv4(),
          supplierItem: null,
          priorSteps: currentStep || priorStepNoName,
        }
      })

      if (fromRecipe) {
        copiedSteps[index + 1] = {
          ...lastStep,
          step: {
            ...lastStep.step,
            stepComponents: uniqBy(newStepComponents, (stepComponent) => stepComponent.priorSteps.index)
          }
        }
      } else {
        copiedSteps[index + 1] = {
          ...lastStep,
          stepComponents: uniqBy(newStepComponents, (stepComponent) => stepComponent.priorSteps.index)
        }
      }


      if (fromRecipe) {
        setFieldValue(`sections[${sectionIndex}].productionSteps`, copiedSteps)
        return
      }
      setFieldValue("productionSteps", copiedSteps)
    }
  }

  return (
    <Box
      sx={{
        display: "flex"
      }}
      onClick={_stopPropagation}
    >
      <StyledStepFirstBodyColumn className="flexRow center" sx={{ position: "relative" }} isReusable={isReusable}>
        <Stack direction="column">
          {isHover ? (
            <>
              {/* plus button */}
              <Box className="flexCenter" sx={{ position: "absolute", left: -8, top: 0, bottom: 0 }}>
                <Button onClick={(e) => handleAddStep(index, e)}>
                  <img alt="plus icon" src="/icons/plus-blue.svg" />
                </Button>
              </Box>
              {/* input */}
              <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <StyledStepText>{step.stepNumber}.</StyledStepText>
                    {fromRecipe ? (
                      <ReusableStepsAutocomplete
                        steps={steps}
                        index={index}
                        sectionIndex={sectionIndex}
                        onFieldFocus={onFieldFocus}
                        onFieldBlur={onFieldBlur}
                        onKeyUp={onKeyUp}
                        setFieldValue={setFieldValue}
                        isReusable={isReusable}
                        allReusableSteps={allReusableSteps}
                        onClearFocus={onClearFocus}
                        formValues={formValues}
                        setValues={setValues}
                        fromRecipe={fromRecipe}
                        currentStepFocused={step}
                      />
                    ) : (
                      <Stack direction="column">
                        <Field
                          component={FormikTextFieldName}
                          name={getFieldName("name")}
                          onClick={_stopPropagation}
                          onFocus={onFieldFocus}
                          onBlur={handleNameBlur}
                          onKeyUp={onKeyUp}
                          error={!!hasError(index, "name")}
                        />
                        <FormikErrorMessage
                          name={getFieldName("name")}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Field
                      component={FormikTextarea}
                      name={getFieldName("description")}
                      onClick={_stopPropagation}
                      onFocus={onFieldFocus}
                      onBlur={onFieldBlur}
                      onKeyUp={onKeyUp}
                      error={!!hasError(index, "description")}
                    />
                  </Stack>
                  <FormikErrorMessage
                    name={getFieldName("description")}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <StepNameDescription
              name={step.name}
              description={
                isEdition && step.error ? ("Instructions :" + (step.description ? step.description : "")) : step.description
              }
              stepNumber={step.stepNumber}
            />
          )}
          {/* name error message when not on hover */}
          {!isHover && hasError(index, "name") && (
            <Box sx={{ position: "absolute", left: 145, top: 19, minWidth: 120, }} className="stretchSelf">
              <FormikErrorMessage
                name={getFieldName("name")}
              />
            </Box>
          )}
          {/* description error message when not on hover */}
          {!isHover && hasError(index, "description") && (
            <Box sx={{ position: "absolute", left: 200, bottom: 17, minWidth: 160, }} className="stretchSelf">
              <FormikErrorMessage
                name={getFieldName("description")}
              />
            </Box>
          )}
        </Stack>
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText>{roundNumber(convertKilosIntoGrams(step.grossWeight), 3) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText>
          {step.realCost ? `${roundNumber(step.realCost, 3)} €` : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        {isHover ? (
          <Stack className="flex1">
            <Field
              name={getFieldName("transformation")}
              component={FormikSelect}
              handleInputChange={(value) => handleTransformationChange(index, value)}
            >
              {TRANSFORMATION_TYPES.map(transformation => (
                <MenuItem key={transformation.value} value={transformation.value}>
                  {transformation.label}
                </MenuItem>
              ))}
            </Field>
            <FormikErrorMessage
              name={getFieldName("transformation")}
            />
          </Stack>
        ) : (
          <StyledStepText>
            {getTransformationTypeLabel(step.transformation) || "-"}
          </StyledStepText>
        )}
        {/* show error when not in hover */}
        {!isHover && hasError(index, "transformation") && !step.transformation && (
          <FormHelperText error>{hasError(index, "transformation")}</FormHelperText>
        )}
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText>{roundNumber(convertKilosIntoGrams(step.netWeight), 3) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ kitchenArea ------------ */}
      <StyledStepBodyCell align="left" width={widths[7]}>
        <Stack>
          {isHover ? (
            <Stack className="flex1" direction="column">
              <Field
                name={getFieldName("kitchenArea")}
                component={FormikSelect}
                value={step.kitchenArea?.objectId || ""}
                handleChange={(selectedId) => {
                  const selectedKitchenArea = kitchenAreas.find(kitchenArea => kitchenArea.objectId === selectedId)
                  setFieldValue(getFieldName("kitchenArea"), selectedKitchenArea)
                }}
              >
                {kitchenAreas.map(kitchenArea => (
                  <MenuItem key={kitchenArea.objectId} value={kitchenArea.objectId}>
                    {kitchenArea.name}
                  </MenuItem>
                ))}
              </Field>
              <FormikErrorMessage
                name={getFieldName("kitchenArea")}
              />
            </Stack>
          ) : (
            <StyledStepText>{step.kitchenArea?.name || "-"}</StyledStepText>
          )}
          {/* kitchenArea error message when not on hover */}
          {!isHover && hasError(index, "kitchenArea") && !step.kitchenArea && (
            <FormHelperText error>{hasError(index, "kitchenArea")}</FormHelperText>
          )}
        </Stack>
      </StyledStepBodyCell>
      <StyledStepBodyCell

        align="left"
        width={widths[8]}
      >
        {isHover
          ? (
            <Field
              name={getFieldName("machineType")}
              component={FormikAutocomplete}
              options={machineTypes}
              isOptionEqualToValue={isPointersOptionEqualToValue}
              getOptionLabel={(option) => option.name}
              readOnly
            />
          ) : (
            <StyledStepText>{step.machineType?.name || "-"}</StyledStepText>
          )
        }
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        {isHover ? (
          <Stack>
            <Field
              component={FormikTextField}
              name={getFieldName("machineSetting")}
              onClick={_stopPropagation}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              onKeyUp={onKeyUp}
            />
            <FormikErrorMessage
              name={getFieldName("machineSetting")}
            />
          </Stack>
        ) : hasError(index, "machineSetting") ? (
          <FormikErrorMessage
            name={getFieldName("machineSetting")}
          />
        ) : (
          <StyledStepText>{step.machineSetting || "-"}</StyledStepText>
        )}
      </StyledStepBodyCell>
      {/* ------------ stepDuration ------------ */}
      <StyledStepBodyCell align="left" width={widths[10]}>
        {isHover ? (
          <Stack className="flex1">
            <Field
              type="number"
              component={FormikTextField}
              name={getFieldName("stepDuration")}
              onClick={_stopPropagation}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              onKeyUp={onKeyUp}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
            <FormikErrorMessage
              name={getFieldName("stepDuration")}
            />
          </Stack>
        ) : hasError(index, "stepDuration") ? (
          <FormikErrorMessage
            name={getFieldName("stepDuration")}
          />
        ) : (
          <StyledStepText>{step.stepDuration || "-"}</StyledStepText>
        )}
      </StyledStepBodyCell>
      {/* ------------ stepDurationUnit ------------ */}
      <StyledStepBodyCell align="left" width={widths[11]}>
        {isHover ? (
          <Stack className="flex1">
            <Field
              name={getFieldName("stepDurationUnit")}
              component={FormikSelect}
            >
              {STEP_DURATION_UNITS.map((unit, index) => (
                <MenuItem key={unit + index} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Field>
            <FormikErrorMessage
              name={getFieldName("stepDurationUnit")}
            />
          </Stack>
        ) : hasError(index, "stepDurationUnit") ? (
          <FormikErrorMessage
            name={getFieldName("stepDurationUnit")}
          />
        ) : (
          <StyledStepText>{step.stepDurationUnit || "-"}</StyledStepText>
        )}
      </StyledStepBodyCell>
      {/* step day */}
      <StyledStepBodyCell align="left" width={widths[12]}>
        {isHover ? (
          <Stack className="flex1">
            <Field
              name={getFieldName("stepDate")}
              component={FormikSelect}
            >
              {STEP_DATES.map(stepDate => (
                <MenuItem key={stepDate.value} value={stepDate.value}>
                  {stepDate.label}
                </MenuItem>
              ))}
            </Field>
            <FormikErrorMessage
              name={getFieldName("stepDate")}
            />
          </Stack>
        ) : (
          <StyledStepText>
            {getStepDateLabelByValue(step.stepDate) || "-"}
          </StyledStepText>
        )}
        {/* show error when not in hover */}
        {!isHover && hasError(index, "stepDate") && !step.stepDate && (
          <FormHelperText error>{hasError(index, "stepDate")}</FormHelperText>
        )}
      </StyledStepBodyCell>
      {/* END STEP WEIGHING */}
      <StyledStepBodyCell align="left" width={widths[13]}>
        {isHover ? (
          <Stack className="flex1">
            <Field
              name={getFieldName("endStepWeighing")}
              component={FormikSelect}
            >
              {END_STEP_WEIGHING.map(stepWeighing => (
                <MenuItem key={stepWeighing.value} value={stepWeighing.value}>
                  {stepWeighing.label}
                </MenuItem>
              ))}
            </Field>
            <FormikErrorMessage
              name={getFieldName("endStepWeighing")}
            />
          </Stack>
        ) : (
          <StyledStepText>
            {getEndStepWeighingLabelByValue(step.endStepWeighing) || "-"}
          </StyledStepText>
        )}
        {/* show error when not in hover */}
        {!isHover && hasError(index, "endStepWeighing") && !step.endStepWeighing && (
          <FormHelperText error>{hasError(index, "endStepWeighing")}</FormHelperText>
        )}
      </StyledStepBodyCell>
      {/* -------- delete icon -------- */}
      <RemoveColumn
        type="step"
        isHover={isHover}
        onClick={(e) => _removeStep(index, e)}
      />
    </Box>
  )
}

export default EditableStep
