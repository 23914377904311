import dayjs from "dayjs"
import moment from "moment"
import _cloneDeep from "lodash/cloneDeep"

import { productType } from "./dispatchUtils"
import { getRecipeDescription, getRecipeHeatingInstructions } from "./recipes"
import { KFC_BRANDS, roundNumber } from "../utils"

// TODO: remove this when all the product type const is dynamized (from database)
// the function that will be used is below
export const planningSections = productType.map(elem => {
    return {
        key: elem.value,
        label: elem.label
    }
})

export const getPlanningSections = (productTypesOptions = []) => {
    // const ordered
    const formattedOptions = productTypesOptions.map(option => ({
        key: option.value,
        label: option.label
    }))

    return formattedOptions
}

export const planningBrands = ["ALL", ...KFC_BRANDS.map(brand => brand.name)]

export const sectors = [
    {value: "ALL", label: "Tous les secteurs", enableValue: false},
    {value: "PARIS", label: "Paris", enableValue: true},
    {value: "LYON", label: "Lyon", enableValue: true},
    {value: "BORDEAUX", label: "Bordeaux", enableValue: true},
    {value: "LILLE", label: "Lille", enableValue: true},
    {value: "NANTES", label: "Nantes", enableValue: true},
    {value: "STRASBOURG", label: "Strasbourg", enableValue: true},
    {value: "TOULOUSE", label: "Toulouse", enableValue: true}
]

export const MAPPING_BRAND_PREV_DAY_INFO = {
    "FOODCHERI": {
        "STARTER": "Entrée",
        "BREAD": "Pain",
        "MAIN_COURSE": "Plats",
        "MEAL_PREP": "Mealprep",
        "DESSERT": "Desserts",
        "CHEESE": "Fromages",
        "BREAKFAST": "Petit déjeuner"
    },
    "SEAZON": {
        "MAIN_COURSE": "Plats"
    }
}

export function getPlanningTabsColor(tabValue) {
    const brand = KFC_BRANDS.find(brand => brand.name === tabValue)

    return brand ? brand.color : "#000000"
}

export function pxTOvw (value) {
    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth

    const result = (100*value)/x
    return result + "vw"
}

export function countProduction (tab) {
    let res = 0

    for (const i in tab){
        const current = tab[i]
        for (const j in current.expectedProduction){
            const currentExpectedProduction = current.expectedProduction[j]
            res += Number(currentExpectedProduction.value)
        }
    }

    return res
}

export function getDifficulty(intern, difficultiesGesters, difficulty) {
    const filteredIds = difficultiesGesters.filter(el => el.difficulty === difficulty).map(el => el.objectId)
    const filteredItern = intern.filter(el => filteredIds.includes(el.itemId))
    
    return {recipeNumber: filteredItern.length, totalNumber: countProduction(filteredItern)}
}

export function getAverageDifficulty(difficulty1, difficulty2, difficulty3) {
    const average = (difficulty1 + (2 * difficulty2) + (3 * difficulty3))/(difficulty1 + difficulty2 + difficulty3)
    
    return roundNumber(average, 2) || 0
}

export function getSumGesters(intern, difficultiesGesters) {
    let res = 0
    
    for (const current of intern) {
        const difficultyGesters = difficultiesGesters.find(el => el.objectId === current.itemId)
        
        if (difficultyGesters && difficultyGesters.gesters) {
            let count = 0
            for (const currentExpectedProduction of current.expectedProduction) {
                count += Number(currentExpectedProduction.value)
            }
    
            res += count * difficultyGesters.gesters
        }
    }
    
    return res
}

export function getSumPots(intern, gestersPots) {
    let res = 0

    for (const current of intern) {
        const gesterPots = gestersPots.find(el => el.objectId === current.itemId)

        if (gesterPots) {
            for (const currentExpectedProduction of current.expectedProduction) {
                const totalPots = (currentExpectedProduction.packagingType === "REUSABLE") ?
                    countPots(gesterPots.reusableSubPackaging, currentExpectedProduction) :
                    countPots(gesterPots.subPackaging, currentExpectedProduction)
                res += totalPots * Number(currentExpectedProduction.value)
            }
        }
    }

    return res
}

export function countPots(subPackaging, expectedProductionObject) {
    let res = 0

    if (subPackaging) {
        const brandSubPackaging = subPackaging.find(el => el.brand === expectedProductionObject.brand)

        if (brandSubPackaging && brandSubPackaging.value && brandSubPackaging.value.length) {
            const pots = brandSubPackaging.value.filter(el => el.name.includes("POT") || el.name.includes("pot"))
            res = pots.length
        }
    }

    return res
}

export function countExpectedProduction(expectedProduction) {
    let result = 0

    for (const i in expectedProduction) {
        result += expectedProduction[i].value !== "" ? parseFloat(expectedProduction[i].value) : 0
    }

    return result
}

export function countPackagingCardProdVolume(card) {
    return card.packagingType.map(el => el.packagingProduction).reduce((a, b) => (Number(a) || 0) + (Number(b) || 0), 0)
}

export function createDayInfosFormat (day, daysInfos){
    if (!daysInfos){
        return null
    }

    const currentDayInfos = daysInfos.find(dayInfo => dayInfo.date === day)

    return currentDayInfos ? currentDayInfos.capacities : null
}

export function isInternCapacityIsOk (capacity, intern) {
    if (capacity && capacity !== ""){
        return capacity > intern
    }

    return true
}

export function isAlreadyAdded (data, id) {
    return data.find(el => el.itemId === id)
}

export function createNewProductionCardObject (data) {
    const productionCardObject = {
        id: data.objectId,
        itemId: data.itemId,
        itemType: data.itemType,
        uniqueCode: data.uniqueCode,
        currentAvailableProductionDates: [...data.availableProductionDates],
        allAvailableProductionDates: [{
            id: data.objectId,
            values: data.availableProductionDates
        }],
        brand: [{
            id: data.objectId,
            value: data.brand
        }],
        commercialName: data.commercialName,
        name: data.name,
        dlc: [{
            id: data.objectId,
            value: data.dlc,
            brand: data.brand
        }],
        foodcost: data.foodcost,
        image: data.image,
        saleDate: [{
            id: data.objectId,
            value: data.saleDate,
            brand: data.brand
        }],
        productionDate: data.productionDate,
        packagingDate: [data.packagingDate],
        expectedProduction: [{
            id: data.objectId,
            value: data.expectedProduction,
            brand: data.brand
        }],
        originalCards: [data],
        productType: data.productType,
        kitchenHasBeenPrinted: data.hasOwnProperty("printKitchen"),
        printKitchen: !!data.printKitchen,
        reportHasBeenPrinted: data.hasOwnProperty("printReport"),
        printReport: !!data.printReport,
        lunchbag: data.lunchbag
    }

    return productionCardObject
}

export function createNewPackagingCardObject(data) {
    const packagingCardObject = {
        id: data.objectId,
        itemId: data.itemId,
        itemType: data.productionItems && data.productionItems[0].itemType,
        uniqueCode: data.productionItems && data.productionItems[0].uniqueCode,
        currentAvailablePackagingDates: data.productionItems && data.productionItems.reduce((a, b) => mergeAvailableDates(b.availablePackagingDates, b.availablePackagingDates), []),
        allAvailablePackagingDates: data.productionItems && data.productionItems.map(elm => ({
            id: elm.objectId,
            values: elm.availablePackagingDates
        })),
        packagingType: [{
            id: data.objectId,
            value: data.packagingType,
            packagingProduction: data.packagingProduction,
            packagingDate: data.packagingDate,
            availableDates: data.productionItems && data.productionItems.reduce((a, b) => mergeAvailableDates(b.availablePackagingDates, b.availablePackagingDates), [])
            
        }],
        commercialName: data.productionItems && data.productionItems[0].commercialName,
        name: data.productionItems && data.productionItems[0].name,
        dlc: data.productionItems && data.productionItems.map(elm => ({
            id: elm.objectId,
            value: elm.dlc,
            brand: elm.brand,
            packagingType: data.packagingType,
            packagingId: data.objectId
        })),
        foodcost: data.productionItems && data.productionItems[0].foodcost,
        image: data.productionItems && data.productionItems[0].image,
        saleDate: data.productionItems && data.productionItems.map(elm => ({
            id: elm.objectId,
            value: elm.saleDate,
            packagingType: data.packagingType,
            packagingId: data.objectId
        })),
        productionDate: data.productionItems && data.productionItems.map(elm => elm.productionDate),
        packagingDate: data.packagingDate,
        expectedProduction: data.productionItems && data.productionItems.map(elm => ({
            id: elm.objectId,
            value: elm.expectedProduction,
            packagingId: data.objectId
        })),
        originalCards: [data],
        productType: data.productionItems && data.productionItems[0].productType,
        packagingHasBeenPrinted: data.productionItems && data.productionItems.every(elm => elm.hasOwnProperty("printPackaging")),
        printPackaging: !!(data.productionItems && data.productionItems.every(elm => elm.printPackaging)),
        lunchbag: data.productionItems && data.productionItems[0].lunchbag
    }

    return packagingCardObject
}

export function updatePackagingCardObject(packagingCardObject, data) {
    packagingCardObject.packagingType.push({
        id: data.objectId,
        value: data.packagingType,
        packagingProduction: data.packagingProduction,
        productionItems: data.productionItems,
        packagingDate: data.packagingDate,
        availableDates: data.productionItems && data.productionItems.reduce((a, b) => mergeAvailableDates(b.availablePackagingDates, b.availablePackagingDates), []),
    })

    packagingCardObject.dlc = [...packagingCardObject.dlc, ...(data.productionItems && data.productionItems.map(elm => ({
        id: elm.objectId,
        value: elm.dlc,
        brand: elm.brand,
        packagingType: data.packagingType,
        packagingId: data.objectId
    })))]

    packagingCardObject.expectedProduction = [...packagingCardObject.expectedProduction, ...(data.productionItems && data.productionItems.map(elm => ({
        id: elm.objectId,
        value: elm.expectedProduction,
        brand: elm.brand,
        packagingType: data.packagingType,
        packagingId: data.objectId
    })))]

    packagingCardObject.saleDate = [...packagingCardObject.saleDate, ...(data.productionItems && data.productionItems.map(elm => ({
        id: elm.objectId,
        value: elm.saleDate,
        packagingType: data.packagingType,
        packagingId: data.objectId
    })))]

    packagingCardObject.allAvailablePackagingDates = [...packagingCardObject.allAvailablePackagingDates, ...(data.productionItems && data.productionItems.map(elm => ({
        id: elm.objectId,
        values: elm.availableProductionDates
    })))]

    packagingCardObject.productionDate = [...new Set([...packagingCardObject.productionDate, ...(data.productionItems && data.productionItems.map(elm => elm.productionDate))])]
    packagingCardObject.currentAvailablePackagingDates = data.productionItems && data.productionItems.reduce((a, b) => mergeAvailableDates(b.availablePackagingDates, b.availablePackagingDates), packagingCardObject.currentAvailablePackagingDates)
    packagingCardObject.originalCards.push(data)
    packagingCardObject.packagingHasBeenPrinted = packagingCardObject.packagingHasBeenPrinted && data.productionItems && data.productionItems.every(elm => elm.hasOwnProperty("printPackaging"))
    packagingCardObject.printPackaging = packagingCardObject.printPackaging  && !!(data.productionItems && data.productionItems.every(elm => elm.printPackaging))
}

export function updateProductionCardObject (productionCardObject, data) {
    productionCardObject.brand.push({
        id: data.objectId,
        value: data.brand
    })

    productionCardObject.dlc.push({
        id: data.objectId,
        value: data.dlc,
        brand: data.brand
    })

    productionCardObject.expectedProduction.push({
        id: data.objectId,
        value: data.expectedProduction,
        brand: data.brand
    })

    productionCardObject.saleDate.push({
        id: data.objectId,
        value: data.saleDate,
        brand: data.brand
    })

    productionCardObject.allAvailableProductionDates.push({
        id: data.objectId,
        values: data.availableProductionDates
    })
    if (!productionCardObject.packagingDate.includes(data.packagingDate)) {
        productionCardObject.packagingDate.push(data.packagingDate)
    }
    productionCardObject.currentAvailableProductionDates = mergeAvailableDates(productionCardObject.currentAvailableProductionDates, data.availableProductionDates)

    productionCardObject.originalCards.push(data)

    productionCardObject.kitchenHasBeenPrinted = productionCardObject.kitchenHasBeenPrinted || data.hasOwnProperty("printKitchen")
    productionCardObject.reportHasBeenPrinted = productionCardObject.reportHasBeenPrinted || data.hasOwnProperty("printReport")
    productionCardObject.printReport = productionCardObject.printReport && data.printReport
    productionCardObject.printKitchen = productionCardObject.printKitchen && data.printKitchen
    
    if (!productionCardObject.lunchbag && data.lunchbag){
        productionCardObject.lunchbag = data.lunchbag
    }
}

export function mergeAvailableDates (oldValues, newValues) {
    if (oldValues.length > newValues.length){
        return oldValues.filter(x => newValues.includes(x))
    }
    else {
        return newValues.filter(x => oldValues.includes(x))
    }
}

export function formatSplitModalData (currentCard) {
    const data = []
    
    for (const i in currentCard.saleDate){
        const currentSaleDate = currentCard.saleDate[i]

        for (const currentValue of currentSaleDate.tab){
            const currentExpectedProduction = currentCard.expectedProduction.find(el => el.id === currentValue.id)
            const allAvailableProductionDates = currentCard.allAvailableProductionDates.find(el => el.id === currentValue.id)
            const yesterdayEnable = allAvailableProductionDates.values.includes(moment.utc(currentCard.productionDate).add(-1, "days").startOf("day").valueOf())
            const tomorrowEnable = allAvailableProductionDates.values.includes(moment.utc(currentCard.productionDate).add(1, "days").startOf("day").valueOf())
            const isReusable = currentCard.originalCards.find(el => el.objectId === currentValue.id).isReusable

            data.push({
                id: currentValue.id,
                saleDate: currentValue.value,
                productionDate: currentCard.productionDate,
                initialExpectedProduction: currentExpectedProduction ? currentExpectedProduction.value : 0,
                todayExpectedProduction: currentExpectedProduction ? currentExpectedProduction.value : 0,
                yesterdayExpectedProduction: 0,
                tomorrowExpectedProduction: 0,
                disableYesterday: !yesterdayEnable,
                disableTomorrow: !tomorrowEnable,
                brand: currentValue.brand,
                isReusable: isReusable,
                packagingNumber: currentCard.packagingNumber || 1,
                packagingNumberUnit: currentCard.pakagingNumberUnit || "piece"
            })
        }
    }

    return data
}

export function formatDeleteProductionItemModalData(currentCard) {
    const data = []
    
    for (const currentSaleDate of currentCard.saleDate){
        for (const currentValue of currentSaleDate.tab){
            const currentExpectedProduction = currentCard.expectedProduction.find(el => el.id === currentValue.id)
            const currentOriginalCard = currentCard.originalCards.find(el => el.objectId === currentValue.id)
            
            data.push({
                id: currentValue.id,
                saleDate: currentValue.value,
                brand: currentValue.brand,
                expectedProduction: currentExpectedProduction ? currentExpectedProduction.value : 0,
                isReusable: currentOriginalCard ? currentOriginalCard.isReusable : false
            })
        }
    }
    
    return data
}


export function dataMapping(dayData, initialize=false) {
    const cart = []
    for (const i in dayData[0].cards) {
        const current = dayData[0].cards[i]
        for (const j in current) {
            const card = current[j]
            const productionItems = card.originalCards.map(elem => {
                return {
                    productionItemId: elem.objectId,
                    expectedProduction: elem.expectedProduction
                }})
            cart.push({
                commercialName: card.commercialName,
                uniqueCode: card.uniqueCode,
                data: { id: card.itemId, productionItems: productionItems },
                type: card.itemType === "Recipe" ? "recipe" : "subcontractorProduct",
                totalVolume: initialize ? "" : countExpectedProduction(card.expectedProduction),
            })
        }
    }
    
    return cart
}

export function isExpectedProductionIsInitial (tab) {
    let res = true

    for (const i in tab){
        const current = tab[i]

        if (current.value !== 0){
            res = false
        }
    }

    return res
}

export function computeUpdateBrandExpectedProduction(newValue, currentExpectedProduction, card, multi, brand) {
    const brandExpectedProductions = card.expectedProduction.filter(el => el.brand === brand).sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value < b.value) return 1
        return 0
    })

    const isInit = isExpectedProductionIsInitial(card.expectedProduction.filter(el => el.brand === currentExpectedProduction.brand))
        && isExpectedProductionIsInitial(brandExpectedProductions)

    if (!isInit) {
        let diff

        if (newValue > currentExpectedProduction.value) {
            diff = newValue - currentExpectedProduction.value

            for (const i in brandExpectedProductions) {
                if (diff > 0) {
                    const current = brandExpectedProductions[i]

                    if (current.value - diff >= 0) {
                        current.value -= diff
                        diff = 0
                    } else {
                        diff -= current.value
                        current.value = 0
                    }
                }
            }
        } else {
            diff = currentExpectedProduction.value - newValue

            if (brandExpectedProductions.length > 0){
                brandExpectedProductions[0].value += diff
            }
        }

        multi = true
    }

    return multi
}

export function updateHubsPriorities (hubs) {
    for (const i in hubs) {
        const current = hubs[i]

        current.priority = parseFloat(i) + 1
    }
}

// TODO: remove this when the product types refacto is finished
export const prevsPlanningProductTypes = [
    "MAIN_COURSE",
    "MEAL_PREP",
    "STARTER",
    "DESSERT",
    "CHEESE",
    "BREAKFAST",
    "BREAD"
]

/*export function getWastePercentage(volume){
    switch (true){
        case (volume > 0 && volume <= 50):
            return 0.3
        case (volume > 50 && volume <= 100):
            return 0.22
        case (volume > 100 && volume <= 150):
            return 0.14
        case (volume > 150 && volume <= 200):
            return 0.1
        case (volume > 200 && volume <= 300):
            return 0.08
        case (volume > 300):
            return 0.05
        default:
            return 0
    }
}*/

export function getWastePercentage(volume){
    switch (true){
        case (volume > 0 && volume <= 50):
            return 0
        case (volume > 50 && volume <= 100):
            return 0
        case (volume > 100 && volume <= 150):
            return 0
        case (volume > 150 && volume <= 200):
            return 0
        case (volume > 200 && volume <= 300):
            return 0
        case (volume > 300):
            return 0
        default:
            return 0
    }
}

const HubStatusLabel = {
    "OPEN": "Ouvert",
    "CLOSE": "Fermé",
    "DISABLED": "Pas actif",
    "NOT_YET_OPEN": "Pas encore ouvert",
    "NO_DATA": "Pas de prev"
}

export function getPrevisionHubsStatusLabel(status){
    return HubStatusLabel[status] || ""
}

export function formatProduct(product) {
    const isRecipe = (typeof product.name !== "object")
    let commercialNamesValues, allergensValues

    if (isRecipe) {
        const commercialNames = product && Array.isArray(product.ingredients)
            ? product.ingredients.map(elem => ({commercialName: elem.supplierItem.commercialName, percentage: elem.percentage})).filter(Boolean).sort((a, b) => b.percentage - a.percentage)
            : []

        commercialNamesValues = commercialNames.map(elem => elem.commercialName && elem.commercialName.name && `${elem.commercialName.name}`).filter(Boolean).join(", ")

        const allergens = commercialNames.map(elem => elem.commercialName && elem.commercialName.allergens).flat().filter(Boolean)
        const allergensNames = Array.from(new Set(allergens.map(allergen => allergen.name).filter(Boolean)))
        allergensValues = allergensNames.join(", ")
    }

    if (!isRecipe) {
        const commercialNames = product && Array.isArray(product.subcontractorCommercialNames)
            ? product.subcontractorCommercialNames.map(elem => elem.commercialName).filter(Boolean)
            : []

        commercialNamesValues = product && Array.isArray(product.subcontractorCommercialNames)
            ? product.subcontractorCommercialNames.sort((a, b) => b.pourcentage - a.pourcentage)
                .map(elem =>
                    (elem.commercialName && elem.commercialName.name
                        ? elem.commercialName.name
                        : ""
                    )
                ).join(", ")
            : ""
        const allergens = commercialNames.map(elem => elem.allergens).flat().filter(Boolean)
        const allergensNames = Array.from(new Set(allergens.map(allergen => allergen.name).filter(Boolean)))
        allergensValues = allergensNames.join(", ")
    }

    return {
        isRecipe,
        description: isRecipe?getRecipeDescription(product, product.brands[0]):product.description,
        nutritionInformation: product.nutritionInformation,
        ingredients: commercialNamesValues,
        allergens: allergensValues,
        tags: product.internalTag.filter(elm => elm.type !== "MARKETING"),
        marketingTags: product.internalTag.filter(elm => elm.type === "MARKETING"),
        specialInstruction: product.specialInstruction,
        heatingInstructions: isRecipe?getRecipeHeatingInstructions(product, product.brands[0]):product.heatingInstructions,
        preparation: product.preparation,
        netWeight: product.netWeight
    }
}

/**
 * data example :
 * [
 * {
 *   cards: {
 *     MAIN_COURSE: [
 *       {
 *         id: 'xxx' { // a card id
 *           originalCards: [
 *             {
 *               objectId: 'xxx', // a productionItem id
 *               expectedProduction: 100
 *             }, {
 *               ...
 *             }
 *           ],
 *           expectedProduction: 200
 *         }, {
 *           ..
 *         }
 *       }
 *     ],
 *     YAOURT: [
 *       ...
 *     ]
 *   }
 * }, {
 *   cards: {...}
 * }
 * ]
 *
 * this function creates output data with modified productionItem values
 * @param data the input data
 * @param action
 * @returns {*} output data
 */
export function updateProdDataPlanning(data, action) {
    const newData = _cloneDeep(data)
    newData.forEach(el => {
        action.productionItemIdAndValues.forEach(idAndValue => {
            const {id, value} = idAndValue
            const currentCardData = el.cards[action.card.productType].find(elm =>
                elm.originalCards.some(oCard => id === oCard.objectId)
            )

            if (currentCardData) {
                const oCard = currentCardData.originalCards.find(elm => elm.objectId === id)
                const currentExpectedProduction = currentCardData.expectedProduction.find(elm => elm.id === id)

                if (oCard) oCard.expectedProduction = value
                if (currentExpectedProduction) currentExpectedProduction.value = value
            }
        })
    })
    
    return newData
}


export function updatePackagingDataPlanning(data, action) {
    const newData = _cloneDeep(data)

    newData.forEach(el => {
        const currentCardData = el.cards[action.cardProductType].find(elm => elm.id === action.cardId)
        if (currentCardData) {
            action.productPackagingIdAndValues.forEach(idAndValue => {
                const {id, value} = idAndValue
                const oCard = currentCardData.originalCards.find(elm => elm.objectId === id)
                const currentPackagingType = currentCardData.packagingType.find(elm => elm.id === id)

                if (oCard) oCard.packagingProduction = value
                if (currentPackagingType) currentPackagingType.packagingProduction = value
            })
        }
    })

    return newData
}

export function formatFoodCost(foodCostData) {
	foodCostData.map(data => {
		Object.keys(data).map(key => {
			if (!["date", "dateLabel"].includes(key)) {
				data[key] = (data[key][1] !== 0) ? parseFloat((data[key][0]/data[key][1]).toFixed(2)): 0
			}
			
			return key
		})
		
		return data
	})
	
	return foodCostData
}

export const preparationInfo = [
    {key: "microwave", img: "/img/product/MICROONDE.svg"},
    {key: "oven", img: "/img/product/FOUR.svg"},
    {key: "pan", img: "/img/product/poele.svg"},
    {key: "cold", img: "/img/product/FROID.svg"}
]

export const sourcingInfo = [
    {key: "seasonalIngredientsRate", unit: "%", min: 80, img: "/img/product/SAISON.svg", label: "de légumes et fruits de saison"},
    {key: "certifiedIngredientsRate", unit: "%", min: 70, img: "/img/product/LABEL.svg", label: "d'ingrédients labellisés"},
    {key: "frenchIngredientsRate", unit: "%", min: 70, img: "/img/product/FRANCE.svg", label: "d'ingrédients français"},
    {key: "organicIngredientsRate", unit: "%", min: 60, img: "/img/product/BIO.svg", label: "d'ingrédients bio"},
    {key: "carbonFootPrint", unit: "g", min: 0, img: "/img/product/CO2.svg", label: "d'empreinte CO2"}
]

export const nutrionalValues = [
    {
        nutritionLabel: "Lipides",
        nutritionValueKey: "fat",
        unity: "g",
        indent: false
    },
    {
        nutritionLabel: "Acides gras saturés",
        nutritionValueKey: "saturatedFattyAcids",
        unity: "g",
        indent: true
    },
    {
        nutritionLabel: "Glucides",
        nutritionValueKey: "carbohydrates",
        unity: "g",
        indent: false
    },
    {
        nutritionLabel: "Dont sucres",
        nutritionValueKey: "sugar",
        unity: "g",
        indent: true
    },
    {
        nutritionLabel: "Sucres ajoutés",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "sugarAdd",
        unity: "mg",
        indent: true
    },
    {
        nutritionLabel: "Charge glycémique",
        nutritionValueKey: "glycemicLoad",
        unity: "g",
        indent: true
    },
    {
        nutritionLabel: "Protéines",
        nutritionValueKey: "proteins",
        unity: "g",
        indent: false
    },
    {
        nutritionLabel: "Fibres",
        nutritionValueKey: "fibers",
        unity: "g",
        indent: false
    },
    {
        nutritionLabel: "Sel",
        nutritionValueKey: "salt",
        unity: "g",
        indent: false,
        space: true
    }
]

export const moreNutrionalValues = [
    {
        nutritionLabel: "Calcium",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "calcium",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Cuivre",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "copper",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Fer",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "iron",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Magnesium",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "magnesium",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Manganese",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "manganese",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Phosphore",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "phosphorus",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Potassium",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "potassium",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Selenium",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "selenium",
        unity: "µg",
        indent: false
    },
    {
        nutritionLabel: "Zinc",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "zinc",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Vitamine D",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "vitaminD",
        unity: "µg",
        indent: false
    },
    {
        nutritionLabel: "Vitamine C",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "vitaminC",
        unity: "mg",
        indent: false
    },
    {
        nutritionLabel: "Vitamine A",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "vitaminA",
        unity: "µg ER",
        indent: false
    },
    {
        nutritionLabel: "Omega 6",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "omega6",
        unity: "g",
        indent: false
    },
    {
        nutritionLabel: "Omega 3",
        nutritionValueKey: "per100",
        nutritionSecondValueKey: "omega3",
        unity: "g",
        indent: false
    }
]

/**
 * copy a card from a column to another column
 * the card should be copied only in the same product type
 * each column is a day
 * @param {number} sourceDate
 * @param {number} destinationDate
 * @param {array} oldProdDataPlanning
 * @param {object} cardItem
 */
 export const updateProdDataColumns = (sourceDate, destinationDate, oldProdDataPlanning, cardItem) => {
    const newProdDataPlanning = []
    // get the column from the drag start (source)
    const sourceDay = oldProdDataPlanning.find((day) => day.date === sourceDate)
  
    for (const day of oldProdDataPlanning) {
        // get current card from card list of a day (column)
        const newCard = sourceDay.cards[cardItem.productType].find((card) => card.id === cardItem.id)
        let newDlcDateDiff
        // check if the copy (drag) is backward or forward
        const isNewDateAfterOldDate = dayjs(sourceDate).isAfter(dayjs(destinationDate))
    
        // difference between future and past date
        if (isNewDateAfterOldDate) {
            newDlcDateDiff = dayjs(sourceDate).diff(destinationDate, "day")
        } else {
            newDlcDateDiff = dayjs(destinationDate).diff(sourceDate, "day")
        }
    
        // ----------------------------------- //
        // --------- destination day --------- //
        // ----------------------------------- //
        if (day.date === destinationDate) {
            const newCardWithNewDlcs = {
            ...newCard,
            dlc:
                newCard.dlc.map((dlcItem) => ({
                ...dlcItem,
                value: isNewDateAfterOldDate
                    ? // subtract day if the card is dragged backward
                    dayjs(dlcItem.value).subtract(newDlcDateDiff, "days").valueOf()
                    : // add day if the card is dragged forward
                    dayjs(dlcItem.value).add(newDlcDateDiff, "days").valueOf()
                })) || []
            }
    
            // add the updated card to the day (destination)
            newProdDataPlanning.push({
                ...day,
                cards: {
                    // other product types
                    ...day.cards,
                    // the product type of the current card
                    [cardItem.productType]: [
                        // other cards from this product type
                        ...day.cards[cardItem.productType],
                        // the new card to the source
                        newCardWithNewDlcs
                    ]
                }
            })
            // ----------------------------------- //
            // ------------ source day ----------- //
            // ----------------------------------- //
        } else if (day.date === sourceDate) {
            // remove the dragged card to the day (source)
            newProdDataPlanning.push({
                ...day,
                cards: {
                    ...day.cards,
                    // remove the card from the destination
                    [cardItem.productType]: day.cards[cardItem.productType].filter((card) => card.id !== cardItem.id)
                }
            })
            // ----------------------------------- //
            // ------------ other days ----------- //
            // ----------------------------------- //
        } else {
            newProdDataPlanning.push(day)
        }
    }
    return newProdDataPlanning
}

export const formatProductionItemDataToSend = (cardItem, destinationDate) => {
    const data = []

    for (const index in cardItem.originalCards) {
        const originalCard = cardItem.originalCards[index]

        data.push({
            id: originalCard.objectId,
            newProductionDate: destinationDate
        })
    }

    return data
}

export const packagingSectionsLineCsvHeader = {
    type: "Type",
    uniqueCode: "Code",
    name: "Nom du produit",
    sectionName: "Nom de la section",
    sectionNetWeight: "Poids de la section",
    sectionCost: "Prix de la section",
    sectionPricePerWeight: "Prix au kg de la section",
}

/**
 * 
 * @param {*} selectedDate date start of the week (always a monday)
 * @param {*} batchDay ex: 1, 2, ..., 7, 5-1, 6-1, ...
 * @returns 
 */
export const getPlanningDateByProductionSchemaDate = (selectedDate, batchDay) => {
    const splittedDays = batchDay.split("-")
    const dayNumber = splittedDays[0] // 5, 6, 7, ...
    // number of week earlier, ex: 1 = 1 week earlier
    const dayWeekNumber = splittedDays[1] 

    let date = moment.utc(selectedDate).isoWeekday(parseInt(dayNumber)).startOf("day")
    if (dayWeekNumber) {
        date = moment.utc(date).subtract(parseInt(dayWeekNumber), "weeks").startOf("day")
    }

    return date.valueOf()
}