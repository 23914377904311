import moment from "moment"
import Parse from "parse"
import { axiosCall } from "../utils/axiosUtils"
import { getServerUrl, parseLimitRequest } from "../utils"
import { getActiveProductionItemsCount, getPlanningReportRules } from "../parseManager/planning/parsePlanningManager"
import {
    getRecipePrice,
    getRecipeDlc,
    getRecipeImage,
    getRecipeCommercialName,
    getRecipePackagingNumber,
    getRecipePackagingNumberUnit
} from "../utils/recipes"
import { SUCY_ID } from "../parseManager/site/parseSiteManager"
import { MANAGEMENT_MODES_ENUM, getProductTypesByOptions } from "../utils/productTypesUtils"
import { actionWithLoader } from "../actions/Utils/utils"
import { showPlanningSell } from "../actions/Planning/Planning"
import { uniqBy } from "lodash"

const Recipe = Parse.Object.extend("Recipe")
const SubcontractorProduct = Parse.Object.extend("SubcontractorsProducts")
const ProductionItem = Parse.Object.extend("ProductionItem")
const ProductsTags = Parse.Object.extend("ProductsTags")
const Supplier = Parse.Object.extend("Suppliers")

export async function getItems(Item, itemType, brand, productTypes = ["MAIN_COURSE"], weekly = false) {
    let queryResults = new Parse.Query(Item)
        .notEqualTo("deleted", true)
        .equalTo("status", "6")
        .equalTo("isActive", true)
        .containedIn("type", productTypes)

    if (brand) {
        if ("SubcontractorProduct" === itemType) {
            queryResults = queryResults.equalTo("brand", brand)
        } else {
            queryResults = queryResults.equalTo("brands", brand)
        }
    }

    if ("SubcontractorProduct" === itemType) {
        queryResults.include(["name", "name.supplier"]) // it's a pointer for subcontractorProduct, not recipe
    }

    if (itemType === "SubcontractorProduct") {
        weekly
            ? queryResults.hint("isActive_1_status_1_type_1")
            : queryResults.hint("brand_1_isActive_1_type_1_status_1")
    }
    else {
        weekly
            ? queryResults.hint("status_1_isActive_1_type_1_brands_1")
            : queryResults.hint("status_1_isActive_1_deleted_1")
    }

    console.time(`fetch ${itemType}`)

    const result = []

    queryResults = await queryResults
        .each(item => {
            const data = item.toJSON()

            result.push({
                ...data,
                itemType,
                name: itemType === "SubcontractorProduct"
                    ? data.name && data.name.name ? data.name.name : ""
                    : typeof data.name === "string" ? data.name : "",
                supplier: itemType === "SubcontractorProduct"
                    ? data.name && data.name.supplier ? data.name.supplier : null
                    : null
            })
        }, {
            batchSize: 2000
            // without specifying batchSize, the default limit of 100 is applied
            // the size has to be optimized based on possible quantity to retrieve
        })
    console.timeEnd(`fetch ${itemType}`)

    return result
}

export async function getSubcontractorsProducts(brand, productTypes, weekly) {
    return await getItems(SubcontractorProduct, "SubcontractorProduct", brand, productTypes, weekly)
}

export async function getRecipes(brand, productTypes, weekly) {
    return await getItems(Recipe, "Recipe", brand, productTypes, weekly)
}

export const normalizeSelectableItems = ({ items, brand = null, planningRules, sellDate, weekly = false }) => {
    if (!Array.isArray(items)) {
        return []
    }
    const brandRules = brand ? planningRules[brand.toUpperCase()] : {}
    const saleDateTimestamp = moment.utc(sellDate, "YYYY-MM-DD").startOf("day").valueOf()

    items = items.map(item => {
        let dlc
        if (!weekly) {
            const itemTypeRule = brandRules[item.itemType.toUpperCase()]
            const nbDay = item.itemType === "SubcontractorProduct" ? item.dlc : getRecipeDlc(item, brand)
            const toAdd = itemTypeRule.productionDate + nbDay
            dlc = moment.utc(saleDateTimestamp).add(toAdd, "day").startOf("day").valueOf()
        }

        let productImages
        if (item.itemType === "SubcontractorProduct") {
            productImages = item.appImage
        }
        else {
            productImages = getRecipeImage(item, brand)
        }

        const price = item.itemType === "SubcontractorProduct" ? item.price : getRecipePrice(item, brand)
        const packagingNumber = item.itemType === "SubcontractorProduct" ? 1 : getRecipePackagingNumber(item, brand)
        const packagingNumberUnit = item.itemType === "SubcontractorProduct" ? "piece" : getRecipePackagingNumberUnit(item, brand)
        const dlcBrand = item.itemType === "SubcontractorProduct" ? item.dlc : getRecipeDlc(item, brand)
        const commercialName = item.itemType === "SubcontractorProduct" ? item.commercialName : getRecipeCommercialName(item, brand)

        const formattedItem = {
            itemId: item.objectId,
            itemType: item.itemType,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            name: item.name,
            commercialName,
            uniqueCode: item.uniqueCode,
            itemBrand: item.brands || [item.brand],
            productType: item.type,
            internalTag: item.internalTag,
            image: Array.isArray(productImages) && productImages.length > 0 ? productImages[0] : productImages,
            rating: item.rating,
            nutriscore: (item.nutritionInformation && item.nutritionInformation.nutriscore) || null,
            supplier: item.itemType === "SubcontractorProduct" && item.supplier ? item.supplier.name : null,
            price: price,
            foodcost: item.foodcost || item.totalCost,
            dlcBrand: dlcBrand,
            season: item.season,
            nationalSend: true,
            sendCapital: true,
            smallRetail: true,
            lunchbag: false,
            packagingNumber: packagingNumber,
            packagingNumberUnit: packagingNumberUnit
        }

        if (brand) { // only for daily meal planner
            formattedItem.brand = brand
        }
        if (dlc) { // only for daily meal planner
            formattedItem.dlc = dlc
        }

        return formattedItem

    })

    return items
}

// TODO:
// Create the following functions

/**
 * Retrieves selectable Recipe and SubcontractorProducts based on the provided parameters.
 *
 * @param {Object} options - The options for retrieving the selectable items.
 * @param {string} options.brand - The brand of the items to retrieve.
 * @param {string} options.date - The date for which to retrieve the items.
 * @param {string} options.productType - The type of the items to retrieve.
 * @param {boolean} options.weekly - Determines if the items should be retrieved on a weekly basis.
 * @return {Array} array of selectable items.
 */
export const getSelectableItems = async ({ brand = null, date, productTypes = ["MAIN_COURSE"], weekly = false }) => {
    const [subcontractorsProducts, recipes, planningRules] = await Promise.all([
        getSubcontractorsProducts(brand, productTypes, weekly),
        getRecipes(brand, productTypes, weekly),
        getPlanningReportRules()
    ])

    if (weekly) {
        return normalizeSelectableItems({ items: [...recipes, ...subcontractorsProducts], weekly })
    }

    return normalizeSelectableItems({
        items: [...recipes, ...subcontractorsProducts],
        brand,
        planningRules,
        sellDate: date,
        weekly
    })
}

// TODO:
// Define methods to retrieve/update ProductionItem objects from/to DB
//
/**
 * getSelectedItems
 * Function to retrieve ProductionItem objects for some given date and brand
 * @param { string } brand              The selected sale brand
 * @param { string|number } date        The selected sale date
 * @returns { object[] }                The query result as a JSON array of objects
 */

export const getSelectedItems = async (brand, date, productTypeOptions) => {
    const startDate = moment.utc(date).startOf("day").valueOf()
    const endDate = moment.utc(date).endOf("day").valueOf()

    let items = await new Parse.Query(ProductionItem)
        .equalTo("brand", brand)
        .greaterThanOrEqualTo("saleDate", startDate)
        .lessThanOrEqualTo("saleDate", endDate)
        .notEqualTo("isReusable", true)
        .descending("productionDate")
        .hint("saleDate_1_productType_1_brand_1")
        .limit(parseLimitRequest)
        .find()

    items = Array.isArray(items)
        ? items.map(Item => Item.toJSON())
        : []


   let automaticallyManagedItems = []
    // default cart selected products (with automatic managed product type) for foodcheri
    if (brand === "FOODCHERI") {
        const automaticManagedProductTypes = getProductTypesByOptions(productTypeOptions, [MANAGEMENT_MODES_ENUM.automatic])
        automaticallyManagedItems = await getSelectableItems({ brand, date, productTypes: automaticManagedProductTypes })
    }

    const allItems = items.concat(automaticallyManagedItems)

    // return deduplicated items
    return uniqBy(allItems, "itemId")
}


/**
  * saveSelectedItems
  * Function to save a selection of item objects to the DB for some given date and brand
  * @param { string } brand              The selected sale brand
  * @param { string|number } date        The selected sale date
  * @param { object[] } selection        The array of selected item objects
  */


export const saveSelectedItems = (brand, date, selection) => {
    return actionWithLoader(async (dispatch) => {
        try {
            const body = {
                brand,
                date,
                selection,
                user: Parse.User.current() ? Parse.User.current().get("email") : null,
                siteId: SUCY_ID,
                isMealPlannerTagging: true
            }

            await axiosCall(
                "POST",
                `${getServerUrl()}/mealPlanner`,
                body,
                null
            )

            dispatch(showPlanningSell())
        }
        catch (e) {
            //TODO faire gestion d'erreur
            console.error("saveSelectedItems error: ", e)
            return Promise.reject(e)
        }
    })
}

/**
 * get ProductsTags and transform to filter
 */

export const getProductsTagsFilters = async () => {
    try {
        const productsTags = await new Parse.Query(ProductsTags)
            .limit(parseLimitRequest)
            .find()

        return productsTags.map(elem => {
            return { label: elem.get("name"), value: elem.id }
        })
    } catch (err) {

    }
}

export const getSuppliersFilters = async () => {
    try {
        const suppliers = await new Parse.Query(Supplier)
            .limit(parseLimitRequest)
            .find()

        return suppliers.map(supplier => {
            return { label: supplier.get("name"), value: supplier.get("name") }
        })
    } catch (error) {
        console.err("getSuppliersFilters error")
    }
}

export const isMealOpenForSales = async (meal, startDate, endDate) => {
    const startDateToTimestamp = moment(startDate).startOf("day").valueOf()
    const endDateToTimestamp = moment(endDate).endOf("day").valueOf()
    const numberOfProductionItemsAlreadyInSales = await getActiveProductionItemsCount(meal.itemId, startDateToTimestamp, endDateToTimestamp)
    return numberOfProductionItemsAlreadyInSales === 0
}

