import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"

import DataGrid from "../../components/DataGrid/DataGrid"
import { getProductionStepsRecipeOptionsSelector, getProductionStepsSectionOptionsSelector } from "../../reducers/Production/ProductionSteps"
import {
  showProductionStepsHoursEdit,
  showProductionStepExecution
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import { PRODUCTION_STEP_EXECUTION_STATUSES } from "../../utils/productionStepExecution"
import { TRANSFORMATION_TYPES, getTransformationTypeLabel } from "../../utils/supplierItemUtils"
import ProductionStepExecutionStatus from "./ProductionStepExecutionsStatus"

const ProductionStepExecutionsTable = ({ productionStepExecutions, state }) => {
  const dispatch = useDispatch()

  const recipeOptions = useSelector(getProductionStepsRecipeOptionsSelector)
  const sectionOptions = useSelector(getProductionStepsSectionOptionsSelector)

  const columnTableHeaders = [
    {
      key: "theoreticalTime",
      label: "Heures théoriques",
      dataType: "text",
      align: "center",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      visibilityStates: ["TODO", "IN_PROGRESS"]
    },
    {
      key: "time",
      label: "Heures réelles",
      align: "center",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      visibilityStates: ["DONE", "TO_TEST"]
    },
    {
      key: "name",
      label: "Nom",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      },
    },
    {
      key: "type",
      label: "Type d'étape",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: TRANSFORMATION_TYPES.map((transformationModeOption) => ({
          key: transformationModeOption.value,
          value: transformationModeOption.label,
          label: transformationModeOption.label
        }))
      },
    },
    {
      key: "machines",
      label: "Machines",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      },
    },
    {
      key: "recipe",
      label: "Recette (section)",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: recipeOptions.concat(sectionOptions).map((recipeOption) => ({
          key: recipeOption.key,
          value: recipeOption.label,
          label: recipeOption.label
        }))
      },
      render: (recipeValue) => {
        if (Array.isArray(recipeValue)) {
          return (
            <>
              {recipeValue.map((value, index) =>
                (
                <span key={index}>
                  {value.split(",")[0]}
                  <span style={{ color: "#A5A5A5" }}> ({value.split(",")[1]})</span>
                </span>
                )
              )}
            </>
          )
        } else {
          const sectionName = recipeValue.split(",")[1]
          return (<span>
          {recipeValue.split(",")[0]}
            <span style={{ color: "#A5A5A5" }}> ({sectionName})</span>
        </span>)
        }
      }
    },
    {
      key: "status",
      label: "État de l'étape",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      render: (statusName) => {
        const statusValue = PRODUCTION_STEP_EXECUTION_STATUSES.find(({ name }) => name === statusName)?.value
        return <ProductionStepExecutionStatus statusValue={statusValue} statusName={statusName} />
      }
    }
  ]

  const [columnHeaders, setColumnHeaders] = useState(columnTableHeaders)
  const [data, setData] = useState([])

  useEffect(() => {
    const updatedColumnHeaders = columnTableHeaders.filter(({ visibilityStates }) => !visibilityStates || visibilityStates.includes(state))
    setColumnHeaders(updatedColumnHeaders)
  }, [state])

  useEffect(() => {
    const formattedProductionStepExecutions = productionStepExecutions.map((productionStepExecution) => {
      const status = PRODUCTION_STEP_EXECUTION_STATUSES.find(({ value }) => value === productionStepExecution.status)?.name
      const theoreticalStart = productionStepExecution.theoreticalStartTime ? dayjs(productionStepExecution.theoreticalStartTime).format("HH:mm") : ""
      const theoreticalEnd = productionStepExecution.theoreticalEndTime ? dayjs(productionStepExecution.theoreticalEndTime).format("HH:mm") : ""
      const startTime = productionStepExecution.startTime ? dayjs(productionStepExecution.startTime).format("HH:mm") : ""
      const endTime = productionStepExecution.endTime ? dayjs(productionStepExecution.endTime).format("HH:mm") : ""

      let recipe = `${productionStepExecution.uniqueCode} - ${productionStepExecution.recipeName}` + `,${productionStepExecution.sectionName}`
      if (productionStepExecution.isStepMerged) {
        recipe = productionStepExecution.mergedProductionStepExecutions.map(pse => `${pse.uniqueCode} - ${pse.recipeName}` + `,${pse.sectionName}`)
      }

      return ({
        id: productionStepExecution.objectId,
        status,
        theoreticalTime: theoreticalStart + " - " + theoreticalEnd,
        time: startTime + " - " + endTime,
        name: productionStepExecution.productionStepSnapshot.name,
        type: getTransformationTypeLabel(productionStepExecution.productionStepSnapshot.transformation),
        machines: (productionStepExecution.machinesBatch || []).map(machine => machine.machineName).join(", "),
        recipe
      })
    })
    setData(formattedProductionStepExecutions)
  }, [productionStepExecutions])

  const moreMenus = useMemo(() => {
    if (state === "TODO") {
      return [{
        label: "Éditer les heures et les machines",
        onClick: () => dispatch(showProductionStepsHoursEdit())
      }]
    }
    return []
  }, [state])

  const title = useMemo(() => {
    let commonTitle = "Étapes de production "
    if (state === "TODO") {
      return commonTitle + "à réaliser"
    }
    if (state === "IN_PROGRESS") {
      return commonTitle + "en cours"
    }
    if (state === "DONE") {
      return commonTitle + "terminées"
    }
    if (state === "TO_TEST") {
      return commonTitle + "à tester"
    }
  }, [state])

  const handleShowProductionStep = (productionStepExecution) => dispatch(showProductionStepExecution(productionStepExecution.id))

  return (
    <DataGrid
      title={title}
      columns={columnHeaders}
      data={data}
      withFilters
      rowLabel="Étapes de production"
      menus={moreMenus}
      onRowClick={handleShowProductionStep}
    />
  )

}

export default ProductionStepExecutionsTable