import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import {COLORS, roundNumber} from "../../utils"
import {
    unitSelectValues,
    unitValues,
    conversionLabels,
    unitsCategories,
    servingUnits
} from "../../utils/supplierItemUtils"
import NewEditIcon from "../NewEditIcon"
import Modal from "@mui/material/Modal"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import UnitsForm from "./UnitsForm"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        paddingBottom: 40,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: "20px 32px 16px 32px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    singleValueContainer: {
        display: "flex"
    },
    conversionContainer: {
        minHeight: "110px"
    },
    table: {
        marginBottom: 20
    },
    tableRow: {
        border: "none"
    },
    tableCell: {
        border: "none",
        padding: 5,
        width: 100,
        color: COLORS.GREY_700
    },
    categoryTitle: {
        padding: "4px 55px 0px 32px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontSize: 14,
        width: 250
    }
}))

const SupplierItemUnits = (props) => {
    const {
        supplierItem,
        onUpdateSupplierItem,
        canEdit
    } = props
    
    const classes = useStyles()
    
    const [openEditModal, setOpenEditModal] = useState(false)
    
    const handleCloseModal = () => {
        setOpenEditModal(false)
    }
    
    const getUnitValue = (unitRow, unitCategory) => {
        if (supplierItem.units && supplierItem.units[unitCategory] && supplierItem.units[unitCategory][unitRow]){
            switch (unitRow) {
                case "unity":
                    return `${supplierItem.units[unitCategory][unitRow].name} ${supplierItem.units[unitCategory][unitRow].quantity} ${_getUnitLabel(supplierItem.units[unitCategory][unitRow].unity, unitSelectValues)}`
                case "weight":
                    return `${roundNumber(supplierItem.units[unitCategory][unitRow], 5)} kg`
                case "price":
                    return `${roundNumber(supplierItem.units[unitCategory][unitRow], 2)} €`
                default:
                    return ""
            }
        }
        else {
            return ""
        }
    }

    const _getUnitLabel = (unitValue, dataArray) => {
        if (dataArray === conversionLabels) {
            console.log(dataArray, unitValue)
            return dataArray.find(item => item.type === unitValue).label || ""
        }
        return dataArray.find(item => item.value === unitValue).label || ""
    }
    
    const renderUnits = (unitCategory) => {
        return (
            <Table className={classes.table}>
                <TableBody className={classes.tableBody}>
                    {
                        unitValues.map((el, i) =>
                            <TableRow className={classes.tableRow} key={i}>
                                <TableCell className={classes.tableCell}>
                                    { el.label }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    { getUnitValue(el.value, unitCategory) }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        )
    }
    
    const renderConversion = () => {
        return (
            <Table className={classes.table}>
                <TableBody className={classes.tableBody}>
                    {
                        supplierItem.units.conversions.map((el, i) =>
                            <TableRow className={classes.tableRow} key={i}>
                                <TableCell className={classes.tableCell}>
                                    { el.value }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    { _getUnitLabel(el.type, conversionLabels) }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        )
    }
    
    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Unités de l'article
                </div>
                {
                    canEdit &&
                        <NewEditIcon
                            onClick={setOpenEditModal}
                        />
                }
            </div>
            {
                unitsCategories.map((unitCategory, index) =>
                    <div key={index} className={classes.singleValueContainer}>
                        <div className={classes.categoryTitle}>
                            { unitCategory.label }
                        </div>
                        <div>
                            { renderUnits(unitCategory.value) }
                        </div>
                    </div>
                )
            }
            <div className={`${classes.singleValueContainer} ${classes.conversionContainer}`}>
                <div className={classes.categoryTitle}>
                    Conversions
                </div>
                <div>
                    { supplierItem && supplierItem.units && supplierItem.units.conversions && supplierItem.units.conversions.length > 0 && renderConversion() }
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.categoryTitle}>
                    Unité de barquettage
                </div>
                <div>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    Unité
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {supplierItem && supplierItem.units && supplierItem.units.servingUnit && _getUnitLabel(supplierItem.units.servingUnit, servingUnits) || "En kg"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            {
                openEditModal
                    ? <Modal
                        open={openEditModal}
                        onClose={handleCloseModal}
                        aria-labelledby="form-dialog-title"
                        className={classes.modal}
                    >
                        <UnitsForm
                            supplierItem={supplierItem}
                            onUpdateSupplierItem={onUpdateSupplierItem}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                    : null
            }
        </Paper>
    )
}

export default SupplierItemUnits