export const conservationModes = [
    {
        value: 0,
        label: "Aucun"
    },
    {
        value: 1,
        label: "A température ambiante/frais"
    },
    {
        value: 2,
        label: "Séché"
    },
    {
        value: 3,
        label: "En conserve"
    },
    {
        value: 4,
        label: "Réfrigéré"
    },
    {
        value: 5,
        label: "Congelé"
    },
]

export const origins = [
    {
        value: 0,
        label: "Aucun"
    },
    {
        value: 1,
        label: "Local"
    },
    {
        value: 2,
        label: "National"
    },
    {
        value: 3,
        label: "Local et circuits courts"
    },
    {
        value: 4,
        label: "National et circuits courts"
    },
    {
        value: 5,
        label: "Pays limitrophe"
    },
    {
        value: 6,
        label: "Reste du monde"
    },
    {
        value: 7,
        label: "Pays limitrophe et circuits courts"
    },
    {
        value: 8,
        label: "Reste du monde et circuits courts"
    },
    {
        value: 9,
        label: "Spécial"
    }
]

export const labels = [
    {
        value: 0,
        label: "Aucun"
    },
    {
        value: 1,
        label: "Bio"
    },
    {
        value: 2,
        label: "AOP/AOC/IGP"
    },
    {
        value: 3,
        label: "Label Rouge (viandes)"
    },
    {
        value: 4,
        label: "MSC"
    },
    {
        value: 5,
        label: "ASC"
    },
    {
        value: 6,
        label: "Commerce équitable"
    },
    {
        value: 7,
        label: "Bio et équitable"
    },
    {
        value: 8,
        label: "HVE"
    },
    {
        value: 9,
        label: "Label Rouge (oeuf)"
    },
    {
        value: 10,
        label: "Bleu Blanc Coeur"
    },
    {
        value: 11,
        label: "RSPO"
    },
    {
        value: 12,
        label: "Rain Forest Alliance"
    },
    {
        value: 13,
        label: "UTZ"
    },
    {
        value: 14,
        label: "Nature et progrès"
    },
    {
        value: 15,
        label: "Bio Cohérence"
    },
    {
        value: 16,
        label: "Demeter"
    },
    {
        value: 17,
        label: "Sourcé bio"
    }
]

export const getIngredientLabel = (options, code) => {
    const currentOrigin = options.find(label => label.value === code)

    if (!currentOrigin) return ""

    return currentOrigin.label
}

export const commercialNamesBaseCsvHeader = {
    id: "Id",
    name: "Nom de l'ingrédient",
    family: "Famille",
    group: "Groupe",
    complexity: "Complexité",
    season: "Saisonnalité",
    allergens: "Allergènes",
    label: "Label",
    conservationMode: "Mode de conservation",
    firstOrigin: "Provenance 1",
    firstOriginAverage: "Moyenne provenance 1",
    secondOrigin: "Provenance 2",
    secondOriginAverage: "Moyenne provenance 2",
}