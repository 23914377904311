import { push as routerPush } from "react-router-redux"
import Parse from "parse"
import cloneDeep from "lodash/cloneDeep"

import { getValues } from "../../parseUtils"
import { showError } from "./app"
import {
    fullSeason,
    packagingFields,
    recipeFields,
    recipeDetailsFields,
    ingredientFields,
    subcontractorProductFields,
    groupIngredientFields,
    subcontractorCommercialNameFields,
    familyIngredientFields,
    allergenIngredientFields,
    commercialNameFields,
    forEachBrand,
    KFC_BRANDS,
    getServerUrl,
    ACTIVE_KFC_BRANDS_NAMES
} from "../../utils"
import { getCurrentUser, getUserRights } from "../../reducers/Utils/app"
import {
    parseSectionToObject,
    getDefaultSection,
    defaultNutritionInformation,
    getSupplierItemsFromSections,
    getPVC,
    computeSectionData,
    computeStepData,
    computeIngredientData,
    computeRecipeData,
    FCHeatingInstruction,
    SZNHeatingInstruction,
    MONOPHeatingInstruction,
    getRecipePrice,
    getRecipeTva,
    getProductionStepPointerObj,
    parseInitialProductionStepsToObject,
    getDefaultReusableSteps
} from "../../utils/recipes"
import {
    conservationModes,
    labels,
    origins
} from "../../utils/commercialNamesUtils"
import { formatValuesWithWeight } from "../../utils/ressources"

import {
    getDispatchFormat
} from "../../utils/dispatchPriority"
import { axiosCall } from "../../utils/axiosUtils"
import { orderUpdateStatus } from "../../utils/ordersUtils"
import { formatSupplierItemsAutocompleteOption, productTypeToAdd } from "../../utils/supplierItemUtils"
import { getProductTypes } from "../../parseManager/products/productTypeManager"

/**
 * returns a thunk
 * @param {Promise<Promise>} longPromiseCreatorOrPromise signature if creator: (dispatch : Function) : Promise
 */
export function actionWithLoader(longPromiseCreatorOrPromise) {
    return async (dispatch, getState) => {
        dispatch({
            type: "LOADING_START"
        })
        try {
            if (typeof longPromiseCreatorOrPromise === "function") {
                await longPromiseCreatorOrPromise(dispatch, getState)
            } else {
                await longPromiseCreatorOrPromise
            }
        } catch (error) {
            showError(error)(dispatch)
        } finally {
            dispatch({
                type: "LOADING_END"
            })
        }

        return Promise.resolve()
    }
}

/**
 * returns a thunk
 * @param {Promise<Promise>} longPromiseCreatorOrPromise signature if creator: (dispatch : Function) : Promise
 */
export function simpleAction(longPromiseCreatorOrPromise) {
    return async (dispatch, getState) => {
        try {
            if (typeof longPromiseCreatorOrPromise === "function") {
                await longPromiseCreatorOrPromise(dispatch, getState)
            } else {
                await longPromiseCreatorOrPromise
            }
        } catch (error) {
            showError(error)(dispatch)
        }

        return Promise.resolve()
    }
}

export async function queryUserRights() {
    try {
        return await Parse.Cloud.run("getUserRights")
    } catch (err) {
        return {}
    }
}

export function onEnter({ store, actionThunk, getReplacingPath, haveAccessRight, withLoader = true }) {
    return async (nextState, replace, callback) => {
        try {
            if (getReplacingPath) {
                const replacingPath = await getReplacingPath(store.getState, nextState)
                if (replacingPath) {
                    replace(replacingPath)
                    callback()
                    return
                }
            }
            if (haveAccessRight) {
                const haveRight = await haveAccessRight(store.getState, nextState)
                if (!haveRight) {
                    replace("/")
                    callback()
                    return
                }
            }


            //---- actual call ----//
            if (actionThunk) {
                const dispatchingFunction = actionThunk(nextState.params)
                let result
                if (withLoader) {
                    result = actionWithLoader(dispatchingFunction)(store.dispatch, store.getState)
                } else {
                    result = dispatchingFunction(store.dispatch, store.getState)
                }
                if (result && result.then) {
                    await result
                }
            }

            callback()
        } catch (error) {
            console.error(error)
            callback(error)
        }
    }
}

export function getJSONValues(obj, fields, dest = {}) {
    for (const i in fields) {
        const field = fields[i]
        if (obj[field] !== null) {
            dest[field] = obj[field]
        }
    }
    return dest
}

export function push(pathname, returnPath) {
    // pathname might already be a location object
    const location = returnPath ? routerLocation(pathname, returnPath) : pathname
    return routerPush(location)
}

export function routerLocation(pathname, returnPath) {
    if (typeof returnPath !== "string") {
        // can be an Event (eg: onClick={showxxx})
        returnPath = undefined
    }
    return {
        pathname,
        state: {
            returnPath
        }
    }
}

export function getReplacingPath({ needUser = true }) {
    return async (getState, nextState) => {
        const state = getState()
        if (needUser) {
            const user = getCurrentUser(state)
            if (!user) {
                return {
                    pathname: "/login",
                    state: {
                        returnPath: nextState.location.pathname
                    }
                }
            }
        }
        return undefined
    }
}

export function haveAccessRight(right) {
    return async (getState) => {
        const state = getState()
        const userRights = getUserRights(state)
        return checkIfHaveRight(right, userRights)
    }
}
export function checkIfHaveRight(right, userRights) {
    if (!right) return true // if no right is defined
    let haveRight = true
    Object.keys(right).forEach(key => {
        if (userRights[key] !== right[key]) {
            haveRight = false
        }
    })
    return haveRight
}
/*
Should add method to check rights
 */

export function downloadFile(url, filename) {
    const link = document.createElement("a")

    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export function downloadFromBlob(blob, fileName, fileExtension = ".csv") {
    const downloadUrl = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = downloadUrl
    link.setAttribute("download", fileName + fileExtension)

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)
}

/*==========================================
 * FORM INITIAL VALUES
 * =========================================*/

/**
 *
 * @param {JSON.Object} ingredient
 * @returns {{}}
 */
export function ingredientFormInitialValues(ingredient, cookingModes) {
    if (!ingredient || typeof ingredient !== "object") {
        return {
            cookingModes: cookingModes.map(cookingMode => {
                return { cookingMode: cookingMode, transformRate: 100 }
            })
        }
    }
    const ingredientObject = getJSONValues(ingredient, ingredientFields)

    if (ingredientObject.commercialName) {

        /* CommercialName Groups and Families */
        if (ingredientObject.commercialName.group) {
            ingredientObject.family = ingredientObject.commercialName.group.family.name
            ingredientObject.group = ingredientObject.commercialName.group.name
        }

        /* CommercialName Allergens */
        if (Array.isArray(ingredientObject.commercialName.allergens)) {
            ingredientObject.allergens = ingredientObject.commercialName.allergens.map(allergen => {
                return { objectId: allergen.objectId, label: allergen.name }
            })
        }

        /* CommercialName sourcing */
        if (ingredientObject.commercialName.conservationMode !== null && ingredientObject.commercialName.conservationMode !== undefined) {
            ingredientObject.conservationMode = conservationModes.filter(conservationMode => conservationMode.value === ingredientObject.commercialName.conservationMode).map(item => item.label)[0]
        }
        if (ingredientObject.commercialName.firstOrigin !== null && ingredientObject.commercialName.firstOrigin !== undefined) {

            if (ingredientObject.commercialName.firstOriginAverage) {
                ingredientObject.firstOriginAverage = ingredientObject.commercialName.firstOriginAverage
            }

            ingredientObject.firstOrigin = origins.filter(origin => origin.value === ingredientObject.commercialName.firstOrigin).map(item => item.label)[0]
        }
        if (ingredientObject.commercialName.secondOrigin !== null && ingredientObject.commercialName.secondOrigin !== undefined) {

            if (ingredientObject.commercialName.secondOriginAverage) {
                ingredientObject.secondOriginAverage = ingredientObject.commercialName.secondOriginAverage
            }

            ingredientObject.secondOrigin = origins.filter(origin => origin.value === ingredientObject.commercialName.secondOrigin).map(item => item.label)[0]
        }
        if (ingredientObject.commercialName.label !== null && ingredientObject.commercialName.label !== undefined) {
            ingredientObject.label = labels.filter(label => label.value === ingredientObject.commercialName.label).map(item => item.label)[0]
        }
    }

    /* CookingModes */
    if (!ingredientObject.cookingModes || ingredientObject.cookingModes.length === 0) {
        ingredientObject.cookingModes = cookingModes.map(cookingMode => {
            return { cookingMode: cookingMode, transformRate: 100 }
        })
    }
    return ingredientObject
}

export function familyIngredientFormInitialValues(familyIngredient) {
    if (!familyIngredient || typeof familyIngredient !== "object") {
        return {}
    }

    return getJSONValues(familyIngredient, familyIngredientFields)
}

/**
 *
 * @param {Parse.Object} allergenIngredient
 * @returns {{}}
 */
export function allergenIngredientFormInitialValues(allergenIngredient) {
    if (!allergenIngredient || typeof allergenIngredient !== "object") {
        return {}
    }

    return getJSONValues(allergenIngredient, allergenIngredientFields)
}

/**
 *
 * @param {Parse.Object} groupIngredient
 * @returns {{}}
 */
export function groupIngredientFormInitialValues(groupIngredient) {
    if (!groupIngredient || typeof groupIngredient !== "object") {
        return {}
    }

    const groupIngredientObject = getJSONValues(groupIngredient, groupIngredientFields)

    /* Family Ingredient */
    if (groupIngredientObject.family) {
        groupIngredientObject.family = groupIngredientObject.family.objectId
    }

    return groupIngredientObject
}

export function commercialNameFormInitialValues(commercialName, familiesIngredients) {
    if (!commercialName || typeof commercialName !== "object") {
        const defaultFamily = familiesIngredients.filter(familyIngredient => familyIngredient.name === "autre")[0]
        return {
            family: defaultFamily ? defaultFamily.objectId : null,
            conservationMode: 0,
            firstOrigin: 0,
            firstOriginAverage: 100,
            label: 0,
            compexity: 0
        }
    }

    const commercialNameObject = getJSONValues(commercialName, commercialNameFields)

    /* Groups and Families */
    if (commercialNameObject.group) {
        commercialNameObject.family = commercialNameObject.group.family.objectId
        commercialNameObject.group = commercialNameObject.group.objectId
    }

    /* Allergens */
    if (commercialNameObject.allergens) {
        commercialNameObject.allergens = commercialNameObject.allergens.map(allergen => {
            return { objectId: allergen.objectId, label: allergen.name }
        })
    }

    return commercialNameObject
}

/**
 *
 * @param {Parse.Object} packaging
 * @returns {{}}
 */
export function packagingFormInitialValues(packaging) {
    if ((!packaging || typeof packaging !== "object")) {
        return {
            brands: [],
            reference: ""
        }
    }

    const values = getValues(packaging.toJSON(), packagingFields)

    // Issues: strange behavior. Should always save a json object not a parse object in the redux store
    if (packaging.has("supplierItem") && values.supplierItem.__type !== "Pointer") {
        values.supplierItem = formatSupplierItemsAutocompleteOption(values.supplierItem)
    }

    return values
}

export function sectionGenericFormInitialValues(section) {
    let sections

    if (!section) {
        const result = getDefaultSection()
        result.reusable = true
        result.generic = false
        result.print = true
        sections = [result]
    } else {
        const parseSection = parseSectionToObject([section])
        parseSection[0].reusable = true
        parseSection[0].generic = false
        sections = parseSection
    }

    const values = { sections }
    computeDisplayData({ recipe: values, isRecipe: false })

    return values
}

export function recipeCommentFormInitialValues(recipe) {
    if (!recipe || typeof recipe !== "object") {
        return {
            id: null,
            comments: ""
        }
    } else {
        const oldComments = typeof recipe.get("comments") === "string" ? recipe.get("comments") : recipe.get("oldComments")
        return {
            id: recipe.id,
            comments: oldComments
        }
    }
}

export const recipeSectionsFormInitialValues = (recipe, isProductionSteps = false) => {
    const values = {}

    if (!recipe || typeof recipe !== "object") {
        values.id = null
        values.sections = [getDefaultSection()]
    } else {
        const recipeObject = getValues(recipe, recipeFields)
        const TTCPrice = getRecipePrice(recipe.toJSON(), "FOODCHERI")
        const tva = getRecipeTva(recipe.toJSON(), "FOODCHERI")
        const pvc = getPVC("RECIPE", recipeObject.type, recipe.get("foodcost")).pvc

        values.id = recipe.id
        values.sections = recipeObject.sections && recipeObject.sections.length
            ? parseSectionToObject(recipeObject.sections, false)
            : [getDefaultSection()]

        values.type = recipeObject.type
        values.HTprice = tva && TTCPrice && TTCPrice !== 0 ? TTCPrice - (TTCPrice * (parseFloat(tva) / 100)) : null
        values.HTPVC = tva && (!TTCPrice || TTCPrice === 0) ? pvc - (pvc * (parseFloat(tva) / 100)) : null
    }

    if (isProductionSteps) {
        computeDisplayData({
            recipe: values,
            stepsField: "productionSteps",
            stepIngredientField: "stepComponents",
            isProductionSteps
        })
    } else {
        computeDisplayData({ recipe: values })
    }

    return values
}

export const getReusableFormInitialValues = (reusableStep, isNew = null) => {
    if (!isNew && reusableStep) {
        const { netWeight, cost, realCost, reusableStepTotalGrossWeight } = formatValuesWithWeight(reusableStep)
        return {
            productionSteps: parseInitialProductionStepsToObject(reusableStep.productionSteps),
            name: reusableStep.name,
            id: reusableStep.objectId,
            grossWeight: reusableStepTotalGrossWeight,
            netWeight,
            cost,
            realCost,
        }
    }

    return {
        productionSteps: [getDefaultReusableSteps()],
        name: ""
    }
}

export function recipeDetailsFormInitialValues(recipe) {
    if (!recipe || "object" !== typeof recipe) {
        return {
            id: null,
            description: [],
            price: [],
            commercialNames: [],
            packagingNumber: [],
            packagingNumberUnit: [],
            tva: [],
            dlc: [],
            instructions: [],
            packaging: [],
            subPackaging: [],
            reusablePackaging: [],
            reusableSubPackaging: [],
            appImage: [],
            kitchenImage: [],
            defaultValues: {
                price: null,
                dlc: null,
                description: null,
                instructions: null,
                appImage: null,
                tva: 5.5,
                commercialName: null,
                packagingNumber: null,
                packagingNumberUnit: null
            },
            gesters: null,
            portionPerPlate: null,
            difficulty: 1,
            preparation: null,
            specialInstruction: null,
        }
    }

    const values = getValues(recipe, recipeDetailsFields)
    values.id = recipe.id

    values.brands = (values.brands || []).filter(brand => ACTIVE_KFC_BRANDS_NAMES.includes(brand))

    const finalPackaging = []
    const finalSubPackaging = []
    const finalReusablePackaging = []
    const finalReusableSubPackaging = []

    for (const brand of values.brands) {

        //Packaging
        const currentPackaging = values.packaging.find(el => el.brand === brand) || null

        if (currentPackaging) {
            finalPackaging.push({
                brand: brand,
                value: currentPackaging.value ? currentPackaging.value.id : null
            })
        }
        else {
            finalPackaging.push({
                brand: brand,
                value: null
            })
        }

        //SubPackaging
        const currentSubPackaging = values.subPackaging.find(el => el.brand === brand) || null

        if (currentSubPackaging) {
            finalSubPackaging.push({
                brand: brand,
                value: currentSubPackaging.value ? currentSubPackaging.value.map(subPackaging => ({
                    value: subPackaging.id,
                    label: subPackaging.get && subPackaging.get("name")
                })) : null
            })
        }
        else {
            finalSubPackaging.push({
                brand: brand,
                value: null
            })
        }

        //ReusablePackaging
        const currentReusablePackaging = values.reusablePackaging.find(el => el.brand === brand) || null

        if (currentReusablePackaging) {
            finalReusablePackaging.push({
                brand: brand,
                value: currentReusablePackaging.value ? currentReusablePackaging.value.id : null
            })
        }
        else {
            finalReusablePackaging.push({
                brand: brand,
                value: null
            })
        }

        //ReusableSubPackaging
        const currentReusableSubPackaging = values.reusableSubPackaging.find(el => el.brand === brand) || null

        if (currentReusableSubPackaging) {
            finalReusableSubPackaging.push({
                brand: brand,
                value: currentReusableSubPackaging.value ? currentReusableSubPackaging.value.map(subPackaging => ({
                    value: subPackaging.id,
                    label: subPackaging.get && subPackaging.get("name")
                })) : null
            })
        }
        else {
            finalReusableSubPackaging.push({
                brand: brand,
                value: null
            })
        }
    }

    values.packaging = finalPackaging
    values.subPackaging = finalSubPackaging
    values.reusablePackaging = finalReusablePackaging
    values.reusableSubPackaging = finalReusableSubPackaging
    values.commercialNames = values.commercialNames || []
    values.tva = values.tva || []
    values.packagingNumber = values.packagingNumber || []
    values.packagingNumberUnit = values.packagingNumberUnit || []

    if (0 !== values.brands.length) {
        const brandRelatedFields = ["description", "price", "instructions", "dlc", "tva", "commercialNames", "packagingNumber", "packagingNumberUnit"] // Fields that can be empty because not mandatory in some cases

        for (const field of brandRelatedFields) {
            if (Array.isArray(values[field])) {
                if (0 === values[field].length) { // If empty we build the { brand, value } array
                    values[field] = values.brands.map(brand => ({ brand: brand, value: null }))
                }
                else if (values[field].length !== values.brands.length) { // Otherwise we add the missing brands
                    const fieldBrands = values[field].map(field => field.brand)

                    values.brands.filter(brand => !fieldBrands.includes(brand)).forEach(brand => {
                        values[field].push({ brand: brand, value: null })
                    })

                    // reorder by brand order
                    values[field].sort((a, b) => values.brands.indexOf(a.brand) - values.brands.indexOf(b.brand))
                }
            }
        }
    }

    if (values.preparation !== "1") {
        for (const brand of values.brands) {
            const currentHeatingInstruction = values.heatingInstructions.find(el => el.brand === brand) || null

            if (!currentHeatingInstruction) {
                values.heatingInstructions.push({
                    brand: brand,
                    value: getDefaultHeatingInstruction(brand)
                })
            }
        }
    }

    for (const field of ["description", "price", "instructions", "dlc", "heatingInstructions", "packaging",
        "subPackaging", "reusablePackaging", "reusableSubPackaging", "appImage", "kitchenImage", "commercialNames"]) {
        if (values[field]) {
            values[field] = values[field]
                .filter(el => values.brands.includes(el.brand))
                .sort((a, b) => values.brands.indexOf(a.brand) - values.brands.indexOf(b.brand))
        }
    }

    return values
}

export function getDefaultHeatingInstruction(brand) {
    switch (brand) {
        case "FOODCHERI":
            return FCHeatingInstruction
        case "SEAZON":
            return SZNHeatingInstruction
        case "MONOPRIX":
            return MONOPHeatingInstruction
        default:
            return FCHeatingInstruction
    }
}

export const getRecipePackagingNumberUnitLabel = (value) => {
    switch (value) {
        case "full":
            return "entier"
        case "piece":
            return "pièce"
        default:
            return ""
    }
}
export function recipeFormInitialValues(recipe) {
    if (!recipe || typeof recipe !== "object") {
        return {
            id: null,
            status: "1",
            season: fullSeason,
            brands: [],
            internalProduct: true,
            sameInstructions: false,
            sameDescriptions: false,
            isActive: false,
            difficulty: 1,
            sections: [getDefaultSection()],
            commercialNames: [],
            internalTag: [],
            supplierItems: [],
            description: [],
            heatingInstructions: [],
            packaging: [],
            reusablePackaging: [],
            subPackaging: [],
            reusableSubPackaging: [],
            instructions: [],
            price: [],
            dlc: []
        }
    }

    const recipeId = recipe.id
    const recipeObject = getValues(recipe, recipeFields)

    recipeObject.id = recipeId

    if (recipeObject.packaging) {
        recipeObject.packaging = recipeObject.packaging.map(p => ({ brand: p.brand, value: p.value ? p.value.id : null }))
    }

    if (recipeObject.subPackaging) {
        recipeObject.subPackaging = recipeObject.subPackaging.map(sp => {
            return {
                brand: sp.brand, value: sp.value ? sp.value.map(ssp => {
                    return { value: ssp.id, label: ssp.get && ssp.get("name") }
                }) : null
            }
        })
    }

    if (recipeObject.reusablePackaging) {
        recipeObject.reusablePackaging = recipeObject.reusablePackaging.map(p => ({ brand: p.brand, value: p.value ? p.value.id : null }))
    }

    if (recipeObject.reusableSubPackaging) {
        recipeObject.reusableSubPackaging = recipeObject.reusableSubPackaging.map(sp => {
            return {
                brand: sp.brand, value: sp.value ? sp.value.map(ssp => {
                    return { value: ssp.id, label: ssp.get && ssp.get("name") }
                }) : null
            }
        })
    }

    if (recipeObject.internalTag) {
        recipeObject.internalTag = recipeObject.internalTag.map(obj => {
            return { value: obj.id, label: obj.get && obj.get("name") }
        })
    }

    if (recipeObject.sections && recipeObject.sections.length > 0) {
        recipeObject.sections = parseSectionToObject(recipeObject.sections)
    } else {
        recipeObject.sections = [getDefaultSection()]
    }

    if (Array.isArray(recipeObject.ingredients) && recipeObject.ingredients.length > 0) {
        recipeObject.ingredients = recipeObject.ingredients.map(ingredient => ingredient.id)
    } else {
        recipeObject.ingredients = getSupplierItemsFromSections([], cloneDeep(recipeObject.sections))
    }

    if (0 !== recipeObject.brands.length) {
        const brandRelatedFields = ["description", "price", "instructions", "dlc"] // Fields that can be empty because not mandatory in some cases

        for (const field of brandRelatedFields) {
            if (Array.isArray(recipeObject[field])) {
                if (0 === recipeObject[field].length) { // If empty we build the { brand, value } array
                    recipeObject[field] = recipeObject.brands.map(brand => ({ brand: brand, value: null }))
                } else if (recipeObject[field].length !== recipeObject.brands.length) { // Otherwise we add the missing brands
                    const fieldBrands = recipeObject[field].map(field => field.brand)
                    recipeObject.brands.filter(brand => !fieldBrands.includes(brand)).forEach(brand => {
                        recipeObject[field].push({ brand: brand, value: null })
                    })
                }
            }
        }
    }

    return recipeObject
}

export const getSiteInitialValues = (site, isEditing) => {
    return isEditing ?
        site :
        {
            name: "",
            deliveryAddress:
            {
                line1: "",
                line2: "",
                codeAccess: "",
                zipCode: "",
                city: "",
                country: "France"
            }
            ,
            invoiceAddress:
            {
                line1: "",
                line2: "",
                codeAccess: "",
                zipCode: "",
                city: "",
                country: "France"
            }
        }
}

export function supplierInitialValues(supplier, isEditing) {
    return isEditing ?
        supplier :
        {
            name: "",
            site:
            {
                line1: "",
                line2: "",
                codeAccess: "",
                zipCode: "",
                city: "",
                country: "France"
            }
            ,
            invoiceAddress:
            {
                line1: "",
                line2: "",
                codeAccess: "",
                zipCode: "",
                city: "",
                country: "France"
            }
        }
}

export function stockZoneInitialValues(stockZone) {
    return {
        name: stockZone ? stockZone.name : "",
        spot: stockZone ? stockZone.spot : "",
        temperature: stockZone ? stockZone.temperature : "",
        storeNumber: stockZone ? stockZone.storeNumber : ""
    }
}

/**
 * @TODO 3 nested loops isn't a good design, check for better possibilities
 */
export const computeDisplayData = ({
    recipe,
    isRecipe = true,
    stepsField = "steps",
    stepIngredientField = "ingredients",
    isProductionSteps = false
}) => {
    for (const section of recipe.sections) {
        for (const step of section[stepsField]) {
            const currentStep = getProductionStepPointerObj(step, isProductionSteps)

            if (!step.reusable) {
                // if (!step.isReusable) { // change this to step.reusable later
                const ingredients = currentStep?.[stepIngredientField] || []

                for (const ingredient of ingredients) {
                    const computedIngredientData = computeIngredientData(ingredient, stepsField)
                    ingredient.netWeight = computedIngredientData.netWeight
                    ingredient.cost = computedIngredientData.cost
                    ingredient.realCost = computedIngredientData.realCost
                    ingredient.transformRate = stepsField === "productionSteps" ? ingredient.transformRate : computedIngredientData.transformRate
                    if (stepsField === "steps") {
                        ingredient.cookingModeLabel = computedIngredientData.cookingModeLabel
                    }
                    ingredient.transformationMode = ingredient.transformationMode ? ingredient.transformationMode : null
                }

                computeStepData(step, stepIngredientField, isProductionSteps)
            }
        }

        computeSectionData(section, stepsField, stepIngredientField, isProductionSteps)
    }

    if (isRecipe) computeRecipeData(recipe)

    return recipe
}

export function nutritionInformationFormInitialValues(recipe) {
    if (!recipe || typeof recipe !== "object" || !recipe.get("nutritionInformation")) {
        return defaultNutritionInformation
    }
    const nutriInfo = recipe.get("nutritionInformation")
    return Object.keys(defaultNutritionInformation)
        .reduce(
            (result, key) => ({ ...result, [key]: nutriInfo[key] || defaultNutritionInformation[key] }),
            {}
        )
}

export function subcontractorCommercialNameFormInitialValues(subcontractorCommercialName, familiesIngredients) {
    if (!subcontractorCommercialName || typeof subcontractorCommercialName !== "object") {
        const defaultFamily = familiesIngredients.filter(familyIngredient => familyIngredient.name === "autre")[0]
        return {
            family: defaultFamily ? defaultFamily.objectId : null,
            conservationMode: 0,
            origin: 0,
            label: 0
        }
    }

    const subcontractorCommercialNameObject = getJSONValues(subcontractorCommercialName, subcontractorCommercialNameFields)

    /* supplier */
    if (subcontractorCommercialNameObject.supplier) {
        subcontractorCommercialNameObject.supplier = subcontractorCommercialNameObject.supplier.objectId
    }

    /* Groups and Families */
    if (subcontractorCommercialNameObject.group) {
        subcontractorCommercialNameObject.family = subcontractorCommercialNameObject.group.family.objectId
        subcontractorCommercialNameObject.group = subcontractorCommercialNameObject.group.objectId
    }

    /* Allergens */
    if (subcontractorCommercialNameObject.allergens) {
        subcontractorCommercialNameObject.allergens = subcontractorCommercialNameObject.allergens.map(allergen => {
            return { objectId: allergen.objectId, label: allergen.name }
        })
    }

    return subcontractorCommercialNameObject
}

export function subcontractorProductFormInitialValues(subcontractorProduct, packagingCost, subcontractorIngredientCost, subcontractorNetWeight) {
    if (!subcontractorProduct || typeof subcontractorProduct !== "object") {

        return {
            id: null,
            status: "1",
            season: fullSeason,
            heatingInstructions: null,
            packagingCost: 0,
            subcontractorIngredientCost: 0,
            netWeight: 0,
            brand: null,
            isActive: false,
            internalTag: [],
            pvc: 0,
            packaging: null,
            subPackaging: [],
            reusablePackaging: null,
            reusableSubPackagings: [],
            spicy: "0",
            chef: "",
            uniqueCode: "",
        }
    }
    const subcontractorProductObject = getValues(subcontractorProduct, subcontractorProductFields)
    const subcontractorProductId = subcontractorProduct.id
    subcontractorProductObject.id = subcontractorProductId

    /* Packaging */
    if (subcontractorProductObject.brand) {
        subcontractorProductObject.packaging = (subcontractorProductObject.packaging && subcontractorProductObject.packaging.id)
            ?
            subcontractorProductObject.packaging.id
            :
            null
    }

    /* reusable packaging */
    if (subcontractorProductObject.brand) {
        subcontractorProductObject.reusablePackaging = (subcontractorProductObject.reusablePackaging && subcontractorProductObject.reusablePackaging.id)
            ?
            subcontractorProductObject.reusablePackaging.id
            :
            null
    }

    /* IsActive */
    if (typeof subcontractorProductObject.isActive === "undefined") {
        subcontractorProductObject.isActive = false
    }

    /* SubPackaging */
    if (subcontractorProductObject.brand) {
        const currentsSubPackaging = []
        for (const i in subcontractorProductObject.subPackaging) {
            const current = subcontractorProductObject.subPackaging[i]
            currentsSubPackaging.push({ value: current.id, label: current.get("name") })
        }
        subcontractorProductObject.subPackaging = currentsSubPackaging
    }

    /* ReusableSubPackaging */
    if (subcontractorProductObject.brand) {
        const currentsSubPackaging = []
        for (const i in subcontractorProductObject.reusableSubPackagings) {
            const current = subcontractorProductObject.reusableSubPackagings[i]
            currentsSubPackaging.push({ value: current.id, label: current.get("name") })
        }
        subcontractorProductObject.reusableSubPackagings = currentsSubPackaging
    }

    /* internalTag */
    if (subcontractorProductObject.internalTag) {
        subcontractorProductObject.internalTag = subcontractorProductObject.internalTag.map(elem => {
            return { value: elem.id, label: elem && elem.get && elem.get("name") ? elem.get("name") : "Inconnu" }
        })
    }
    /* SubcontractorCommercialNames */
    if (subcontractorProductObject.subcontractorCommercialNames) {
        subcontractorProductObject.subcontractorCommercialNames = subcontractorProductObject.subcontractorCommercialNames.filter(subcontractorProduct => !!subcontractorProduct.commercialName).map(subcontractorCommercialName => {
            return {
                id: subcontractorCommercialName.commercialName.id,
                label: subcontractorCommercialName.commercialName.get("name"),
                pourcentage: subcontractorCommercialName.pourcentage
            }
        })

        subcontractorProductObject.subcontractorCommercialNames.sort(function (a, b) {
            return b.pourcentage - a.pourcentage
        })
    }

    /* Chef */
    subcontractorProductObject.chef = subcontractorProductObject.chef && subcontractorProductObject.chef.id
        ? subcontractorProductObject.chef.id
        : ""
    /* Costing */
    const pvcInfo = getPVC("SUBCONTRACTORPRODUCT", subcontractorProductObject.type, subcontractorIngredientCost)
    subcontractorProductObject.pvc = pvcInfo.pvc
    subcontractorProductObject.packagingCost = packagingCost
    subcontractorProductObject.subcontractorIngredientCost = subcontractorIngredientCost
    subcontractorProductObject.netWeight = subcontractorNetWeight
    subcontractorProductObject.totalCost = packagingCost + subcontractorIngredientCost
    return subcontractorProductObject
}

function checkRulesValidity(rules) {
    if (!rules) {
        return false
    }
    const keysToCheck = ["RECIPE", "SUBCONTRACTORPRODUCT"]
    let rulesOk = true

    for (let i = 0, l = KFC_BRANDS.length; i < l && rulesOk; i++) {
        const brandName = KFC_BRANDS[i].name

        if (!rules[brandName]) {
            rulesOk = false
        } else {
            for (let j = 0, ll = keysToCheck.length; j < ll && rulesOk; j++) {
                if (!rules[brandName][keysToCheck[j]]) {
                    rulesOk = false
                }
            }
        }
    }

    return rulesOk
}

export function reportRulesInitialValues(rules) {
    if (!checkRulesValidity(rules)) {
        const newRules = {}
        const reportObject = {}

        forEachBrand(brand => {
            reportObject[brand.name] = {
                saleDate: null
            }
        })

        forEachBrand(brand => {

            newRules[brand.name] = {
                RECIPE: {
                    productionDate: "0",
                    packagingDate: "0"
                },
                SUBCONTRACTORPRODUCT: {
                    productionDate: "0",
                    packagingDate: "0"
                },
                isReporting: false,
                isReportingVolume: false,
                autoSaveableBrands: reportObject
            }
        })

        return newRules
    }

    const ret = {}
    Object.keys(rules).forEach(brandField => {
        if (typeof rules[brandField] === "object") {
            ret[brandField] = {}
            ret[brandField].isReporting = Array.isArray(rules[brandField].autoSaveableBrands) && rules[brandField].autoSaveableBrands.length > 0
            ret[brandField].isReportingVolume = Array.isArray(rules[brandField].volumeBrands) && rules[brandField].volumeBrands.length > 0

            const reportObject = {}
            forEachBrand(brand => {
                if (brand.name !== brandField) {
                    if (Array.isArray(rules[brandField].autoSaveableBrands) && rules[brandField].autoSaveableBrands.length > 0) {
                        const currentValue = rules[brandField].autoSaveableBrands.find(el => el.brand === brand.name)
                        if (currentValue) {
                            reportObject[brand.name] = {
                                saleDate: currentValue.saleDate < 0 ? currentValue.saleDate * -1 : currentValue.saleDate
                            }
                        }
                        else {
                            reportObject[brand.name] = {
                                saleDate: null
                            }
                        }
                    }
                    else {
                        reportObject[brand.name] = {
                            saleDate: null
                        }
                    }
                }
            })
            ret[brandField].autoSaveableBrands = reportObject
            ret[brandField].volumeReporting = rules[brandField].volumeReporting
            ret[brandField].isReportingVolume = !!rules[brandField].volumeReporting
            Object.keys(rules[brandField]).forEach(productType => {
                if (typeof rules[brandField][productType] === "object" && rules[brandField][productType] && !Array.isArray(rules[brandField][productType])) {
                    ret[brandField][productType] = {}
                    Object.keys(rules[brandField][productType]).forEach(key => {
                        ret[brandField][productType][key] = rules[brandField][productType][key] < 0
                            ? String(-rules[brandField][productType][key])
                            : String(rules[brandField][productType][key])
                    })
                } else if (Array.isArray(rules[brandField][productType])) {
                    for (const idx in rules[brandField][productType]) {
                        ret[brandField][`isReportingTo${rules[brandField][productType][idx].brand}`] = true
                    }
                }
            })
        }
    })

    return ret
}

export function reportRulesJsonToReportRulesParseFormat(rules) {
    const ret = {}
    Object.keys(rules).forEach(brand => {
        ret[brand] = {
            RECIPE: {},
            SUBCONTRACTORPRODUCT: {}
        }
        ret[brand].RECIPE.productionDate = Number(-rules[brand].RECIPE.productionDate)
        ret[brand].RECIPE.packagingDate = Number(rules[brand].RECIPE.packagingDate)
        ret[brand].SUBCONTRACTORPRODUCT.productionDate = Number(-rules[brand].SUBCONTRACTORPRODUCT.productionDate)
        ret[brand].SUBCONTRACTORPRODUCT.packagingDate = Number(rules[brand].SUBCONTRACTORPRODUCT.packagingDate)
        ret[brand].isReporting = rules[brand].isReporting
        ret[brand].isReportingVolume = rules[brand].isReportingVolume
        ret[brand].volumeReporting = rules[brand].volumeReporting

        const finalAutoSaveableBrands = []
        forEachBrand(currentBrand => {
            if (currentBrand.name !== brand) {
                if (rules[brand].autoSaveableBrands[currentBrand.name] && rules[brand].autoSaveableBrands[currentBrand.name].saleDate !== null) {
                    finalAutoSaveableBrands.push({
                        brand: currentBrand.name,
                        saleDate: Number(rules[brand].autoSaveableBrands[currentBrand.name].saleDate) > 0 ? Number(rules[brand].autoSaveableBrands[currentBrand.name].saleDate) * -1 : Number(rules[brand].autoSaveableBrands[currentBrand.name].saleDate)
                    })
                }
            }
        })
        ret[brand].autoSaveableBrands = finalAutoSaveableBrands
    })

    return ret
}

const defaultDispatchValue = {
    sendProvince: false,
    waste: undefined,
    minSTARTER: undefined,
    maxSTARTER: undefined,
    minDESSERT: undefined,
    maxDESSERT: undefined,
    minMAIN_COURSE: undefined,
    maxMAIN_COURSE: undefined
}

export function distributionCentersPriorityInitialValues(distributionCenters, brand) {
    return {
        distributionCenters: Array.isArray(distributionCenters)
            ? distributionCenters.sort((a, b) => {
                return a.dispatch.priority - b.dispatch.priority
            }).filter(elem => elem.brand === brand).map(elem => {
                return {
                    objectId: elem.objectId,
                    name: elem.name,
                    dispatch:
                        elem.dispatch
                            ? getDispatchFormat(elem.dispatch)
                            : defaultDispatchValue
                }
            })
            : []
    }
}

export function sendOrderVoucherMail(orderId, status) {
    return actionWithLoader(async (dispatch) => {
        try {
            let url = `${getServerUrl()}/mailJet/sendOrderVoucherMail/${orderId}`

            if (status === orderUpdateStatus.TO_CANCEL) {
                url = `${getServerUrl()}/mailJet/sendCancelAndReplaceOrderVoucherMail/${orderId}`
            } else if (status === orderUpdateStatus.CANCELLED) {
                url = `${getServerUrl()}/mailJet/sendCancelOrderVoucherMail/${orderId}`
            }

            const result = await axiosCall("get", url)

            if (result.status === 200) {
                dispatch({
                    type: "ORDERS_OPEN_SNACKBAR",
                    ordersSnackBar: { open: true, duration: 10000, type: "success", message: "Le bon de commande a bien été envoyé par mail au fournisseur" }
                })
            }
            else {
                dispatch({
                    type: "ORDERS_OPEN_SNACKBAR",
                    ordersSnackBar: { open: true, duration: 10000, type: "error", message: "Le bon de commande n'a pas pu être envoyé par mail au fournisseur" }
                })
            }
        }
        catch (e) {
            dispatch({
                type: "ORDERS_OPEN_SNACKBAR",
                ordersSnackBar: { open: true, duration: 10000, type: "error", message: "Le bon de commande n'a pas pu être envoyé par mail au fournisseur" }
            })
        }
    })
}

export function sendCreditNoteMail(orderId) {
    return actionWithLoader(async () => {
        try {
            const url = `${getServerUrl()}/mailJet/sendCreditNoteMail/${orderId}`

            await axiosCall("get", url)
        }
        catch (e) {
            Promise.reject(e)
        }
    })
}

export async function getSupplierItemProductType(supplierItem) {
    const supplierItemProductTypes = await getProductTypes()
    if (supplierItem) {
        if (supplierItem.productType === "PACKAGING"
            || supplierItem.productType === "SUB_PACKAGING"
            || supplierItem.productType === "LABEL"
            || supplierItem.productType === "HYGIENE"
            || supplierItem.productType === "WAREHOUSE_CONSUMABLE"
            || supplierItem.productType === "KITCHEN_EQUIPMENT"
            || supplierItem.productType === "SECURITY"
        ) {
            return productTypeToAdd.find(el => el.value === supplierItem.productType).label
        } else {
            return supplierItemProductTypes?.find(el => el.kfcName === supplierItem.productType).label
        }
    }
}

export const updateRecipeOnFieldChange = async (section, steps, isProductionStep = true) => {
    steps.forEach((step) => {
        const currentStep = getProductionStepPointerObj(step, isProductionStep)

        currentStep.stepComponents?.forEach(ingredient => {
            const computedIngredientData = computeIngredientData(ingredient, "productionSteps")
            ingredient.netWeight = computedIngredientData.netWeight
            ingredient.cost = computedIngredientData.cost
            ingredient.realCost = computedIngredientData.realCost
            ingredient.cookingModeLabel = computedIngredientData.cookingModeLabel
            ingredient.transformationMode = ingredient.transformationMode ? ingredient.transformationMode : null
        })

        computeStepData(step, "stepComponents", isProductionStep)
    })

    if (!section) return
    computeSectionData(section, "productionSteps", "stepComponents")
}

export const computeStepNumbers = (formValues) => {
    let stepNumbersStorage = {}
    formValues && formValues.sections.forEach(section => {
        let stepNumber = 1
        section.productionSteps.forEach(productionStep => {
            if (!productionStep.reusable) {
                productionStep.step.stepNumber = stepNumber
                stepNumbersStorage[productionStep.step.index] = stepNumber
                stepNumber++
            } else {
                productionStep.step.productionSteps.forEach(stepReusable => {
                    stepReusable.stepNumber = stepNumber
                    stepNumbersStorage[stepReusable.index] = stepNumber
                    stepNumber++
                })
            }
        })
    })

    return stepNumbersStorage
}

export const computeProductionStepsNumbers = (productionSteps, stepNumbersStorage = {}, stepNumber = 1) => {
    productionSteps && productionSteps.forEach(productionStep => {
        if (!productionStep.reusable) {
            productionStep.step.stepNumber = stepNumber
            stepNumbersStorage[productionStep.step.index] = stepNumber
            stepNumber++
        } else {
            if (productionStep.step) {
                productionStep.step.productionSteps.forEach(stepReusable => {
                    stepReusable.stepNumber = stepNumber
                    stepNumbersStorage[stepReusable.index] = stepNumber
                    stepNumber++
                })
            }
        }
    })

    return stepNumbersStorage
}

export const computeReusableProductionStepsNumbers = (reusableProductionSteps, stepNumbersStorage = {}, stepNumber = 1) => {
    if (reusableProductionSteps.productionSteps) {
        reusableProductionSteps.productionSteps.forEach(stepReusable => {
            stepReusable.stepNumber = stepNumber
            stepNumbersStorage[stepReusable.index] = stepNumber
            stepNumber++
        })
    } else {
        reusableProductionSteps.forEach(stepReusable => {
            stepReusable.stepNumber = stepNumber
            stepNumbersStorage[stepReusable.index] = stepNumber
            stepNumber++
        })
    }

    return stepNumbersStorage
}

export const sortReusableStepsByName = (reusableSteps) => {
    if (!reusableSteps || !Array.isArray(reusableSteps)) return
    return reusableSteps.sort((a, b) => {
        let fieldA, fieldB
        if (a.get) {
            fieldA = a.get("name") || a.get("description")
        }
        if (!a.get) {
            fieldA = a.name || a.description

        }
        if (b.get) {
            fieldB = b.get("name") || b.get("description")
        }
        if (!b.get) {
            fieldB = b.name || b.description
        }
        return fieldA.localeCompare(fieldB)
    })
}

export const refreshPage = () => {
    window.location.reload()
}