import React, { useEffect, useMemo, useState, useCallback } from "react"
import moment from "moment"
import { isDateObject } from "../../utils"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import flexModule from "flexsearch"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  Box,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Chip
} from "@mui/material"
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep"
import SavedSearchIcon from "@mui/icons-material/SavedSearch"

import Filter from "../Filter/Filter"
import MoreMenu from "../MoreMenu"

import { STOPWORDS } from "../../utils/constant"
import { COLORS } from "../../utils"
import AdditionalFiltersModal from "./AdditionalFiltersModal"
import { isObject } from "lodash"

import dayjs from "dayjs"
const isBetween = require("dayjs/plugin/isBetween")
dayjs.extend(isBetween)
const customParseFormat = require("dayjs/plugin/customParseFormat")
dayjs.extend(customParseFormat)

const getCellAlignment = (align = "left") => {
  switch (align) {
    case "center":
      return "center"
    case "right":
      return "flex-end"
    default:
      return "flex-start"
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  row: {
    display: "flex"
  },
  cell: {
    display: "flex",
    alignItems: "baseline"
  },
  listHead: {
    color: "#262626",
  },
  bodyCell: {
    color: COLORS.LABEL_GREY,
    cursor: ({ rowClickable }) => rowClickable ? "pointer" : "default",
    padding: [[17, 16]],
    display: "flex"
  },
  flexCell: {
    flex: 1,
  },
  withMargin: {
    marginLeft: 16,
    [theme.breakpoints.down("md")]: {
      marginLeft: 24
    }
  },
  titleAndIconContainer: {
    height: 48,
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    justifyContent: "space-between",
    marginRight: 4,
    [theme.breakpoints.down("md")]: {
      marginRight: 12
    }
  },
  titleAndIconContainerMb20: {
    composes: "$titleAndIconContainer",
    marginBottom: 16
  },
  additionalFilterChip: {
    color: "#262626",
    fontSize: "14px",
    padding: "8px 16px",
    marginRight: "8px"
  }
}))

/**
 * Filters an array of rows based on the provided filters.
 *
 * @param {Array} rows - The array of rows to be filtered.
 * @param {Object} filters - The filters to be applied to the rows.
 * @return {Array} - The filtered array of rows.
 */
const filterRows = (rows = [], filters = {}, columns = [], index) => {
  if (
    !rows ||
    !Array.isArray(rows) ||
    !filters ||
    typeof filters !== "object"
  ) {
    return rows // Return original rows if input is invalid
  }

  return rows.filter((row) => {
    return Object.keys(filters).every((key) => {
      const { type: filterType, multiple, fullText, strict, customFilterFunction } = columns.find(column => column.key === key && column.filter?.filterable === true).filter

      const filterValue = filters[key]
      const rowValue = row[key]

      if (customFilterFunction) {
        return customFilterFunction(row, filters[key])
      }

      switch (filterType) {
        case "text":
          if (fullText) {
            const results = index.search(filterValue, { field: key })
            return results[0]?.result.some(result => result === row.id)
          } else if (strict) {
            // for case insensitive
            // Strict is useful when we want to check Ids: BM84ska is not the same id as bM84Ska
            return String(rowValue).trim().includes(
              filterValue.trim()
            )
          } else {
            return String(rowValue).toLowerCase().trim().includes(
              filterValue.toLowerCase().trim()
            )
          }
        case "select":
          if (multiple) {
            if (strict) {
              return filterValue.every(
                (value) =>
                  String(rowValue).toLowerCase().split(",").map(val => val.trim()).includes(String(value).toLowerCase())
              )
            }
            else {
              return filterValue.some(
                (value) =>
                  String(rowValue).toLowerCase().split(",").map(val => val.trim()).includes(String(value).toLowerCase())
              )
            }
          } else {
            return String(rowValue).toLowerCase().split(",").map(val => val.trim()).includes(String(filterValue).toLowerCase())
          }
        case "date":
          // It is mandatory to format the rowValue to DD/MM/YYYY
          const filterDate = moment(filterValue).format("DD/MM/YYYY")
          return filterDate === rowValue
        case "dateRange":
          const { start, end } = filterValue
          const filterStart = dayjs(start)
          const filterEnd = dayjs(end)
          const parsedRowValue = dayjs(rowValue, "DD/MM/YYYY")
          return dayjs(parsedRowValue).isBetween(filterStart, filterEnd, "day", "[)")
        default:
          return true
      }
    })
  })
}

/**
 * Compares two values and returns a number indicating their relative order.
 *
 * @param {any} a - The first value to compare.
 * @param {any} b - The second value to compare.
 * @param {string} [type] - The type of comparison to perform. Defaults to undefined.
 *                          If type is "date", the values will be treated as dates and compared accordingly.
 * @return {number} - A number indicating the relative order of the two values:
 *                    -1 if a is less than b,
 *                     0 if a is equal to b,
 *                     1 if a is greater than b.
 */
const compareAsc = (a, b, type = "text") => {
  switch (type) {
    case "date":
      return moment(a, "DD/MM/YYYY").isBefore(moment(b, "DD/MM/YYYY")) ? -1 : 1
    case "text":
      return String(a).toLowerCase() < String(b).toLowerCase() ? -1 : 1
    case "number":
      return Number(a) < Number(b) ? -1 : 1
    default:
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
  }
}

/**
 * Compares two values in descending order.
 *
 * @param {any} a - The first value to compare.
 * @param {any} b - The second value to compare.
 * @return {number} Returns the result of the comparison.
 */
const compareDesc = (a, b, type = "text") => {
  return compareAsc(b, a, type)
}

/**
 * Sorts the rows of a table based on the specified order and orderBy column.
 *
 * @param {Array} columns - An array of objects representing the columns of the table.
 * @param {Array} rows - An array of objects representing the rows of the table.
 * @param {string} order - The sorting order. Can be "asc" for ascending or "desc" for descending.
 * @param {string} orderBy - The column ID to sort the rows by.
 * @return {Array} - The sorted array of rows.
 */
const sortRows = (columns, rows, order, orderBy) => {
  if (!orderBy) return [...rows]
  const sortedRows = [...rows]
  const comparator = order === "desc" ? compareDesc : compareAsc
  sortedRows.sort((a, b) => comparator(a[orderBy], b[orderBy], columns.find(column => column.key === orderBy)?.dataType))
  return sortedRows
}

const reformatAdditionalFilter = (filterKey, filterValue, additionalFilter, result = []) => {
  const label = additionalFilter.label
  const filterConfig = additionalFilter.filter
  const undeletable = additionalFilter.undeletable === true
  let text = filterValue
  if (filterConfig.type === "select") {
    text = filterConfig.options?.find(option => option.value === filterValue)?.text
  }
  if (isDateObject(text)) {
    text = dayjs(filterValue).format("DD/MM/YYYY")
  }
  if (isObject(text) && text.isRange) {
    text = `${dayjs(text.start).format("DD/MM/YYYY")} - ${dayjs(text.end).format("DD/MM/YYYY")}`
  }
  result.push({ key: filterKey, label, value: filterValue, text: text || filterValue, undeletable })
  return result
}

const reformatAdditionalFiltersValues = (newFilters, additionalFilters) => {
  const reformattedFilters = []
  for (const [key, value] of Object.entries(newFilters)) {
    const additionalFilter = additionalFilters.find(filter => filter.key === key && filter.filter.filterable === true)
    if (Array.isArray(value)) {
      value.forEach(subValue => reformatAdditionalFilter(key, subValue, additionalFilter, reformattedFilters))
    } else {
      reformatAdditionalFilter(key, value, additionalFilter, reformattedFilters)
    }
  }
  return reformattedFilters
}

const emptyColumn = {
  label: "",
  filterable: false,
  sortable: false,
  filter: {
    label: "",
    filterable: false
  },
  key: "empty",
  width: 24
}

const DataGrid = ({
  columns = [],
  data = [],
  rowsPerPageOptions = [10, 15, 20, 25, 50, 100],
  withFilters = false,
  defaultAdditionalFilters = {},
  title,
  topClassName,
  menus = [],
  onRowClick,
  rowLabel,
  onRowMouseEnter,
  onRowMouseLeave,
  onFilterChange,
  onResetFilters,
  sxBodyCell = {},
  withTitleMargin = true,
  additionalFilters = [],
  additionalFiltersPreCallback,
  renderRecapRow = null,
  asyncFilterFunction = null,
  removeDeleteFilterIcon = null,
  tableContainerStyle = null,
  defaultPostResetFilter = null,
  initialValues = {},
  fixedAdditionalFilters = {}
}) => {
  const [filters, setFilters] = useState(initialValues)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0] || 5)
  const [displayableData, setDisplayableData] = useState([...data])
  const [additionalFilterModalShown, setAdditionalFilterModalShown] = useState(false)
  const [selectedAdditionalFilters, setSelectedAdditionalFilters] = useState([])
  const [activeAdditionalFilters, setActiveAdditionalFilters] = useState(defaultAdditionalFilters)
  const [additionalFiltersOnDisplay, setAdditionalFiltersOnDisplay] = useState([])
  const additionalFiltersKeys = useMemo(() => additionalFilters.map(filter => filter.key), [additionalFilters])

  const classes = useStyles({ rowClickable: !!onRowClick })

  const columnsWithEmptySpaces = useMemo(() => [emptyColumn, ...columns, emptyColumn], [columns])

  const [resetFilters, setResetFilters] = useState(false)

  const [orderBy, setOrderBy] = useState("") // State to track the sorted column
  const [order, setOrder] = useState("asc") // State to track sorting order

  const searchIndex = useMemo(() => {
    const columnsToIndex = columns
      .filter((column) => column.filter?.type === "text" && column.filter?.fullText)
      .map((column) => column.key)

    if (columnsToIndex.length > 0 && data.length > 0) {
      const newIndex = new flexModule.Document({
        language: "fr",
        tokenize: "forward",
        rtl: true,
        optimize: true,
        encoder: "simple",
        document: {
          field: [...columnsToIndex],
        },
        filter: STOPWORDS
      })

      data.forEach((document) => {
        newIndex.add(document)
      })

      return newIndex
    }
    return null
  }, [data])

  const _updateFilters = (filters) => {
    setFilters({ ...filters })
  }

  // used to update filters when user changes them
  const _onFilterChange = useCallback((newFilters) => {
    // get only the current filter changed (e.g: { supplier: "xxxx" })
    onFilterChange?.(newFilters)
    const filtersFromAdditionalFilters = {}
    for (const key in filters) {
      if (additionalFiltersKeys.includes(key)) {
        filtersFromAdditionalFilters[key] = filters[key]
      }
    }
    for (const key of additionalFiltersKeys) {
      if (filters[key]) {
        filtersFromAdditionalFilters[key] = filters[key]
      } else if (defaultAdditionalFilters[key]) {
        filtersFromAdditionalFilters[key] = defaultAdditionalFilters[key]
      }
    }
    _updateFilters({ ...filtersFromAdditionalFilters, ...newFilters })
  }, [filters])

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setDisplayableData([...data])
  }, [data])


  useEffect(() => {
    const completeColumns = [...columns, ...additionalFilters]
    // Apply filters whenever filters or data change
    if (asyncFilterFunction) {
      // we reload from db (async filter function is provided)
      //for tables for which there's too many doc in db to load them in one fell swoop)
      asyncFilterFunction(filters, order, orderBy)
      return
    }
    const filteredData = filterRows(data, filters, completeColumns, searchIndex)

    // Sorting
    const sortedRows = sortRows(completeColumns, filteredData, order, orderBy)


    setDisplayableData([...sortedRows])

    setPage(0) // Reset to the first page when filtering or sorting changes
  }, [filters, JSON.stringify(filters), JSON.stringify(data), orderBy, order])

  const paginatedData = displayableData.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  )

  const handleFiltersReset = () => {
    setResetFilters(true) // Set the resetFilters state to trigger the reset
  }

  const resetFiltersCallback = () => {
    setResetFilters(false) // Reset the resetFilters state after resetting
  }

  const handleSort = (columnKey) => {
    const isAsc = orderBy === columnKey && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(columnKey)
  }

  const selectAdditionalFilters = (selectedFilter, isAddition = true) => {
    if (!isAddition) {
      setSelectedAdditionalFilters(prev => prev.filter(filter => filter.key !== selectedFilter.key))
      return
    }
    setSelectedAdditionalFilters(prev => ([...prev, selectedFilter]))
  }

  const sanitizeAdditionalFilters = (additionalFilters) => {
    for (const key in additionalFilters) {
      if (!additionalFilters[key] || !additionalFilters[key].length === 0) {
        delete additionalFilters[key]
      }
    }
  }

  const mergeAdditionalFiltersWithFilters = (additionalFilters, filters) => {
    const newFilters = { ...filters }
    for (const key in newFilters) {
      if (additionalFiltersKeys.includes(key) && !additionalFilters[key]) {
        delete newFilters[key]
      }
    }
    return { ...newFilters, ...additionalFilters } // if same key, second object overrides the first
  }

  const applyAdditionalFilters = (additionalFilters) => {
    setAdditionalFilterModalShown(false)
    sanitizeAdditionalFilters(additionalFilters)
    // store values for next time user opens modal
    setActiveAdditionalFilters(additionalFilters)
  }

  const deleteAdditionalFilter = (filter) => {
    const { key, value } = filter
    const clonedAdditionalFilters = { ...activeAdditionalFilters }
    if (Array.isArray(clonedAdditionalFilters[key])) {
      clonedAdditionalFilters[key] = clonedAdditionalFilters[key].filter(val => val !== value)
      if (!clonedAdditionalFilters[key].length) {
        delete clonedAdditionalFilters[key]
      }
    } else {
      delete clonedAdditionalFilters[key]
    }
    setActiveAdditionalFilters(clonedAdditionalFilters)
  }

  useEffect(() => {
    // update all filters so as to trigger a new data filtering
    const updatedFilters = mergeAdditionalFiltersWithFilters(activeAdditionalFilters, filters)
    setFilters(updatedFilters)
    // reformat additional filters for chip display
    const formattedFilters = reformatAdditionalFiltersValues(activeAdditionalFilters, additionalFilters)
    setAdditionalFiltersOnDisplay(formattedFilters)
  }, [activeAdditionalFilters])

  useEffect(() => {
    if (resetFilters) {
      // Clear filters when resetFilters is true
      setFilters({})
      setActiveAdditionalFilters({})
      localStorage.removeItem("useStoredDataGridFilters")
      localStorage.removeItem("dataGridFilters")

      if (defaultPostResetFilter) {
        setActiveAdditionalFilters(fixedAdditionalFilters)
        setFilters(fixedAdditionalFilters)
      }
    }
  }, [resetFilters])

  const openAdditionalFiltersModal = () => {
    additionalFiltersPreCallback && additionalFiltersPreCallback()
    setAdditionalFilterModalShown(true)
  }

  const closeAdditionalFiltersModal = () => {
    setAdditionalFilterModalShown(false)
  }

  return (
    <div className={classes.root}>
      {
        withFilters || title || Array.isArray(menus) && menus.length || Array.isArray(additionalFilters) && additionalFilters.length
          ? (
            <Box
              className={clsx(removeDeleteFilterIcon === true ? classes.titleAndIconContainer : classes.titleAndIconContainerMb20, topClassName)}>
              <Typography variant="h6" id="tableTitle" className={clsx({ [classes.withMargin]: withTitleMargin })}>{title || ""}</Typography>
              <Stack direction="row" spacing={0}>
                {
                  Array.isArray(additionalFilters) && additionalFilters.length
                    ? (
                      <Tooltip disableInteractive title="Appliquer des filtres additionnels">
                        <IconButton
                          aria-label="Appliquer des filtres additionnels"
                          onClick={openAdditionalFiltersModal}
                          size="large"
                        >
                          <SavedSearchIcon sx={{ color: COLORS.LABEL_GREY }} />
                        </IconButton >
                      </Tooltip>
                    )
                    : null
                }
                {
                  withFilters && removeDeleteFilterIcon !== true
                    ? (
                      <Tooltip disableInteractive title="Réinitialiser les filtres" sx={{
                        ...(additionalFilters.length === 0 && {
                          paddingRight: 3
                        })
                      }}>
                        <IconButton
                          aria-label="Réinitialiser les filtres"
                          onClick={handleFiltersReset}
                          size="large"
                        >
                          <DeleteSweepIcon sx={{ color: COLORS.LABEL_GREY }} />
                        </IconButton >
                      </Tooltip>
                    )
                    : null
                }
                {
                  Array.isArray(menus)
                    ? <MoreMenu menus={menus} />
                    : null
                }
              </Stack>
            </Box>
          )
          : null
      }
      {additionalFiltersOnDisplay && additionalFiltersOnDisplay.length > 0 &&
        <Stack direction="row" spacing={0} sx={{ justifyContent: "end", paddingBottom: "16px" }}>
          {additionalFiltersOnDisplay.map((filter, index) => {
            const onDeleteTrigger = filter.undeletable === false ? () => deleteAdditionalFilter(filter) : false
            return <Chip
              key={index}
              label={filter.label + ": " + filter.text}
              onDelete={onDeleteTrigger}
              className={classes.additionalFilterChip}
            />
          })}
        </Stack>
      }
      {renderRecapRow && renderRecapRow(displayableData)}
      <TableContainer component={Paper} elevation={2} sx={tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow className={classes.row}>
              {columnsWithEmptySpaces.map((column) => (
                <TableCell
                  key={column.key}
                  className={clsx(classes.cell, classes.listHead, { [classes.flexCell]: !column.width || column.width === "auto" })}
                  sx={{
                    minWidth: column.width || "auto",
                    maxWidth: column.width || "auto",
                    width: column.width || "auto",
                    justifyContent: getCellAlignment(column.align),
                  }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : "asc"}
                      onClick={() => handleSort(column.key)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
            {
              withFilters ?
                <Filter
                  filters={columnsWithEmptySpaces}
                  onFilterChange={_onFilterChange}
                  resetFilters={resetFilters}
                  resetFiltersCallback={resetFiltersCallback}
                  initialFilters={initialValues}
                  data={displayableData}
                  onReset={onResetFilters}
                />
                : null
            }
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow
                key={row.id}
                hover={!!onRowClick}
                onClick={() => onRowClick && onRowClick(row)}
                onMouseEnter={() => onRowMouseEnter?.(row)}
                onMouseLeave={() => onRowMouseLeave?.(row)}
                className={classes.row}
              >
                {columnsWithEmptySpaces.map((column) => (
                  <TableCell
                    key={column.key}
                    sx={{
                      minWidth: column.width || "auto",
                      maxWidth: column.width || "auto",
                      width: column.width || "auto",
                      justifyContent: getCellAlignment(column.align),
                      ...sxBodyCell
                    }}
                    className={clsx(classes.cell, classes.bodyCell, { [classes.flexCell]: !column.width || column.width === "auto" })}
                  >
                    {column.render ? column.render(row[column.key], row.id) : row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={displayableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage='Lignes par page:'
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}${rowLabel ? " " + rowLabel.toLowerCase() : ""}`}
      />
      <AdditionalFiltersModal
        open={additionalFilterModalShown}
        onClose={closeAdditionalFiltersModal}
        filters={additionalFilters}
        activeFilters={activeAdditionalFilters}
        selectedFilters={selectedAdditionalFilters}
        updateSelectedFilters={selectAdditionalFilters}
        applyFilters={applyAdditionalFilters}
      />
    </div>
  )
}

export default DataGrid
