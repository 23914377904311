import React from "react"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import { Box } from "@mui/material"

import { COLORS } from "../utils"

const useStyles = makeStyles({
    container: {
        display: "flex",
        padding: "0 20px",
        borderTop: `1px solid ${COLORS.DEFAULT_GREY}`,
        backgroundColor: "#fff"
    },
    rightAction: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
    }
})

const GenericBottomBarFooter = (props) => {
    const {
        rightAction,
        className,
    } = props

    const classes = useStyles()

    return (
        <Box className={clsx(classes.container, className)} >
                {
                    rightAction &&
                        <div className={classes.rightAction}>
                            {rightAction}
                        </div>
                }   
        </Box>
    )
}

export default GenericBottomBarFooter
