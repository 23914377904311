import { formatSupplierItemsAutocompleteOption } from "./supplierItemUtils"

export const type = {
	BOTH: { key: "BOTH", value: "0", label: "Packaging / Sous Packaging" },
	PACKAGING: { key: "PACKAGING", value: "1", label: "Packaging" },
	SUB_PACKAGING: { key: "SUB_PACKAGING", value: "2", label: "Sous Packaging" },
}

export const getPackagingString = (value) => {
	switch (value) {
		case "1":
			return "Packaging"
		case "2":
			return "Sous packaging"
		default:
			return ""
	}
}

export const packagingCategories =  [
	{ value: "1", label: "Plats" },
	{ value: "2", label: "Desserts / Entrées" },
	{ value: "3", label: "Couvercles" },
	{ value: "4", label: "Pots" },
	{ value: "5", label: "Autres" },
	{ value: "6", label: "LunchBag"}
]

export const packagingNature = [
	{ value: "REUSABLE", label: "Réemployable" },
	{ value: "DISPOSABLE", label: "Jetable" },
	{ value: "CAPPED", label: "Operculé" }
]

export const packagingCardOrder = ["CAPPED", "DISPOSABLE", "REUSABLE"]

export const brands = [
	{ value: "SEAZON", label: "Seazon" },
	{ value: "FOODCHERI", label: "Foodcheri" },
	{ value: "BOTH", label: "Les deux" }
]

export const packagingTypes = [
	{ value: "1", label: "Packaging" },
	{ value: "2", label: "Sous Packaging" }
]

export const getPackagingLabel = (items, type) => {
	const packaging =  items.find(p => p.value === type)

	if (!packaging) return ""
	return packaging.label
}

export const getPackagingLabelByType = (type) => {
	return getPackagingLabel(packagingNature, type)
}

export const getPackagingTypeLabel = (value) => {
	return getPackagingLabel(packagingTypes, value)
}

export const getPackagingCategoryLabel = (value) => {
	return getPackagingLabel(packagingCategories, value)
}

export const getPackagingResumeFormInitialValues = (packaging) => {
	if ((!packaging)) {
			return {
					brands: [],
					reference: "",
					name: ""
			}
	}

	return {
		name: packaging.name,
		reference: packaging.reference,
		brands: packaging.brands || [],
		categories: packaging.categories || [],
		type: packaging.type,
		nature: packaging.nature,
		price: packaging.price,
	}
}

export const getPackagingAttachmentFormInitialValues = (supplierItem) => {
	if (!supplierItem) {
		return {
			supplierItem: null
		}
	}

	return {
		supplierItem: formatSupplierItemsAutocompleteOption(supplierItem)
	}
}

export const getPackagingLogisticsFormInitialValues = (packaging) => {
	if (!packaging) {
		return {
			deepth: ""
		}
	}

	return {
		deepth: packaging.deepth,
		width: packaging.width,
		height: packaging.height,
		weight: packaging.weight,
	}
}

export const formatPackagingsAutocompleteOption = (packagings = []) => {
	return packagings.map(packaging => ({
		label: packaging.objectId + " - " + packaging.name.toLowerCase(),
		value: packaging.objectId,
		data: packaging,
	}))
}