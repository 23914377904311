import React from "react"
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import {
	COLORS,
	getDiffArrays, roundNumber
} from "../../utils"
import {
	unitsCategories,
	unitSelectValues,
	unitValues,
	unitsInitialValues,
	servingUnits
} from "../../utils/supplierItemUtils"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Select from "@mui/material/Select"

const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		width: 800,
		outline: "none",
		margin: "0 auto",
		top: "10%",
		position: "relative",
		borderRadius: 3,
		color: COLORS.BLACK,
		padding: 20
	},
	title: {
		margin: "10px 0",
		fontSize: 20
	},
	buttonsContainer: {
		marginTop: 50,
		width: "100%",
		display: "flex",
		justifyContent: "space-between"
	},
	field: {
		width: "100%",
		marginTop: 20
	},
	errorField: {
		color: COLORS.RED,
		fontSize: 14
	},
	firstTableCell: {
		padding: "15px 10px 0px 10px",
		border: "none",
		width: 100,
		color: COLORS.GREY_700
	},
	lastTableCell: {
		padding: "15px 10px 0px 10px",
		border: "none",
		width: 100,
		color: COLORS.GREY_700
	},
	tableCell: {
		border: "none",
		padding: 10,
		width: 100,
		color: COLORS.GREY_700
	},
	singleValueContainer: {
		display: "flex"
	},
	categoryTitle: {
		padding: "32px 55px 0px 32px",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontSize: 14,
		width: 250
	},
	conversionTable: {
		marginTop: 21
	},
	selectField: {
		color: "#555555",
		fontSize: "14px",
		lineHeight: "22px"
	}
}))

const UnitsForm = (props) => {
	const {
		supplierItem,
		onUpdateSupplierItem,
		onClose
	} = props
	
	const classes = useStyles()
	const initialValues = unitsInitialValues(supplierItem)
	
	const submitForm = async (values) => {
		onUpdateSupplierItem("UNITS", {units: values})
		onClose()
	}
	
	const renderUnitsOptions = () => {
		return unitSelectValues.map((el, i) =>
			<MenuItem key={i} value={el.value}>{el.label}</MenuItem>
		)
	}
	
	const computeFields = (data, values, setFieldValue) => {
		const copy = {...values}
		
		if (data.type === "conversions"){
			copy.conversions = data.conversions
		}
		else if (data.type === "unity"){
			copy.conversions = data.conversions
			copy[data.category].unity.unity = data.unity
		}
		else if (data.type === "servingUnit"){
			copy.servingUnit = data.servingUnit
		}
		else {
			copy[data.category].unity[data.field] = +data.value
		}
		
		//compute Weight
		for (const category of unitsCategories){
			if (copy[category.value].unity.unity !== 0) {
				const currentConversion = copy.conversions.find(el => el.type === copy[category.value].unity.unity)
				
				if (currentConversion){
					const newWeight = roundNumber(+copy[category.value].unity.quantity * +currentConversion.value, 5)
						
					setFieldValue(`${category.value}.weight`, newWeight)
					copy[category.value].weight = newWeight
				}
			}
			else {
				setFieldValue(`${category.value}.weight`, +copy[category.value].unity.quantity)
				copy[category.value].weight = +copy[category.value].unity.quantity
			}
		}
		
		//compute Price
		for (const category of unitsCategories){
			if (category.value !== "billing"){
				const newPrice = roundNumber((+copy.billing.price * +copy[category.value].weight) / +copy.billing.weight, 2)
				
				setFieldValue(`${category.value}.price`, newPrice)
				copy[category.value].price = newPrice
			}
		}
	}
	
	const handleConversionChange = (input, index, values, setFieldValue) => {
		const newValue = +input.target.value
		const newConversions = [...values.conversions]
		
		newConversions[index].value = newValue
		setFieldValue("conversions", newConversions)
		
		const data = {
			type: "conversions",
			conversions: newConversions
		}
		
		computeFields(data, values, setFieldValue)
	}
	
	const handleQuantityChange = (input, category, values, setFieldValue) => {
		const newValue = +input.target.value
		
		setFieldValue(`${category}.unity.quantity`, newValue)
		
		const data = {
			type: "quantity",
			category,
			field: "quantity",
			value: newValue
		}
		
		computeFields(data, values, setFieldValue)
	}
	
	const handleUnityChange = (input, category, values, setFieldValue) => {
		const newValue = input.target.value
		
		setFieldValue(`${category}.unity.unity`, newValue)
		
		//get selected unity of each categories
		const selectedUnits = []
		if (values.servingUnit === "piece") {
			selectedUnits.push(2)
		}
		
		for (const item of unitsCategories){
			if (item.value === category){
				selectedUnits.push(newValue)
			}
			else {
				selectedUnits.push(values[item.value].unity.unity)
			}
		}
		
		//update conversions array
		let newConversions = [...values.conversions]
		
		const deletedUnits = getDiffArrays(values.conversions.map(el => el.type), selectedUnits)
		const newUnits = getDiffArrays(selectedUnits, values.conversions.map(el => el.type))
		
		newConversions = newConversions.filter(el => !deletedUnits.includes(el.type))
		
		for (const item of newUnits){
			if (item !== 0){
				newConversions.push({
					value: 0,
					type: item
				})
			}
		}
		
		newConversions.sort(function(a, b) {
			return a.type - b.type
		})
		
		setFieldValue("conversions", newConversions)
		
		const data = {
			type: "unity",
			category,
			unity: newValue,
			conversions: newConversions
		}
		
		computeFields(data, values, setFieldValue)
	}

	const handleServingUnitChange = (input, values, setFieldValue) => {
    const newValue = input.target.value
    setFieldValue("servingUnit", newValue)

		// Check if the "piece" conversion field is already set
		const unitiesSetToTwo = Object.values(values).filter(value => value.unity && value.unity.unity === 2)
		if (unitiesSetToTwo.length === 0) {
			let newConversions = [...values.conversions]
			if (newValue === "piece") {
				const existingPieceConversion = newConversions.find(el => el.type === 2)
					if (!existingPieceConversion) {
							newConversions.push({ value:0, type: 2 })
					}
			}
			else {
					newConversions = newConversions.filter(el => el.type !== 2)
			}
    setFieldValue("conversions", newConversions)
	}
}
	
	const getUnitValue = (item, index, category, values, setFieldValue, handleChange) => {
		switch (item.value) {
			case "unity":
				return (
					<TableRow className={classes.tableRow} key={index}>
						<TableCell className={classes.firstTableCell}>
							{ item.label }
						</TableCell>
						<TableCell className={classes.tableCell}>
							<TextField
								variant="standard"
								name={`${category}.${item.value}.name`}
								value={values[category][item.value].name}
								label="Libellé"
								onChange={handleChange}
							/>
						</TableCell>
						<TableCell className={classes.tableCell}>
							<TextField
								variant="standard"
								name={`${category}.${item.value}.quantity`}
								value={values[category][item.value].quantity}
								label="Quantité"
								type="number"
								onChange={(e) => {handleQuantityChange(e, category, values, setFieldValue)}}
								InputProps={{
									inputProps: { min: 1 }
								}}
							/>
						</TableCell>
						<TableCell className={classes.lastTableCell}>
							<Select
								variant="standard"
								name={`${category}.${item.value}.unity`}
								value={values[category][item.value].unity}
								onChange={(e) => {handleUnityChange(e, category, values, setFieldValue)}}
							>
								{ renderUnitsOptions() }
							</Select>
						</TableCell>
					</TableRow>
				)
			case "weight":
				return (
					<TableRow className={classes.tableRow} key={index}>
						<TableCell className={classes.tableCell}>
							{ item.label }
						</TableCell>
						<TableCell className={classes.tableCell}>
							{ `${roundNumber(values[category][item.value], 5)} kg` }
						</TableCell>
						<TableCell className={classes.tableCell} />
						<TableCell className={classes.tableCell}/>
					</TableRow>
				)
			case "price":
				return (
					<TableRow className={classes.tableRow} key={index}>
						<TableCell className={classes.tableCell}>
							{ item.label }
						</TableCell>
						<TableCell className={classes.tableCell}>
							{ `${roundNumber(values[category][item.value], 2)} €` }
						</TableCell>
						<TableCell className={classes.tableCell} />
						<TableCell className={classes.tableCell}/>
					</TableRow>
				)
			default:
				return ""
		}
	}
	
	const renderUnits = (category, values, setFieldValue, handleChange) => {
		return (
			<Table className={classes.table}>
				<TableBody className={classes.tableBody}>
					{
						unitValues.map((el, i) => {
							return getUnitValue(el, i, category, values, setFieldValue, handleChange)
						})
					}
				</TableBody>
			</Table>
		)
	}
	
	return (
		<div className={classes.container}>
			<p className={classes.title}>Editer les unités</p>
			<Formik
				initialValues={initialValues}
				onSubmit={submitForm}
			>
				{({
						values,
						handleChange,
						setFieldValue,
						handleSubmit
					}) => {
					return (
						<Form>
							{
								unitsCategories.map((category, index) =>
									<div key={index} className={classes.singleValueContainer}>
										<div className={classes.categoryTitle}>
											{ category.label }
										</div>
										<div>
											{ renderUnits(category.value, values, setFieldValue, handleChange) }
										</div>
									</div>
								)
							}
							<div className={classes.singleValueContainer}>
								<div className={classes.categoryTitle}>
									Conversions
								</div>
								<div>
									<Table className={classes.conversionTable}>
										<TableBody>
											{
												values && values.conversions.map((conversion, index) =>
													<TableRow key={index}>
														<TableCell className={classes.tableCell}>
															<TextField
																variant="standard"
																name={`conversion[${index}].value`}
																value={conversion.value}
																label="Valeur"
																type="number"
																InputProps={{
																	inputProps: { min: 0.01 }
																}}
																onChange={(e) => {handleConversionChange(e, index, values, setFieldValue)}}
															/>
														</TableCell>
														<TableCell className={classes.tableCell}>
															{ `Kg / ${unitSelectValues.find(el => el.value === conversion.type).label}` }
														</TableCell>
													</TableRow>
												)
											}
										</TableBody>
									</Table>
								</div>
							</div>

							<div className={classes.singleValueContainer}>
								<div className={classes.categoryTitle}>
									Unités de Barquettage
								</div>
								<div>
									<Table className={classes.conversionTable}>
										<TableBody>
													<TableRow>
													<TableCell className={classes.firstTableCell} style={{verticalAlign: "top", paddingTop: "11px"}}>
														Unité
													</TableCell>
													<TableCell className={classes.tableCell} style={{paddingTop: "7px"}}>
														<Select
																variant="standard"
																value={values.servingUnit}
																onChange={(e) => {
																	handleServingUnitChange(e, values, setFieldValue)
																}}
																className={classes.selectField}
														>
															{servingUnits.map((unit, index) => (
																	<MenuItem key={index} value={unit.value}>
																			{unit.label}
																	</MenuItem>
															))}
														</Select>
													</TableCell>
													</TableRow>
										</TableBody>
									</Table>
								</div>
							</div>





							<div className={classes.buttonsContainer}>
								<Button
									onClick={onClose}
								>
									Annuler
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="button"
									onClick={handleSubmit}
								>
									Valider
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

export default UnitsForm