import * as Yup from "yup"
import _cloneDeep from "lodash/cloneDeep"
import { PSE_REGEX_HOURS } from "./productionStepExecution"

Yup.addMethod(Yup.array, "uniquePriority", function (errorMessage) {
    return this.test("test-unique-priority", errorMessage, function (value) {
        const clones = _cloneDeep(value)
        let isValid = true
        clones.map((currentClone, index) => {
            for (const i in value) {
                const currentValue = value[i]
                if (index !== Number(i) && currentValue.priority === currentClone.priority) {
                    isValid = false
                    break
                }
            }
        })

        return isValid
    })
})

Yup.addMethod(Yup.array, "uniqueSite", function (errorMessage) {
    return this.test("test-unique-site", errorMessage, function (value) {
        const clones = _cloneDeep(value)
        let isValid = true
        clones.map((currentClone, index) => {
            for (const i in value) {
                const currentValue = value[i]
                if (index !== Number(i) && currentValue.name === currentClone.name) {
                    isValid = false
                    break
                }
            }
        })

        return isValid
    })
})


// see KFC-2127 endStepWeighing can't be false if it's the last step from section
Yup.addMethod(Yup.bool, "testEndStepWeighing", function (errorMessage, fromReusableProductionSteps = false) {
    return this.test("test-step-end-weighing", errorMessage, function (value, context) {
        const fromRecipe = !fromReusableProductionSteps
        const from = context.from
        const path = context.path
        const regexIndex = /productionSteps\[(\d+)\]/
        const indexMatch = path.match(regexIndex)
        let isValid = true
        if (indexMatch) {
            const index = parseInt(indexMatch[1], 10)
            let parent = null
            if (fromRecipe) {
                parent = from[2] // parent = section
            }
            if (fromReusableProductionSteps) {
                parent = from[1] // parent = reusableStep
            }
            const parentLength = (parent?.value?.productionSteps || []).length || 0
            const isLast = index === parentLength - 1
            if (value === false && isLast) {
                isValid = false
            }
        }
        return isValid
    })
})

Yup.addMethod(Yup.string, "validPEPSETime", function (errorMessage) {
    return this.test("test-valid-time", errorMessage, async function validateValue(value) {
        return value === "__:__" || !value || PSE_REGEX_HOURS.test(value)
    })
})

export const RecipeSchema = Yup.object().shape({
    sections: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Obligatoire"),
            steps: Yup.array().of(
                Yup.object().shape({
                    description: Yup.string().required("Obligatoire"),
                    ingredients: Yup.array().of(
                        Yup.object().shape({
                            supplierItem: Yup.mixed().notOneOf(
                                [null, undefined], "Obligatoire"
                            ),
                            grossWeight: Yup.number().required("Obligatoire"),
                            cookingMode: Yup.mixed().notOneOf(
                                [null, undefined], "Obligatoire"
                            )
                        })
                    )
                })
            )
        })
    )
})

export const RecipeProductionStepsSchema = Yup.object().shape({
    sections: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Obligatoire"),
            productionSteps: Yup.array().of(
                Yup.object().shape({
                    reusable: Yup.boolean(),
                    grossWeight: Yup.number().when("reusable", {
                        is: true,
                        then: (schema) => schema.optional(),
                    }),
                    cost: Yup.number().when("reusable", {
                        is: true,
                        then: (schema) => schema.optional(),
                    }),
                    netWeight: Yup.number().when("reusable", {
                        is: true,
                        then: (schema) => schema.optional(),
                    }),
                    step: Yup.object().when("reusable", {
                        is: false,
                        then: (schema) => schema.shape({
                            name: Yup.string().required("Nom Obligatoire"),
                            description: Yup.string().required("Description Obligatoire"),
                            machineSetting: Yup.string(),
                            stepDurationUnit: Yup.string(),
                            stepDuration: Yup.number(),
                            kitchenArea: Yup.object().nullable().required("Atelier obligatoire"),
                            transformation: Yup.string().required("Transformation Obligatoire"),
                            endStepWeighing: Yup.boolean().testEndStepWeighing("Pesée obligatoire pour cette étape"),
                            // stepComponents: Yup.array().of(
                            //   Yup.object().shape({
                            //     supplierItem: Yup.mixed().notOneOf(
                            //       [null, undefined],
                            //       "Obligatoire"
                            //     ),
                            //     grossWeight: Yup.number().required("Obligatoire"),
                            //     cookingMode: Yup.mixed().notOneOf(
                            //       [null, undefined],
                            //       "Obligatoire"
                            //     )
                            //   })
                            // )
                        }),
                        otherwise: (schema) => schema.shape({
                            name: Yup.string(),
                            description: Yup.string(),
                            machineSetting: Yup.string(),
                            stepDurationUnit: Yup.string(),
                            stepDuration: Yup.number(),
                            kitchenArea: Yup.object().nullable(),
                            transformation: Yup.string(),
                        })
                    }),
                })
            )
        })
    )
})

export const ReusableProductionStepSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    productionSteps: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Nom obligatoire"),
            description: Yup.string().required("Description obligatoire"),
            machineSetting: Yup.string(),
            kitchenArea: Yup.object().nullable().required("Atelier obligatoire"),
            transformation: Yup.string().required("Transformation Obligatoire"),
            stepDurationUnit: Yup.string(),
            stepDuration: Yup.number(),
            endStepWeighing: Yup.boolean().testEndStepWeighing("Pesée obligatoire pour cette étape", true),
        })
    )
})

export const emptySchema = Yup.object().shape({})

export function getRecipeDetailsSchema(recipeStatus, preparation) {
    let schema = {}

    if (recipeStatus && preparation) {
        if (recipeStatus === "3" || recipeStatus === "5") {
            const tvaSchema = (recipeStatus === "5") ?
                {
                    tva: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    )
                } :
                {}

            schema = {
                defaultValues: Yup.object().shape({
                    dlc: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    instructions: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    ),
                    ...tvaSchema
                }),
                packaging: Yup.array().of(
                    Yup.object().shape({
                        brand: Yup.string().required("Obligatoire"),
                        value: Yup.mixed().notOneOf(
                            [null, undefined], "Obligatoire"
                        )
                    })),
                gesters: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                ),
                difficulty: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                preparation: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                )
            }
        }
        else if (recipeStatus === "6") {
            schema = {
                defaultValues: Yup.object().shape({
                    appImage: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    ),
                    price: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    dlc: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    description: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    ),
                    instructions: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    ),
                    tva: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    ),
                }),
                packaging: Yup.array().of(
                    Yup.object().shape({
                        brand: Yup.string().required("Obligatoire"),
                        value: Yup.mixed().notOneOf(
                            [null, undefined], "Obligatoire"
                        )
                    })),
                gesters: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                ),
                difficulty: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                preparation: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                specialInstruction: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                )
            }
        }

        if (recipeStatus !== "1" && preparation !== "1") {
            schema.heatingInstructions = Yup.array().of(
                Yup.object().shape({
                    brand: Yup.string().required("Obligatoire"),
                    value: Yup.object().shape({
                        microwave: Yup.object().shape({
                            power: Yup.mixed().notOneOf(
                                [null, undefined, "NaN"], "Obligatoire"
                            ),
                            duration: Yup.mixed().notOneOf(
                                [null, undefined, "NaN"], "Obligatoire"
                            ),
                            instructions: Yup.mixed().notOneOf(
                                [null, undefined], "Obligatoire"
                            )
                        }),
                        oven: Yup.object().shape({
                            power: Yup.mixed().notOneOf(
                                [null, undefined, "NaN"], "Obligatoire"
                            ),
                            duration: Yup.mixed().notOneOf(
                                [null, undefined, "NaN"], "Obligatoire"
                            ),
                            instructions: Yup.mixed().notOneOf(
                                [null, undefined], "Obligatoire"
                            )
                        }),
                        pan: Yup.object().shape({
                            power: Yup.number(),
                            duration: Yup.number(),
                            instructions: Yup.string()
                        })
                    })
                })
            )
        }
    }

    return Yup.object().shape(schema)
}


export function getSubcontractorProductDetailsSchema(status, preparation) {
    let schema = {}

    if (status && preparation) {
        if (status === "3" || status === "5") {
            schema = {
                dlc: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                packaging: Yup.mixed().notOneOf(
                    [null, undefined, ""], "Obligatoire"
                ),
                preparation: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                )
            }
        }
        else if (status === "6") {
            schema = {
                appImage: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                ),
                price: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                dlc: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                description: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                ),
                packaging: Yup.mixed().notOneOf(
                    [null, undefined, ""], "Obligatoire"
                ),
                preparation: Yup.mixed().notOneOf(
                    [null, undefined, "NaN"], "Obligatoire"
                ),
                specialInstruction: Yup.mixed().notOneOf(
                    [null, undefined], "Obligatoire"
                )
            }
        }

        if (status !== "1" && preparation !== "1") {
            schema.heatingInstructions = Yup.object().shape({
                microwave: Yup.object().shape({
                    power: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    duration: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    instructions: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    )
                }),
                oven: Yup.object().shape({
                    power: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    duration: Yup.mixed().notOneOf(
                        [null, undefined, "NaN"], "Obligatoire"
                    ),
                    instructions: Yup.mixed().notOneOf(
                        [null, undefined], "Obligatoire"
                    )
                }),
                pan: Yup.object().shape({
                    power: Yup.number(),
                    duration: Yup.number(),
                    instructions: Yup.string()
                })
            })
        }
    }

    return Yup.object().shape(schema)
}

export function getRecipeResumeSchema(recipeStatus, noCommercialNameIngredients) {
    let schema = {
        ean: Yup.string().matches(/^\d{13}$/, { message: "L'ean doit contenir 13 chiffres.", excludeEmptyString: true })
    }

    if (noCommercialNameIngredients.value && noCommercialNameIngredients.value.length && Number(recipeStatus) > 2) {
        const ingredients = noCommercialNameIngredients.value.map(el => el.get("name").toLowerCase()).join(", ")

        schema.status = Yup.mixed().notOneOf(
            ["3", "4", "5", "6"],
            noCommercialNameIngredients.value.length === 1 ?
                `Attention, l’ingrédient ${ingredients} n’a pas de nom commercial, veuillez compléter avant de changer de statut` :
                `Attention, les ingrédients : ${ingredients} n’ont pas de nom commercial, veuillez compléter avant de changer de statut`
        )
    }

    if (recipeStatus === "3" || recipeStatus === "5" || recipeStatus === "6") {
        schema.maxProd = Yup.number().required("obligatoire")
    }
    if (recipeStatus === "6") {
        schema.commercialName = Yup.string().required("Obligatoire")
        schema.ean = Yup
            .string()
            .matches(/^\d{13}$/, { message: "L'ean doit contenir 13 chiffres.", excludeEmptyString: true })
            .required("Obligatoire")

    }

    return Yup.object().shape(schema)
}

export function getSubcontractorProductResumeSchema(status) {
    let schema = {
        name: Yup.object().required("Un nom technique est nécéssaire"),
        brand: Yup.string().required("Une marque est nécessaire"),
        type: Yup.string().required("Un type est nécessaire"),
        ean: Yup.string().matches(/^\d{13}$/, { message: "L'ean doit contenir 13 chiffres.", excludeEmptyString: true }),
    }

    if (status === "5" || status === "6") {
        schema.tva = Yup.string().required("Obligatoire")
    }
    if (status === "6") {
        schema.commercialName = Yup.string().required("Obligatoire")
    }

    return Yup.object().shape(schema)
}

export const ProductResumeCreateSchema = Yup.object({
    name: Yup.string().required("Un nom technique est nécéssaire"),
    brands: Yup.array().required("Une marque est nécessaire"),
    type: Yup.string().required("Un type est nécessaire")
}
)
export const ProductResumeCreateSchema2 = Yup.object({
    name: Yup.object().required("Un nom technique est nécéssaire"),
    brand: Yup.string().required("Une marque est nécessaire"),
    type: Yup.string().required("Un type est nécessaire")
}
)

export const DayCardCreateSchema = Yup.object({
    hub: Yup.string().required("Un hub est nécéssaire"),
    types: Yup.array().required("Un type est nécessaire")
}
)

export const RecipeDownloadProductionSchema = Yup.object({
    volume: Yup.number().required("Une quantité est requise").min(0, "Ne peut pas être inférieur à 0"),
    brand: Yup.string().required("Une marque est nécessaire"),
    productionDate: Yup.date().required("Une date de production est requise"),
    packagingDate: Yup.date().required("Une date de barquettage est requise")
})

export const ChefSchema = Yup.object({
    firstName: Yup.string().required("Un prénom est nécessaire"),
    lastName: Yup.string().required("Un nom est nécessaire")
})

export const DistributionCenterSchema = Yup.object({
    name: Yup.string().required("Un nom est nécessaire"),
    dispatchSites: Yup.array().of(Yup.object().shape({
        priority: Yup.number()
            .integer("Chiffre invalide")
            .max(100, "Maximun 100")
            .min(1, "Minimum 1")
    })).uniqueSite("Le site doit être unique").uniquePriority("La priorité doit être unique")
})

export const DistributionCenterFridgeSchema = Yup.object({
    name: Yup.string().required("Un nom est nécessaire"),
    fridgeInfos: Yup.object().shape({
        fridgeMaxRefAmount: Yup.number()
            .required("Champs requis")
            .max(20, "Maximum 20")
            .integer("Format invalide")
            .positive("Format invalide"),
        fridgeMaxProductsPerRefAmount: Yup.number()
            .required("Champs requis")
            .max(50, "Maximum 50")
            .integer("Format invalide")
            .positive("Format invalide"),
        fridgeDeliveries: Yup.array().of(Yup.object().shape({
            percent: Yup.number()
                .integer("Format invalide")
                .max(100, "Maximun 100%")
                .min(0, "Minimum 0%")
        }))
    }),
    dispatchSites: Yup.array().of(
        Yup.object().shape({
            priority: Yup.number()
                .required("Champs requis")
                .integer("Chiffre invalide")
                .max(100, "Maximun 100")
                .min(1, "Minimum 1")
        })
    ).uniqueSite("Le site doit être unique").uniquePriority("La priorité doit être unique")
})

export const SiteSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    deliveryAddress:
        Yup.object().shape({
            line1: Yup.string().required("Obligatoire"),
            line2: Yup.string(),
            codeAccess: Yup.string(),
            zipCode: Yup.string().required("obligatoire"),
            city: Yup.string().required("obligatoire"),
            country: Yup.string().required("obligatoire")
        }),
    invoiceAddress:
        Yup.object().shape({
            line1: Yup.string().required("Obligatoire"),
            line2: Yup.string(),
            codeAccess: Yup.string(),
            zipCode: Yup.string().required("obligatoire"),
            city: Yup.string().required("obligatoire"),
            country: Yup.string().required("obligatoire")
        })
})

export const StockZoneSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    spot: Yup.string().required("Obligatoire"),
    temperature: Yup.number().required("Obligatoire"),
    storeNumber: Yup.number().required("Obligatoire")
})

export const SupplierItemHeaderSchema = Yup.object().shape({
    family: Yup.string().required("Entrez un nom commercial valide"),
    group: Yup.string().required("Entrez un nom commercial valide"),
    type: Yup.string()
})

export const SupplierItemResumeSchema = Yup.object().shape({
    supplierArticleID: Yup.number().required("Obligatoire"),
    pricePerKg: Yup.number().required("Obligatoire"),
    weightPerKg: Yup.number().required("Obligatoire"),
    orderUnitWeight: Yup.string().required("Obligatoire")
})

export const SupplierItemCookingModesSchema = Yup.object().shape({
    raw: Yup.number().required("Obligatoire"),
    steam: Yup.number().required("Obligatoire"),
    fried: Yup.number().required("Obligatoire"),
    oven: Yup.number().required("Obligatoire"),
    long: Yup.number().required("Obligatoire"),
    special1: Yup.number().required("Obligatoire"),
    special2: Yup.number().required("Obligatoire"),
    special3: Yup.number().required("Obligatoire")
})

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const SupplierCreationSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    corporateName: Yup.string().required("Obligatoire"),
    contactName: Yup.string().required("Obligatoire"),
    phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide").required("Obligatoire"),
    siret: Yup.number().required("Obligatoire"),
    intraTVA: Yup.number().required("Obligatoire"),
    commercialEmails: Yup.array().transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
            return value
        }
        return originalValue ? originalValue.split(/[\s,]+/) : []
    }).of(Yup.string().email(({ value }) => `${value} n'est pas un email valide`)).required("Obligatoire"),
    defaultDeliverySite: Yup.string().required("Obligatoire")
})

export const SupplierResumeEditSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    countryCode: Yup.string().required("Obligatoire"),
    continentCode: Yup.string().required("Obligatoire"),
    sanitaryApprovalNumber: Yup.string().required("Obligatoire"),
    siret: Yup.number().required("Obligatoire"),
    intraTVA: Yup.number().required("Obligatoire"),
    referenceStartDate: Yup.date().required("Une date de production est requise"),
    referenceEndDate: Yup.date().required("Une date de production est requise"),
    clientReference: Yup.string().required("Obligatoire"),
    defaultDeliverySite: Yup.string().required("Obligatoire"),
    canExportEtiquettable: Yup.bool().required("Obligatoire"),
    corporateName: Yup.string().required("Obligatoire")
})

export const SupplierContactEditSchema = Yup.object().shape({
    country: Yup.string().required("Obligatoire"),
    city: Yup.string().required("Obligatoire"),
    zipCode: Yup.string().required("Obligatoire"),
    address: Yup.string().required("Obligatoire"),
    phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide").required("Obligatoire"),
    fax: Yup.string().matches(phoneRegExp, "Fax invalide").required("Obligatoire"),
    commercialEmails: Yup.array().transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
            return value
        }
        return originalValue ? originalValue.split(/[\s,]+/) : []
    }).of(Yup.string().email(({ value }) => `${value} n'est pas un email valide`)).required("Obligatoire"),
    orderEmails: Yup.array().transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
            return value
        }
        return originalValue ? originalValue.split(/[\s,]+/) : []
    }).of(Yup.string().email(({ value }) => `${value} n'est pas un email valide`)).required("Obligatoire"),
    contactName: Yup.string().required("Obligatoire")
})

export const supplierCommandSchema = Yup.object().shape({
    minimumCommand: Yup.number().required("Obligatoire"),
    sendOrderMode: Yup.object().shape({
        manually: Yup.bool().required("Obligatoire"),
        email: Yup.bool().required("Obligatoire"),
        fax: Yup.bool().required("Obligatoire"),
    }),
    openDays: Yup.string().required("Obligatoire"),
    deliveryDays: Yup.array().required("Obligatoire"),
    forbidMinimumCommand: Yup.bool().required("Obligatoire"),
    showLinkInEmail: Yup.bool().required("Obligatoire"),
    shippingFees: Yup.number().required("Obligatoire"),
    allowShippingFeesCancel: Yup.bool().required("Obligatoire"),
    allowEarlyDelivery: Yup.bool().required("Obligatoire"),
    deliveryLeadTime: Yup.string().required("Obligatoire"),
    orderHourLimit: Yup.string().required("Obligatoire")
})

export const supplierInvoiceSchema = Yup.object().shape({
    invoiceEqualToBL: Yup.bool().required("Obligatoire"),
    autoComplain: Yup.bool().required("Obligatoire"),
    autoSendEmailComplain: Yup.bool().required("Obligatoire"),
    autoSendEmailCredit: Yup.bool().required("Obligatoire")
})

export const SupplierItemCreationSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire"),
    type: Yup.string().required("Type obligatoire"),
    productType: Yup.string().when("type", {
        is: (type) => type === "SALABLE_PRODUCT" || type === "PACKAGING_CONSUMABLE",
        then: Yup.string().required("Sous-type obligatoire"),
        otherwise: Yup.string()
    })
})

export const transformationModesSchema = Yup.object().shape({
    name: Yup.string().required("Mode de transformation obligatoire"),
    transformationType: Yup.string().required("Type de transformation obligatoire")
})

export const SelectAmbientsSchema = Yup.object().shape({
    productTypes: Yup.array().required("Types d'Ambiants obligatoire")
})

export const replaceSupplierItemsSchema = Yup.object().shape({
    originalSupplierItem: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        data: Yup.object(),
    }).nullable().required("Champ obligatoire"),
    replacedSupplierItem: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        data: Yup.object(),
    }).nullable().required("Champ obligatoire"),
})

export const salableSupplierItemResumeEditionSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire"),
    productType: Yup.string().required("Sous-type obligatoire")
})

export const supplierItemResumeEditionSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire")
})

export const orderDeliveryNoteSchema = Yup.object().shape({
    number: Yup.string().required("Obligatoire"),
    images: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Obligatoire"),
            publicId: Yup.mixed().notOneOf(
                [null, undefined], "Obligatoire"
            )
        })
    )
})

export const deliveryNoteInformationSchema = Yup.object().shape({
    shippingFees: Yup.number().required("Obligatoire"),
})

export const deliveryNoteArticleSchema = Yup.object().shape({
    quantity: Yup.number().min(0, "Entrer une valeur positive").required("Obligatoire"),
    deliveryNoteUnitPrice: Yup.number().min(0, "Entrer une valeur positive").required("Obligatoire"),
})

export const billingControlArticleSchema = Yup.object().shape({
    quantity: Yup.number().min(0, "Entrer une valeur positive").required("Obligatoire"),
    billUnitPrice: Yup.number().min(0, "Entrer une valeur positive").required("Obligatoire"),
})

export const machineModelSchema = Yup.object().shape({
    cleanTime: Yup
        .number()
        .min(0, "Entrer une valeur positive")
        .required("Obligatoire"),
    capacity: Yup
        .number()
        .min(0, "Entrer une valeur positive")
        .required("Obligatoire"),
    availabilityRate: Yup
        .number()
        .min(0, "Entrer une valeur positive")
        .max(100, "Entrer une valeur entre 0 et 100")
        .required("Obligatoire")
})

export const getSupplierItemTransformationModeSchema = () => {
    const defaultFieldSchema = {
        transformationRate: Yup
            .number()
            .min(0, "Entrer une valeur positive")
            .required("Obligatoire"),
        transformationMode: Yup
            .mixed()
            .required("Obligatoire")
    }

    return Yup.object().shape(defaultFieldSchema)
}


export const loginSchema = Yup.object().shape({
    username: Yup.string().email(({ value }) => `${value} n'est pas un email valide`).required("Obligatoire"),
    password: Yup
        .string()
        .min(10, "Votre mot de passe doit faire au moins 10 caractères.")
        .required("Obligatoire"),
})

export const createAccountSchema = Yup.object().shape({
    username: Yup
        .string()
        .email(({ value }) => `${value} n'est pas un email valide`)
        .matches(/@foodcheri.com$/, "Uniquement les adresses @foodcheri sont acceptées")
        .required("Obligatoire"),
    password: Yup
        .string()
        .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*\W)/, "Mot de passe invalide")
        .min(10, "Votre mot de passe doit faire au moins 10 caractères.")
        .required("Obligatoire"),
})

export const RequestPasswordChangeSchema = Yup.object().shape({
    email: Yup.string().email(({ value }) => `${value} n'est pas un email valide`).required("Obligatoire"),
})

export const ChangePasswordSchema = Yup.object().shape({
    password: Yup
        .string()
        .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*\W)/, "Mot de passe invalide")
        .min(10, "Votre mot de passe doit faire au moins 10 caractères.")
        .required("Obligatoire"),
    confirmPassword: Yup
        .string()
        .required("Obligatoire")
        .oneOf([Yup.ref("password"), null], "Mot de passe pas identique"),
})

export const orderReceptionsFilterSchema = Yup.object().shape({
    receptionPeriodStart: Yup.date(),
    receptionPeriodEnd: Yup
        .date()
        .min(
            Yup.ref("receptionPeriodStart"),
            "La date de fin doit être supérieure à la date de début"
        ),
})

export const kitchenAreaSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire")
})

export const kitchenAreaMachinesSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire"),
    machineModel: Yup.string().required("Obligatoire")
})

export const ReportOrderRDSchema = Yup.object().shape({
    products: Yup.array()
        .of(
            Yup.object().shape({
                quantity: Yup.number()
                    .typeError("Quantité obligatoire")
                    .required("Quantité obligatoire"),
                product: Yup.object().required("Produit obligatoire")
            })
        )
        .min(1, "Veuillez ajouter un produit")
})

export const productTypesSchema = Yup.object().shape({
    label: Yup.string().required("Nom obligatoire"),
    adj: Yup.string().required("Obligatoire"),
    kfcName: Yup.string().required("Nom obligatoire"),
    fcSzName: Yup.string().required("Nom obligatoire"),
    managementMode: Yup.string().required("Obligatoire")
})

export const packagingSchema = Yup.object().shape({
    price: Yup.number().required("Le prix est obligatoire").positive("Le prix doit être un nombre positif"),
    weight: Yup.number().positive("Le poid doit être un nombre positif"),
    quantity: Yup.number().positive("La quantité doit être un nombre positif"),
    reference: Yup.string().required("La référence est obligatoire"),
    name: Yup.string().required("Le nom est obligatoire"),
    type: Yup.string().required("Le type est obligatoire"),
    nature: Yup.string().required("La nature du packaging est obligatoire"),
    height: Yup.number().required("La hauteur du packaging est obligatoire"),
    deepth: Yup.number().required("La profondeur du packaging est obligatoire"),
    width: Yup.number().required("La largeur du packaging est obligatoire"),
    brands: Yup.array().of(Yup.string()).required("La marque est obligatoire")
})

export const packagingResumeSchema = packagingSchema.pick(["name", "reference", "type", "nature", "categories", "brands"])
export const packagingLogisticsSchema = packagingSchema.pick(["height", "deepth", "width"])

export const ingredientResumeSchema = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    family: Yup.string().required("Obligatoire"),
    group: Yup.string().required("Obligatoire"),
    season: Yup.array()
        .of(Yup.string().required("Obligatoire"))
        .required("Obligatoire"),
})

export const packagingLineSchema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire"),
})

export const allergenSchema = Yup.object().shape({
    allergens: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Obligatoire")
        })
    )
})

export const sourcingSchema = Yup.object().shape({
    firstOriginAverage: Yup.number()
        .required("Obligatoire")
        .min(0, "La moyenne doit être au moins 0")
        .max(100, "La moyenne doit être au plus 100")
        .typeError("La moyenne doit être un nombre"),
})

export const ProductionStepExecutionsSchema = Yup.object({
    productionDate: Yup.number().required("La date de production est requise"),
})

export const PSEToDoneSchema = Yup.object().shape({
    netWeight: Yup.number().required("Net weight required"),
})

export const DateSchema = Yup.object({
    date: Yup.number().required("La date de production est requise"),
})

export const packagingExecutionsValidationSchema = Yup.object().shape({
    packagingExecutions: Yup.array().of(
        Yup.object().shape({
            theoreticalStartTime: Yup.string().validPEPSETime("L'heure doit être au format hh:mm"),
            theoreticalEndTime: Yup.string().validPEPSETime("L'heure doit être au format hh:mm"),
            packagingLine: Yup.string()
        })
    )
})

export const packagingMaterialSchema = Yup.object().shape({
    kgCO2PerMaterialTon: Yup.number().required("kg CO2e / tonne de matière est obligatoire").positive("Le kg CO2e / tonne de matière est obligatoire doit être un nombre positif"),
    name: Yup.string().required("Le nom est obligatoire"),
    shortName: Yup.string().required("Le nom court est obligatoire"),
})

export const newPackagingSchema = Yup.object().shape({
    name: Yup.string().required("Le nom est obligatoire"),
})

export const packagingLifeCycleSchema = Yup.object().shape({
    manufacturingCountry: Yup.string().required("Le nom est obligatoire"),
    isReusable: Yup.boolean(),
    isRecyclableInFrance: Yup.boolean(),
})

export const packagingLifeCycleMaterialSchema = Yup.object().shape({
    packagingMaterial: Yup.string().required("La matière est obligatoire"),
    countryOfOrigin: Yup.string(),
    proportion: Yup.number().min(0, "Entrer une valeur positive").required("Obligatoire"),
})

export const parseTimestampForValidation = (_, timestamp) => {
    const timestampToDate = new Date(timestamp)
    return timestampToDate
}

export const secondaryDLCValidationSchema = Yup.object().shape({

    site: Yup.string().required("Obligatoire"),
    kitchenArea: Yup.string(),
    supplierItem: Yup.string().typeError("Veuillez sélectionner le nom du produit.").required("Veuillez sélectionner le nom du produit."),
    orderSupplierItem: Yup.string().required("OrderSupplierItem associé au lot obligatoire"),
    lot: Yup.string().typeError("Veuillez sélectionner le lot correspondant.").required("Veuillez sélectionner le lot correspondant."),
    productionSupply: Yup.string().typeError("Erreur").required("Erreur"),
    openingDate: Yup.date().transform(parseTimestampForValidation).required("Obligatoire"),
    secondaryDLC: Yup.date().transform(parseTimestampForValidation).required("Obligatoire"),
    dlc: Yup.date().transform(parseTimestampForValidation).required("Obligatoire"),
    quantity: Yup.number().typeError("Veuillez indiquer le poids restant en kg.").min(1, "Veuillez indiquer le poids restant en kg.").required("Veuillez indiquer le poids restant en kg."),
    lotNumber: Yup.string().typeError("Obligatoire").required("Obligatoire"),
    name: Yup.string().typeError("Nom du lot obligatoire").required("Nom du lot obligatoire"),
})

export const CommentSchema = Yup.object().shape({
    username: Yup.string(),
    comment: Yup.string(),
    images: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            publicId: Yup.string().required(),
        })
    )
}).test(
    "comment-or-images",
    function (value) {
        const { comment, images } = value
        if (!comment && (!images || images.length === 0)) {
            return this.createError({ path: "comment", message: "Veuillez saisir un commentaire" })
        }
        return true
    }
)

export const ProductionStepExecutionsLotsSelectionSchema = Yup.object().shape({
    lots: Yup.array(),
    secondaryDLCs: Yup.array()
}).test(
    "lots-or-secondaryDLCs",
    function (value) {
        const { lots, secondaryDLCs } = value
        if (!lots.length && !secondaryDLCs.length) {
            return this.createError({ path: "lots", message: "Veuillez sélectionner les lots utilisés." })
        }
    }
)

export const ProductionStepExecutionWeightValidationSchema = Yup.object().shape({
    netWeight: Yup.number().typeError("Veuillez saisir le poids final de votre préparation.").required("Veuillez saisir le poids final de votre préparation.")
})

export const replacePackagingsSchema = Yup.object().shape({
    originalPackaging: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        data: Yup.object(),
    }).nullable().required("Champ obligatoire"),
    replacedPackaging: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        data: Yup.object(),
    }).nullable().required("Champ obligatoire"),
})