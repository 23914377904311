import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { loadFilteredLotsForHistory } from "../../parseManager/lot/parseLotManager"
import DataGrid from "../../components/DataGrid/DataGrid"
import dayjs from "dayjs"
import { debounce } from "lodash"
import { getSuppliers } from "../../parseManager/suppliers/suppliers/parseSupplierManager"
import { showLotEventsHistory } from "../../actions/LotInventory/LotInventory"
import _cloneDeep from "lodash/cloneDeep"
import {getDataGridFiltersStored} from "../../utils/dataUtils"

const createData = (lot) => {
  return {
    id: lot.objectId,
    name: lot.supplierItem?.name || "",
    supplier: lot.supplierItem?.supplierName || lot.supplierItem?.supplier?.name || "",
    lotNumber: lot.lotNumber,
    receptionDateUnique: lot.receptionDate ? dayjs(lot.receptionDate).format("DD/MM/YYYY") : "NO_DATE_FOUND",
    dlc: lot.dlc ? dayjs(lot.dlc).format("DD/MM/YYYY") : "NO_DATE_FOUND"
  }
}

const getColumnsHeaders = (suppliersOptions) => {
  return [
    {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      },
      width: 150
    },
    {
      key: "name",
      label: "Nom de l'article",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "supplier",
      label: "Fournisseur",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: suppliersOptions.map(supplier => ({ key: supplier.objectId, value: supplier.name, label: supplier.name }))
      },
    },
    {
      key: "receptionDateUnique",
      label: "Date de réception",
      dataType: "date",
      sortable: false,
      filter: {
        filterable: true,
        type: "date",
        strict: true
      }
    },
    {
      key: "lotNumber",
      label: "N° de lot",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      }
    },
    {
      key: "dlc",
      label: "DLC",
      dataType: "date",
      sortable: false,
      filter: {
        filterable: true,
        type: "date",
        strict: true
      }
    },
  ]
}

const additionalFilters = [
  {
    key: "receptionDate",
    label: "Date de réception",
    formLabel: "Indiquer la période de réception que vous souhaitez filtrer",
    dataType: "dateRange",
    sortable: false,
    undeletable: true,
    filter: {
      filterable: true,
      type: "dateRange"
    },
  }
]

const fixedAdditionalFilters = {
  receptionDate: {
      start: dayjs().subtract(1, "week").startOf("day").toDate(),
      end: dayjs().startOf("day").toDate(),
      isRange: true
  }
}

const LotsHistory = () => {
  const dispatch = useDispatch()

  const defaultAdditionalFilters = _cloneDeep(fixedAdditionalFilters)
  const [lots, setLots] = useState([])
  const [filters, setFilters] = useState(defaultAdditionalFilters)
  const [suppliersOptions, setSuppliersOptions] = useState([])
  const [data, setData] = useState([])

  const initDefaultFilters = () => {
    let defaultFilters = {}
    const useStoredDataGridFilters = localStorage.getItem("useStoredDataGridFilters")
    if (useStoredDataGridFilters && useStoredDataGridFilters === "1") {
      defaultFilters = getDataGridFiltersStored([], defaultAdditionalFilters, "lotsHistory")
    }

    return defaultFilters || {}
  }

  const defaultFilters = initDefaultFilters()

  const _loadLots = debounce(async (filters) => {
    const lots = await loadFilteredLotsForHistory(filters)
    setLots(lots)
  }, 300)

  const _loadSupplierOptions = async () => {
    const suppliers = await getSuppliers()
    const sortedSuppliers = suppliers.sort((a, b) => a.name.localeCompare(b.name))
    setSuppliersOptions(sortedSuppliers)
  }

  useEffect(() => {
    _loadSupplierOptions()
  }, [])

  useEffect(() => {
    _loadLots(filters)
  }, [filters])

  useEffect(() => {
    setData(lots.map(createData))
  }, [lots])

  const handleFiltersChange = async (filters) => setFilters(filters)

  const handleRowClick = (lot) => {
    dispatch(showLotEventsHistory(lot.id))
  }

  return (
    <DataGrid
      title="Historique des lots"
      data={data}
      columns={getColumnsHeaders(suppliersOptions)}
      menus={[]}
      withFilters
      asyncFilterFunction={handleFiltersChange}
      onRowClick={handleRowClick}
      defaultPostResetFilter={true}
      additionalFilters={additionalFilters}
      defaultAdditionalFilters={defaultAdditionalFilters}
      initialValues={defaultFilters}
      fixedAdditionalFilters={fixedAdditionalFilters}
    />
  )
}

export default LotsHistory