import Parse from "parse"
import { push } from "react-router-redux"

import { loadMachineTypes } from "../Machines/Machines"
import { loadKitchenAreaBySiteId } from "../Site/sites"
import { loadSupplierItemsToReplace } from "../Supplier/supplierItems"
import { loadTransformationModes } from "../TransformationModes/TransformationModes"
import { actionWithLoader, getReplacingPath, onEnter } from "../Utils/utils"
import { createOrUpdateProductionStep, parseProductionStepsToSave } from "../../utils/recipes"
import { getReusableProductionStep, getReusableProductionSteps } from "../../parseManager/recipe/parseReusableProductionStepsManager"
import { getServerUrl } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import { openAppMessageSnackBar } from "../Utils/app"
import { getActiveSupplierItems } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

const ReusableProductionStep = Parse.Object.extend("ReusableProductionStep")

export const loadProductionStepsRelatedData = () => {
  return actionWithLoader(async (dispatch) => {
    dispatch(loadMachineTypes(null, []))
    dispatch(loadKitchenAreaBySiteId())
    dispatch(loadSupplierItemsToReplace(true))
    dispatch(loadTransformationModes(true))
  })
}

export const createOrUpdateReusableProductionStep = (values) => {
  return actionWithLoader(async (dispatch) => {
      let reusableProductionStep = await getReusableProductionStep(values.id, false)
      if (!reusableProductionStep) {
          reusableProductionStep = new ReusableProductionStep()
      }

      reusableProductionStep.set("name", values.name)
      const formattedProductionStepsValues = parseProductionStepsToSave(values.productionSteps)
      
      const [productionSteps] = await createOrUpdateProductionStep(formattedProductionStepsValues)
      reusableProductionStep.set("productionSteps", productionSteps)

      await reusableProductionStep.save()
      dispatch(showReusableProductionSteps())
  })
}

export const loadReusableProductionSteps = () => {
  return actionWithLoader(async (dispatch) => {
    const reusableProductionSteps = await getReusableProductionSteps()

    const supplierItems = await getActiveSupplierItems()

    dispatch({
      type: "REUSABLE_STEPS_LOADED",
      reusableProductionSteps,
      supplierItems
    })
  })
}

export const loadReusableProductionStepEdition = (params) => {
  return actionWithLoader(async (dispatch) => {
      const reusableProductionStep = await getReusableProductionStep(params.id)
      
      dispatch({
          type: "REUSABLE_STEP_LOADED",
          reusableProductionStep,
          isEdition: true
      })

      dispatch(loadTransformationModes(true))
      // load data for form
      dispatch(loadProductionStepsRelatedData())
  })
}

/**
 * duplicate reusable production step and its production steps to create a new one
 * the redirect to the new reusable production step view
 * @param {*} id 
 * @returns 
 */
export const duplicateReusableProductionStep = (id) => {
  return actionWithLoader(async (dispatch) => {
		const reusableProductionStep = await new Parse.Query(ReusableProductionStep)
			.equalTo("objectId", id)
			.first()

    const copiedReusableProductionStep = reusableProductionStep.clone()
    
    const productionSteps = reusableProductionStep.get("productionSteps") || []
    const newProductionSteps = []

    // copy each production step
    for (const [index, productionStep] of productionSteps.entries()) {
      const copiedProductionStep = productionStep.clone()

      // add (duplicata) to the last production step name
      if (index === productionSteps.length - 1) {
        const newName = productionStep.get("name") + " (duplicata)"
        copiedProductionStep.set("name", newName)
      }

      const saveCopiedProductionStep = await copiedProductionStep.save()
      newProductionSteps.push(saveCopiedProductionStep)
    }
    
    copiedReusableProductionStep.set("name", reusableProductionStep.get("name") + " (DUPLICATA)")
    copiedReusableProductionStep.set("productionSteps", newProductionSteps)

    // Create a pointer to the original reusable production step
    const originalStepPointer = new Parse.Object("ReusableProductionStep")
    originalStepPointer.id = id
    copiedReusableProductionStep.set("reusableProductionStepSource",originalStepPointer)

    const newReusableProductionStep = await copiedReusableProductionStep.save()

    dispatch(showReusableProductionStepView(newReusableProductionStep.id))
  })
}

export const exportReusableStepUsage = (id) => {
  return actionWithLoader(async (dispatch) => {
    try {
        const url = `${getServerUrl()}/steps/${id}/extractRecipes`
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" }, "blob")
        const csvUrl = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement("a")
        const fileName = `Export-${id}-FT.csv`

        link.href = csvUrl
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch(openAppMessageSnackBar(`La liste des produits internes contentant l'étape réutilisable ${id} a été exportée`))
      } catch (error) {
        dispatch(openAppMessageSnackBar(error.message, "error"))
      }
  })
}

export const loadReusableProductionStepView = (params) => {
  return actionWithLoader(async (dispatch) => {
    const reusableProductionStep = await getReusableProductionStep(params.id)
    dispatch({
      type: "REUSABLE_STEP_LOADED",
      reusableProductionStep,
      isEdition: false
    })

    // load data for form
    dispatch(loadProductionStepsRelatedData())
  })
}

export const resetProductionStepView = () => {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "REUSABLE_STEP_LOADED",
            reusableProductionStep: null,
            isNew: true
        })

        // load data for form
        dispatch(loadProductionStepsRelatedData())
    })
}


export const onEnterReusableProductionStepCreation = (store) => {
  return onEnter({
      store,
      actionThunk: resetProductionStepView,
      getReplacingPath: getReplacingPath({ needUser: true })
  })
}

export const onEnterReusableProductionSteps = (store) => {
  return onEnter({
      store,
      actionThunk: loadReusableProductionSteps
  })
}

export function onEnterReusableProductionStepEdition(store) {
  return onEnter({
      store,
      actionThunk: loadReusableProductionStepEdition,
      getReplacingPath: getReplacingPath({ needUser: true })
  })
}
export function onEnterReusableProductionStepView(store) {
  return onEnter({
    store,
    actionThunk: loadReusableProductionStepView,
    getReplacingPath: getReplacingPath({ needUser: true })
  })
}

export const showReusableProductionStepCreation = () => {
  return push("/settings/resources/reusableSteps/add")
}

export const showReusableProductionSteps = () => {
  return push("/settings/resources/reusableSteps")
}

export const showReusableProductionStepEdition = (id) => {
  return push("/settings/resources/reusableSteps/edit/" + id)
}

export const showReusableProductionStepView = (id) => {
  return push("/settings/resources/reusableSteps/view/" + id)
}