import React, { useEffect, useRef, useState } from "react"

import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import { Formik } from "formik"
import { cloneDeep } from "lodash"
import { goBack } from "react-router-redux"
import { useDispatch, useSelector } from "react-redux"

import { getMachineTypesSelector } from "../../../../../reducers/Machines/machines"
import { getKitchenAreasSelector } from "../../../../../reducers/Site/sites"
import {
  computeReusableProductionStepsNumbers,
  getReusableFormInitialValues
} from "../../../../../actions/Utils/utils"
import ProductionStepsTable from "../../../../../components/Recipe/ProductionSteps/ProductionStepsTable"
import ProductionStepsTableHead from "../../../../../components/Recipe/ProductionSteps/ProductionStepsTableHead"
import { ReusableProductionStepSchema } from "../../../../../utils/yupValidators"
import {
  createOrUpdateReusableProductionStep,
  duplicateReusableProductionStep,
  exportReusableStepUsage,
  showReusableProductionStepEdition,
  showReusableProductionSteps
} from "../../../../../actions/Steps/reusableProductionSteps."
import {
  getIsEditionReusableProductionStepSelector, getIsNewReusableProductionStepSelector,
  getReusableProductionStepSelector
} from "../../../../../reducers/Steps/steps"
import ReusableStepFormRow from "./ReusableStepFormRow"
import GenericHeaderReturnButton from "../../../../../components/GenericHeaderReturnButton"
import GenericTopBarHeader from "../../../../../components/GenericTopBarHeader"
import { getTransformationModesSelector } from "../../../../../reducers/TransformationModes/transformationModes"
import { REUSABLE_STEPS_TABLE_HEADS } from "../../../../../utils/recipes"
import MoreMenu from "../../../../../components/MoreMenu"

const ReusableStepForm = ({ location }) => {
  const machineTypes = useSelector(getMachineTypesSelector)
  const kitchenAreas = useSelector(getKitchenAreasSelector)
  const reusableStep = useSelector(getReusableProductionStepSelector)
  const isEdition = useSelector(getIsEditionReusableProductionStepSelector)
  const isNew = useSelector(getIsNewReusableProductionStepSelector)
  const transformationModes = useSelector(getTransformationModesSelector)

  const dispatch = useDispatch()

  const formRef = useRef()
  const [initialValues, setInitialValues] = useState(null)
  const [stepNumbers, setStepNumbers] = useState({})

  /*
   * this will not be changed,
   * unlike the initial values that changed every input of the form change
   */
  const [defaultValues, setDefaultValues] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [fieldFocused, setFieldFocused] = useState(false)
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false)

  useEffect(() => {
    const initialFormValues = getReusableFormInitialValues(cloneDeep(reusableStep), isNew)
    const stepNumbersStorage = computeReusableProductionStepsNumbers(initialFormValues)

    setStepNumbers(stepNumbersStorage)
    setInitialValues(initialFormValues)
    setDefaultValues(cloneDeep(initialFormValues))
  }, [reusableStep])

  const onRowBlur = () => {
    if (fieldFocused) return
    setHoveredRow(null)
  }

  const toggleDuplicateModal = () => setOpenDuplicateModal(!openDuplicateModal)
  
  /**
 * ComponentIndex is used for productionSteps > stepComponents
 * PriorComponentIndex is used for productionSteps > stepComponents > priorSteps
 */
  const onRowHover = (
    component,
    index,
    parentIndex = null,
    componentIndex = null,
    priorComponentIndex = null,
    stepComponentIndexesList = []

  ) => {
    if (fieldFocused) return
    setHoveredRow({
      component,
      index,
      parentIndex,
      componentIndex,
      priorComponentIndex,
      stepComponentIndexesList
    })
  }

  const onFieldFocus = () => setFieldFocused(true)

  const onFieldBlur = (event, setFieldTouched) => {
    setFieldFocused(false)
    setFieldTouched(event.target.name)
  }

  const onKeyUp = (event, setFieldTouched) => {
    if (!setFieldTouched) return
    setFieldTouched(event.target.name)
  }

  const handleSubmit = () => {
    if (!formRef.current) return
    formRef.current.handleSubmit()
  }

  const handleCancel = () => {
    setInitialValues(defaultValues)
    dispatch(showReusableProductionSteps())
  }

  const handleEdit = () => {
    setInitialValues(defaultValues)
    dispatch(showReusableProductionStepEdition(reusableStep.objectId))
  }

  const handleDuplicate = async () => {
    await dispatch(duplicateReusableProductionStep(reusableStep.objectId))
    toggleDuplicateModal()
  }

  const _onSubmit = (values) => {
    dispatch(createOrUpdateReusableProductionStep(values))
  }

  const handleExportUsage = () => {
    dispatch(exportReusableStepUsage(reusableStep.objectId))
  }

  const onBack = () => dispatch(goBack())

  const renderRightAction = () => {
    return (
      isEdition || isNew ?
        (
          <Stack direction="row" spacing={5}>
            <Button onClick={handleCancel}>Annuler</Button>
            <Button onClick={handleSubmit} variant="contained">Enregistrer</Button>
          </Stack>
        )
        :
        <Stack direction="row" spacing={5}>
          <MoreMenu
            menus={[
              {
                label: "Modifier cette étape réutilisable",
                onClick: handleEdit
              },
              {
                label: "Dupliquer cette étape réutilisable",
                onClick: toggleDuplicateModal
              },
              {
                label: "Exporter les utilisations de l'étape réutilisable",
                onClick: handleExportUsage
              }
            ]}
          />
        </Stack>
    )
  }

  const isViewOrEdit = location.pathname.includes("edit") || location.pathname.includes("view")

  return (
    <Box className="flexColumn" sx={{ width: "100%", height: "100%" }}>
      <Box style={{ height: 64, width: "100%" }}>
        <GenericTopBarHeader
          leftAction={!isEdition && !isNew ? (<GenericHeaderReturnButton handleClick={onBack} />) : null}
          rightAction={renderRightAction()}
        />
      </Box>
      {/* table */}
      <ProductionStepsTable>
        {/* table head */}
        <ProductionStepsTableHead
          headers={REUSABLE_STEPS_TABLE_HEADS}
          hasAnyHoveredRow={!!hoveredRow}
        />
        <Box className="flexColumn">
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={ReusableProductionStepSchema}
            onSubmit={_onSubmit}
            validateOnChange={false}
            enableReinitialize
          >
            {({
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              handleChange,
              setValues,
            }) => {
              return (
                <ReusableStepFormRow
                  onRowHover={onRowHover}
                  onRowBlur={onRowBlur}
                  hoveredRow={hoveredRow}
                  onFieldFocus={onFieldFocus}
                  onFieldBlur={(e) => onFieldBlur(e, setFieldTouched)}
                  onKeyUp={(e) => onKeyUp(e, setFieldTouched)}
                  errors={errors}
                  machineTypes={machineTypes}
                  kitchenAreas={kitchenAreas}
                  transformationModes={transformationModes}
                  setFieldValue={setFieldValue}
                  stepValues={values}
                  setValues={setValues}
                  isEdition={isEdition}
                  fromRecipe={false}
                  handleChange={handleChange}
                  withMarginBottom={isViewOrEdit}
                  stepNumbers={stepNumbers}
                />
              )
            }}
          </Formik>
        </Box>
      </ProductionStepsTable>
      
      {/* duplicate confirmation dialog */}
      <Dialog
        onClose={toggleDuplicateModal}
        aria-labelledby="duplicate-reusable-step-dialog"
        open={openDuplicateModal}
      >
        <DialogContent>
          Voulez-vous dupliquer cette étape réutilisable ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleDuplicateModal}
          >
            Annuler
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDuplicate}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ReusableStepForm
