import { escapeRegExp, every } from "lodash"
import { productType } from "./dispatchUtils"
import { roundNumber } from "../utils"

export const cookingModesIntitialValues = (cookingModes) => ({
    raw: cookingModes[0].transformRate,
    steam: cookingModes[1].transformRate,
    fried: cookingModes[2].transformRate,
    oven: cookingModes[3].transformRate,
    long: cookingModes[4].transformRate,
    special1: cookingModes[5].transformRate,
    special2: cookingModes[6].transformRate,
    special3: cookingModes[7].transformRate
})

export const headerInitialValues = (supplierItem) => ({
    commercialName: supplierItem && supplierItem.commercialName ? supplierItem.commercialName : null,
    family: supplierItem && supplierItem.commercialName && supplierItem.commercialName.group && supplierItem.commercialName.group.family && supplierItem.commercialName.group.family.name ? supplierItem.commercialName.group.family.name : "",
    group: supplierItem && supplierItem.commercialName && supplierItem.commercialName.group && supplierItem.commercialName.group.name ? supplierItem.commercialName.group.name : "",
    useOnRecipe: supplierItem && supplierItem.useOnRecipe ? supplierItem.useOnRecipe : false,
    allergens: supplierItem && supplierItem.commercialName && supplierItem.commercialName.allergens && supplierItem.commercialName.allergens.length > 0 ? supplierItem.commercialName.allergens : []
})

export const newsInitialValues = (supplierItem) => ({
    news: supplierItem.news || ""
})

export const unitsInitialValues = (supplierItem) => {
    if (
        supplierItem.units
        && supplierItem.units.billing
        && supplierItem.units.order
        && supplierItem.units.stock
        && supplierItem.units.conversions
    ) {
        return {
            order: supplierItem.units.order,
            billing: supplierItem.units.billing,
            stock: supplierItem.units.stock,
            conversions: supplierItem.units.conversions,
            servingUnit: supplierItem.units.servingUnit || "weight"
        }
    }
    else {
        return {
            order: {
                unity: {
                    name: "",
                    quantity: 0,
                    unity: 0
                },
                weight: null,
                price: null
            },
            billing: {
                unity: {
                    name: "",
                    quantity: 1,
                    unity: 0
                },
                weight: supplierItem.weightPerKg,
                price: (supplierItem.units && supplierItem.units.billing) ? supplierItem.units.billing.price : supplierItem.pricePerKg
            },
            stock: {
                unity: {
                    name: "",
                    quantity: 0,
                    unity: 0
                },
                weight: null,
                price: null
            },
            conversions: [],
            servingUnit: "weight"
        }
    }
}

export const supplierItemProductTypes = productType.slice(1)

export const unitSelectValues = [
    { label: "Kg", value: 0 },
    { label: "L", value: 1 },
    { label: "Pièce", value: 2 },
    { label: "Palette", value: 3 }
]

export const unitsCategories = [
    { label: "Unité de commande", value: "order" },
    { label: "Unité de facturation", value: "billing" },
    { label: "Unité de stock", value: "stock" }
]

export const conversionLabels = [
    { label: "Kg / Palette", type: 3 },
    { label: "Kg / Pièce", type: 2 },
    { label: "Kg / L", type: 1 }
]

export const unitValues = [
    { label: "Unité", value: "unity" },
    { label: "Poids", value: "weight" },
    { label: "Prix", value: "price" }
]

export const servingUnits = [
    { label: "À la pièce", value: "piece" },
    { label: "En Kg", value: "weight" }
]

export const supplierItemTypes = {
    SALABLE_PRODUCT: { key: "SALABLE_PRODUCT", value: "SALABLE_PRODUCT", label: "Produit vendable" },
    RAW_MATERIAL: { key: "RAW_MATERIAL", value: "RAW_MATERIAL", label: "Matière première" },
    PACKAGING_CONSUMABLE: { key: "PACKAGING_CONSUMABLE", value: "PACKAGING_CONSUMABLE", label: "Packaging - Consommable" },
}

export const supplierItemTypesArray = [
    { key: "SALABLE_PRODUCT", value: "SALABLE_PRODUCT", label: "Produit vendable" },
    { key: "RAW_MATERIAL", value: "RAW_MATERIAL", label: "Matière première" },
    { key: "PACKAGING_CONSUMABLE", value: "PACKAGING_CONSUMABLE", label: "Packaging - Consommable" },
]

export const productTypeToAdd = [
    { key: "PACKAGING", value: "PACKAGING", label: "Packaging" },
    { key: "SUB_PACKAGING", value: "SUB_PACKAGING", label: "Sous-Packaging" },
    { key: "LABEL", value: "LABEL", label: "Etiquettes" },
    { key: "HYGIENE", value: "HYGIENE", label: "Hygiène" },
    { key: "WAREHOUSE_CONSUMABLE", value: "WAREHOUSE_CONSUMABLE", label: "Consommable entrepôt" },
    { key: "KITCHEN_EQUIPMENT", value: "KITCHEN_EQUIPMENT", label: "Matériel de cuisine" },
    { key: "SECURITY", value: "SECURITY", label: "Sécurité" },
]

export const getSupplierItemTypeLabel = (value) => {
    if (value) {
        const currentSupplierItemType = supplierItemTypesArray.find(supplierItemType => supplierItemType.key === value)
        return currentSupplierItemType?.label
    }
}

export const creationInitialValues = (supplier) => ({
    name: "",
    supplier: supplier ? supplier.name : "",
    type: "",
    productType: "",
})

export const editionInitialValues = (supplierItem) => {
    let pricePerKg = 0

    if (supplierItem.pricePerKg) {
        pricePerKg = supplierItem.pricePerKg
    } else if (supplierItem.units && supplierItem.units.billing && supplierItem.units.billing.price && supplierItem.units.billing.weight) {
        pricePerKg = parseFloat((supplierItem.units.billing.price / supplierItem.units.billing.weight).toFixed(3))
    }

    return {
        billingUnitPrice: supplierItem.units ? supplierItem.units.billing.price : 0,
        name: supplierItem.name,
        ean: supplierItem.ean || "",
        supplierArticleName: supplierItem.supplierArticleName || "",
        supplierArticleId: supplierItem.supplierArticleId || "",
        availabilityAtSupplier: supplierItem.availabilityAtSupplier,
        pricePerKg,
        productType: supplierItem.productType || ""
    }
}

export const filterSupplierItemsBySupplier = (supplierItems, suppliers) => {
    const filteredSupplierItems = []

    suppliers.forEach(supplier => {
        let items = supplierItems.filter(supplierItem => supplierItem.supplier && supplierItem.supplier.objectId === supplier)
        if (items.length > 0) {
            filteredSupplierItems.push(...items)
        }
    })

    return filteredSupplierItems
}

export const filterSupplierItemsByType = (supplierItems, types) => {
    const filteredSupplierItems = []

    types.forEach(type => {
        let items = supplierItems.filter(supplierItem => supplierItem.type && supplierItem.type === type)
        if (items.length > 0) {
            filteredSupplierItems.push(...items)
        }
    })

    return filteredSupplierItems
}

/**
 * can change isActive as true
 * if any of supplier item units, pricePerKg, site and stockZone are null and different 0, 
 * @param {*} supplierItem 
 * @returns 
 */
export const canMarkSupplierItemAsActive = (supplierItem) => {
    // can not active if any of these fields are not defined
    if (!supplierItem.units || !supplierItem.units.stock || !supplierItem.units.order || !supplierItem.units.billing || (supplierItem.type === "RAW_MATERIAL" && !supplierItem.commercialName)) return false

    const { units } = supplierItem

    const unitValues = [
        units.order.price,
        units.order.weight,
        units.billing.price,
        units.billing.weight,
        units.stock.price,
        units.stock.weight,
        supplierItem.pricePerKg
    ]

    // if every supplierItem numeric value is different 0 and not null
    const hasUnitValue = every(
        unitValues,
        (value) => value !== 0 && value !== null
    )

    const hasSite = !!supplierItem.site
    const hasStockZone = !!supplierItem.stockZone

    const canActivate = every(
        [hasUnitValue, hasSite, hasStockZone],
        (value) => value === true
    )

    return canActivate
}

export const calculatePricePerKgByBillings = (billingUnitPrice, billingUnitWeight, fixed = true) => {
    const pricePerKg = billingUnitPrice / billingUnitWeight

    if (fixed) {
        return parseFloat(pricePerKg.toFixed(3))
    }

    return pricePerKg
}

export const TRANSFORMATION_TYPES = [
    {
        value: "UNPACKING",
        type: "UNPACKING",
        label: "Déconditionnement",
    },
    {
        value: "WASHING",
        type: "WASHING",
        label: "Lavage",
    },
    {
        value: "CUTTING",
        type: "CUTTING",
        label: "Découpe",
    },
    {
        value: "MIXED",
        type: "MIXED",
        label: "Mélange",
    },
    {
        value: "COOKING",
        type: "COOKING",
        label: "Cuisson",
    },
    {
        value: "COOLING",
        type: "COOLING",
        label: "Refroidissement",
    },
    {
        value:"RACK_LOADING",
        type :"RACK_LOADING",
        label :"Plaquage"
    },
    {
        value:"DIVISION",
        type :"DIVISION",
        label :"Séparation"
    }
]

export const getTransformationTypeLabel = (value) => {
    const currentTransformationType = TRANSFORMATION_TYPES.find(transformationType => transformationType.value === value)
    return currentTransformationType?.label
}

export const filterSupplierItemTransformationModes = (transformationModes, search) => {
    const regex = new RegExp(escapeRegExp(search), "ig")

    const filteredTransformationModes = transformationModes.filter(transformationMode => transformationMode.transformationMode.name.match(regex) !== null)
    return filteredTransformationModes
}

export const isTransformationTypePreparation = (transformationMode) => {
    return ["UNPACKING", "WASHING", "CUTTING"].includes(transformationMode.transformationType)
}

export const getLabelIndex = (index) => {
    return index === 0 ? "1er" : (index + 1) + "ème"
}

export const supplierItemsBaseCsvHeader = {
    id: "Id",
    name: "Nom",
    supplier: "Fournisseur ",
    type: "Type",
    supplierArticleName: "Nom de pour le fournisseur",
    supplierArticleId: "Référence fournisseur",
    ean: "EAN ",
    isActive: "Actif ",
    pricePerKg: "Prix au kg",
    orderUnit: "Unité de commande",
    unitsOrderWeight: "Poids de l'unité de commande",
    unitsOrderPrice: "Prix de l'unité de commande",
    billingUnit: "Unité de facturation",
    unitsBillingWeight: "Poids de l'unité de facturation",
    unitsBillingPrice: "Prix de l'unité de facturation",
    stockUnit: "Unité de stock",
    unitsStockWeight: "Poids de l'unité de stock",
    unitsStockPrice: "Prix de l'unité de stock",
    site: "Site par défaut",
    stockZone: "Zone de stockage par défaut ",
    associatedId: "ID ingrédient associé"
}

export const formatSupplierItemType = (type) => {
    if (type === "SALABLE_PRODUCT") {
        return "Produit vendable"
    } else if (type === "RAW_MATERIAL") {
        return "Matière première"
    } else if (type === "PACKAGING_CONSUMABLE") {
        return "Packaging - Consommable"
    }
}

export const formatSupplierItemsAutocompleteOption = (supplierItem) => {
    return {
        label: supplierItem.objectId + " - " + supplierItem.name.toLowerCase(),
        value: supplierItem.objectId,
        data: supplierItem,
    }
}

export const formatSupplierItemsAutocompleteOptions = (supplierItems = []) => {
    return supplierItems.map(formatSupplierItemsAutocompleteOption)
}

export const getUnitValue = (units, unitCategory, unitRow) => {
    if (units && units[unitCategory] && units[unitCategory][unitRow]) {
        switch (unitRow) {
            case "unity":
                return `${units[unitCategory][unitRow].name} ${units[unitCategory][unitRow].quantity} ${unitSelectValues.find(el => el.value === units[unitCategory][unitRow].unity).label}`
            case "weight":
                return `${roundNumber(units[unitCategory][unitRow], 5)} kg`
            case "price":
                return roundNumber(units[unitCategory][unitRow], 3)
            default:
                return ""
        }
    }
    else {
        return ""
    }
}

export const getSupplierItemUnitPrice = (units) => {
    if (units?.conversions && units.conversions[0]?.type === 2) {
        return roundNumber((units.billing.price * units.conversions[0].value) / units.billing.weight, 3)
    } else {
        return 0
    }
}

export const computeSupplierItemWeightByQuantity = (units) => units.stock.weight / units.stock.unity.quantity
