import React, { useEffect } from "react"
import { TableBody, TableRow, TableHead, Stack } from "@mui/material"
import { packagingNature } from "../../../utils/packaging"
import { PETable, PETableCell_V2, PETableHeaderCell_V2 } from "../StyledPackagingExecutionPreviewComponents"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const styles = {
  borders: {
    left: { borderLeft: "1px solid #E6E6E6" },
    right: { borderRight: "1px solid #E6E6E6" },
    bottomLeft: {
      borderLeft: "1px solid #E6E6E6",
      borderBottom: "1px solid #E6E6E6",
      borderRadius: "0 0 0 8px",
    },
    bottomRight: {
      borderRight: "1px solid #E6E6E6",
      borderBottom: "1px solid #E6E6E6",
      borderRadius: "0 0 8px 0",
      fontWeight: "500",
    },
  }
}

const PackagingExecutionPackagings = ({ packagings = [], expectedPackagingNumber = 0, packagingForecastNumber = 0, setFieldValue }) => {

	const _updatePackagingForecastNumber = (packagings, packagingForecastNumber) => {
		if (packagings.length === 1) {
			setFieldValue("packagings[0].forecastNumber", packagingForecastNumber)
			return
		}
		let currentPackagingForecastNumber = packagingForecastNumber
		for (const packaging of packagings) {
			const index = packagings.indexOf(packaging)
			const isLast = index === packagings.length - 1
			const packagingTheoreticalNumber = packaging.theoreticalNumber || 0
			const usedPackagingNumber = isLast ? currentPackagingForecastNumber : Math.min(packagingTheoreticalNumber, currentPackagingForecastNumber)
			setFieldValue(`packagings[${index}].forecastNumber`, usedPackagingNumber)
			currentPackagingForecastNumber -= usedPackagingNumber
			currentPackagingForecastNumber = Math.max(0, currentPackagingForecastNumber)
		}
	}

	useEffect(() => {
		_updatePackagingForecastNumber(packagings, packagingForecastNumber)
	}, [packagings, packagingForecastNumber])

  console.log("packagings", packagings)
	return (
    <Stack spacing={2} sx={{width:"100%"}}>
      <Stack spacing={1} direction={"row"}>
        <img src="/img/packagingExecutions/packagingNumber.svg" alt="Packaging Icon" />
        <Typography variant="h6" sx={{fontSize: "14px", lineHeight: "22px"}}>
          Nombre de barquettes
        </Typography>
      </Stack>
      <PETable>
        <TableHead>
          <TableRow>
            <PETableHeaderCell_V2>
              Estimation
            </PETableHeaderCell_V2>
              {packagings.map((currentPackaging, index) => (
                <React.Fragment key={index}>
                  <PETableHeaderCell_V2>
                    {packagingNature.find((p) => p.value === currentPackaging.type)?.label}
                  </PETableHeaderCell_V2>
                </React.Fragment>
              ))}
            <PETableHeaderCell_V2>
              Total
            </PETableHeaderCell_V2>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <PETableCell_V2 sx={ styles.borders.left }>
              Théorique
            </PETableCell_V2>
              {packagings.map((currentPackaging, index) => (
                <React.Fragment key={index}>
                  <PETableCell_V2>
                    {currentPackaging.theoreticalNumber || "-"}
                  </PETableCell_V2>
                </React.Fragment>
              ))}
            <PETableCell_V2 sx={styles.borders.right}>
              {expectedPackagingNumber || "-"}
            </PETableCell_V2>
          </TableRow>
          <TableRow>
            <PETableCell_V2 sx={styles.borders.bottomLeft}>
              Prévisionnelle
            </PETableCell_V2>
              {packagings.map((currentPackaging, index) => (
                <React.Fragment key={index}>
                  <PETableCell_V2>
                    {currentPackaging.forecastNumber || "-"}
                  </PETableCell_V2>
                </React.Fragment>
              ))}
            <PETableCell_V2
              sx={{ ...styles.borders.bottomRight, color:COLORS.PRIMARY_COLOR, }}
            >
              {packagingForecastNumber || "-"}
            </PETableCell_V2>
          </TableRow>
        </TableBody>
      </PETable>
    </Stack>
	)
}

export default PackagingExecutionPackagings
