import React, { useRef, useCallback } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import { generateStockUnitLabel } from "../../../utils/orderReception"

const sx = {
  title: {
    color: "#7C7C7C",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.37,
    padding: 0
  },
  label: {
    fontSize: 14,
    lineHeight: 1.57,
  },
  input: {
    "& .MuiFormLabel-root": {
      fontSize: 18,
      lineHeight: 1.57,
    },
    "& .MuiInputBase-root": {
      marginTop: 3
    }
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 555,
    padding: 32
  }
})

const OrderSupplierItemDialogForm = ({ open, onClose, onConfirm, orderSupplierItem }) => {
  const formikRef = useRef()

  const handleConfirm = () => {
    formikRef.current?.submitForm()
  }

  const handleCancel = () => onClose()

  const handleSubmit = useCallback(async (values) => {
    await onConfirm(values)
    onClose()
  }, [onConfirm, onClose])

  if (!orderSupplierItem) return

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <Stack spacing={5}>
        <DialogTitle id="form-dialog-title" sx={sx.title}>
          Modifier les informations de votre article.
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Stack spacing={4}>
            <Stack spacing={8 / 6}>
              <InputLabel sx={sx.label}>Nom de l'article</InputLabel>
              <Typography>{orderSupplierItem?.supplierItem?.name}</Typography>
            </Stack>
            <Formik
              initialValues={{
                quantity: orderSupplierItem?.quantities?.deliveryNote === 0 ? orderSupplierItem?.quantities?.deliveryNote : (orderSupplierItem?.quantities?.deliveryNote || orderSupplierItem?.quantities?.reception)
              }}
              onSubmit={handleSubmit}
              innerRef={formikRef}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ values, handleChange, handleBlur, errors }) => {
                return (
                  <Form>
                    <TextField
                      sx={sx.input}
                      variant="standard"
                      name="quantity"
                      type="number"
                      label="Quantité du bon de livraison"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.quantity}
                      value={values.quantity}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{generateStockUnitLabel(orderSupplierItem?.supplierItem)}</InputAdornment>,
                      }}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Enregistrer
          </Button>
        </DialogActions>
      </Stack>
    </StyledDialog>
  )
}

export default OrderSupplierItemDialogForm
