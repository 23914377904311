import React from "react"
import { Box, Button, Stack, styled, Typography } from "@mui/material"
import { useState } from "react"
import { COLORS } from "../../../utils"

const StyledButton = styled(Button)({
  padding: 0,
  color: COLORS.PRODUCTION_RECEPTION_COLOR,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1,
})

const StyledSubtitle = styled(Typography)({
  color: COLORS.BLACK_500,
})

const MergedPSERecipes = ({ recipes = [] }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  return (
    <Stack spacing={1}>
      <Typography>
        {recipes.length} recettes
      </Typography>
      {open
        ? (
          <Stack spacing={2}>
            <Stack spacing={1}>
              {recipes.map((recipe, index) => (
                <Stack
                  key={recipe.objectId + index}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ pl: 1 }}
                >
                  <Box sx={{ width: 4, height: 4, borderRadius: "50%", bgcolor: "#000"}} />
                  <Typography>{recipe.uniqueCode}</Typography>
                  <Typography>{recipe.recipeName}</Typography>
                  <StyledSubtitle variant="body1">({recipe.sectionName})</StyledSubtitle>
                </Stack>
              ))}
            </Stack>
          <div>
            <StyledButton onClick={toggleOpen}>
                Voir Moins
            </StyledButton>
          </div>
          </Stack>
        ) : (
          <div>
            <StyledButton onClick={toggleOpen}>
              Voir Plus
            </StyledButton>
          </div>
        )
      }
    </Stack>
  )
}

export default MergedPSERecipes