import Parse from "parse"
import { v4 as uuidv4 } from "uuid"

import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"
import { setValues } from "../../parseUtils"
import { packagingFields, parseLimitRequest } from "../../utils"
import { getPackaging, getPackagingSelector, getPackagingsSelector } from "../../reducers/Packaging/packagingList"
import { getSinglePackaging } from "../../parseManager/products/resources/packaging/parsePackagingManager"
import { SupplierItem } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { PackagingMaterials, loadPackagingMaterials } from "../packagingMaterials/packagingMaterials"
import { computeSupplierItemWeightByQuantity } from "../../utils/supplierItemUtils"

const Packaging = Parse.Object.extend("Packaging")

export const loadPackagingData = () => {
    return actionWithLoader(async (dispatch, getState) => {
        const packagingList = await new Parse.Query(Packaging)
            .notEqualTo("deleted", true)
            .descending("updatedAt")
            .include(["supplierItem"])
            .limit(parseLimitRequest)
            .find()

        const state = getState()
        const oldSelectedPackaging = getPackagingSelector(state)
        const newSelectedPackaging = oldSelectedPackaging ? getPackaging(state, oldSelectedPackaging.id) : null

        dispatch({
            type: "PACKAGING_LOADED",
            packagingList,
            selectedPackaging: newSelectedPackaging
        })
    })
}

export const loadSinglePackaging = (packagingId) => {
    return actionWithLoader(async (dispatch) => {
        const packaging = await getSinglePackaging(packagingId)

        dispatch({
            type: "SELECTED_PACKAGING_UPDATED",
            selectedPackaging: packaging
        })
        dispatch({
            type: "SINGLE_PACKAGING_LOADED",
            packaging: packaging.toJSON()
        })

        // load packaging materials for form select
        dispatch(loadPackagingMaterials())
    })
}

export const loadPackagings = () => {
    return actionWithLoader(async (dispatch, getState) => {
        const cachedPackagings = getPackagingsSelector(getState())
        // if we already have the packagings, we don't need to load them again (cache)
        if (cachedPackagings.length) return
    
        const packagings = await new Parse.Query(Packaging)
            .notEqualTo("deleted", true)
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "PACKAGINGS_LOADED",
            packagings: packagings.map(packaging => packaging.toJSON())
        })
    })
}

export function clearSelectedPackaging() {
    return dispatch => {
        dispatch({
            type: "PACKAGING_SELECTED",
            selectedPackaging: null

        })
        dispatch({
            type: "PACKAGING_FORM_INITIAL_VALUES_UPDATED",
            packagingFormInitialValues: null
        })
    }
}

export const createOrUpdatePackaging = (values, packagingParseObj, redirect = false) => {
    return actionWithLoader(async (dispatch) => {
        const keyToIgnore = [
            "height", "deepth", "width", "supplierItem"
        ]

        let packaging = packagingParseObj
        if (!packaging) {
            packaging = new Packaging()
        } else if (packagingParseObj.objectId) {
            packaging = await getSinglePackaging(packagingParseObj.objectId)
        }

        if (values.height) {
            packaging.set("height", parseFloat(values.height))
        }

        if (values.deepth) {
            packaging.set("deepth", parseFloat(values.deepth))
        }

        if (values.width) {
            packaging.set("width", parseFloat(values.width))
        }

        if (values.supplierItem) {
            // pointer
            const supplierItem = new SupplierItem()
            supplierItem.id = values.supplierItem.value // objectId
            packaging.set("supplierItem", supplierItem)

            const units = values.supplierItem.data.units
            const price = units.stock.price / units.stock.unity.quantity
            if (price) {
                packaging.set("price", price.toString())
            }
            const weight = computeSupplierItemWeightByQuantity(units)
            if (weight) {
                packaging.set("weight", weight.toString())
            }
        }

        Object.keys(values).forEach((key) => {
            const value = values[key]
            if (!keyToIgnore.includes(key) && (value !== undefined || value !== null)) {
                packaging.set(key, value)
            }
        })

        const savedPackaging = await packaging.save()

        dispatch({
            type: "SINGLE_PACKAGING_LOADED",
            packaging: savedPackaging.toJSON()
        })

        dispatch({
            type: "PACKAGING_SAVED"
        })

        if (!redirect) return
        dispatch(showPackaging(savedPackaging.id))
    })
}

export const addOrEditPackagingMaterialsToPackaging = (packagingId, values, materialId) => {
    return actionWithLoader(async (dispatch) => {
        const packaging = await getSinglePackaging(packagingId)

        if (!packaging) return

        const materials = packaging.get("materials") || []
        const packagingMaterial = new PackagingMaterials()
        packagingMaterial.id = values.packagingMaterial

        const newMaterial = {
            index: uuidv4(),
            packagingMaterial,
            proportion: parseFloat(values.proportion),
            countryOfOrigin: values.countryOfOrigin
        }


        if (materialId) {
            const newMaterials = materials.map(material => {
                if (material.index === materialId) {
                    return newMaterial
                }

                return material
            })

            packaging.set("materials", newMaterials)

        } else {
            packaging.set("materials", [...materials, newMaterial])
        }

        const savedPackaging = await packaging.save()

        dispatch({
            type: "SINGLE_PACKAGING_LOADED",
            packaging: savedPackaging.toJSON()
        })
    })
}

export const removePackagingMaterialsToPackaging = (packagingId, materialId) => {
    return actionWithLoader(async (dispatch) => {
        const packaging = await getSinglePackaging(packagingId)

        if (!packaging) return

        const materials = packaging.get("materials") || []

        const newMaterials = materials.filter(material => material.index !== materialId)
        packaging.set("materials", newMaterials)

        const savedPackaging = await packaging.save()

        dispatch({
            type: "SINGLE_PACKAGING_LOADED",
            packaging: savedPackaging.toJSON()
        })
    })
}

export function deletePackaging(packaging) {
    return actionWithLoader(async (dispatch) => {
        await packaging.destroy()

        dispatch({
            type: "PACKAGING_REMOVED"
        })
        dispatch(loadPackagingData())
    })
}

export function updateSelectedPackaging(values) {
    return async (dispatch, getState) => {
        const state = getState()
        const selectedPackaging = getPackagingSelector(state) || new Packaging()
        setValues(selectedPackaging, values, packagingFields)
        dispatch({
            type: "SELECTED_PACKAGING_UPDATED",
            selectedPackaging
        })
    }
}

export const replacePackagingInRecipes = (values) => {
    return actionWithLoader(async () => {
        await Parse.Cloud.run("replacePackagingInRecipes", {
            replacedPackagingId: values.replacedPackaging.value,
            originalPackagingId: values.originalPackaging.value
        })
    })
}

export function onEnterPackaging(store) {
    return onEnter({
        store,
        actionThunk: loadPackagingData,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSinglePackaging(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState
        if (params) {
            await store.dispatch(loadSinglePackaging(params.id))
        }
        callback()
    }
}

/** routes */
export const showPackaging = (id) => {
    return push(`/settings/resources/packaging/${id}`)
}
