import { getData } from "../../index"

const storage = JSON.parse(localStorage.getItem("searchValue"))

const defaultState = {
    suppliers: [],
    supplierItems: [],
    sites: [],
    selectedSupplier: null,
    supplierSnackBar: { open: false, type: "", message: "", duration: 0 },
    supplierFilters: {
        rowsPerPage: 10,
        page: 0,
        search: (storage && storage.supplierList) ? storage.supplierList : ""
    },
    total: 0
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "SUPPLIERS_LOADED":
            return {
                ...state,
                suppliers: action.suppliers.suppliers,
                total: action.suppliers.total,
                selectedSupplier: action.selectedSupplier,
                sites: action.sites
            }
        case "SUPPLIERS_LIST":
            return {
                ...state,
                suppliers: action.suppliers
            }
        case "SUPPLIER_SAVED":
            return {
                ...state,
                selectedSupplier: action.selectedSupplier
            }
        case "SUPPLIER_SELECTED":
            return {
                ...state,
                selectedSupplier: action.selectedSupplier
            }
        case "SELECTED_SUPPLIER_UPDATED":
            return {
                ...state,
                selectedSupplier: action.selectedSupplier,
                supplierItems: action.supplierItems
            }
        case "CLEAR_SELECTED_SUPPLIER":
            return {
                ...state,
                selectedSupplier: null,
                supplierItems: []
            }
        case "SUPPLIER_UPDATE_LIST_FILTERS":
            return {
                ...state,
                supplierFilters: {
                    ...state.supplierFilters,
                    ...action.change
                }
            }
        case "CLOSE_SUPPLIER_SNACKBAR":
            return {
                ...state,
                supplierSnackBar: action.supplierSnackBar
            }
        case "SUPPLIERS_ERROR_LOADED":
            return {
                ...state,
                suppliersErrors: action.suppliersErrors,
                selectedSupplier: action.selectedSupplier
            }
        case "SUPPLIER_IMAGE_SAVED":
            return {
                ...state,
                supplierSnackBar: action.supplierSnackBar
            }
        case "SUPPLIER_IMAGE_REMOVE":
            return {
                ...state,
                supplierSnackBar: action.supplierSnackBar
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//

export function getSuppliersSelector(state, errorIfNotFound = false) {
    return getData(state, "suppliers.suppliers", errorIfNotFound && "No supplier found")
}

export function getSitesSelector(state, errorIfNotFound = false) {
    return getData(state, "suppliers.sites", errorIfNotFound && "No sites found")
}

export function getSuppliersSnackbar(state, errorIfNotFound = false) {
    return getData(state, "suppliers.supplierSnackBar", errorIfNotFound && "No supplier snackbar found")
}

export const getSelectedSupplierSelector = (state, errorIfNotFound = false) => {
    return getData(state, "suppliers.selectedSupplier", errorIfNotFound && "No supplier snackbar found")
}
