import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from "react"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import GridThumbnail from "../../components/DataGrid/GridThumbnail"

import {
  createProductResume,
  showRecipeTab,
  showAdvancedSearch,
  loadAdditionalFiltersForRecipes,
} from "../../actions/Recipe/recipes"

import {
  getRecipeStatusToString,
  RecipeStatus
} from "../../utils/recipes"
import { getBrandBy, ACTIVE_KFC_BRANDS } from "../../utils"
import ProductResumeCreate from "../../components/Recipe/ProductResumeCreate"
import ReportOrderRDDialog from "./orderReportRD/ReportOrderRDDialog"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import { getProductTypeLabelByProduct } from "../../utils/productTypesUtils"
import DataGrid from "../../components/DataGrid/DataGrid"
import { getRecipes } from "../../reducers/Recipe/recipes"
import { getCommercialNamesOptions } from "../../reducers/Ingredients/Internal/CommercialName"
import { getSupplierItemsOptionsSelector } from "../../reducers/Suppliers/SupplierItems/supplierItems"
import { getProductTagsOptionsSelector } from "../../reducers/ProductsTags/ProductsTags"
import { refreshRecipesForAdditionalFilters } from "../../actions/Recipe/recipes"
import moment from "moment"
import { TableDateFormat } from "../../components/table-utils/utils"
import BooleanIcons from "../../components/BooleanIcons"

const useStyles = makeStyles({
  brandLogoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    cursor: "pointer"
  },
  fab: {
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
  },
})

const mapOptions = (option) => ({
  key: option.key,
  value: `${option.key} - ${option.label}`,
  label: `${option.key} - ${option.label}`,
  text: `${option.label}`
})

const mapTagOptions = (option) => ({
  key: option.key,
  value: option.label,
  label: option.label,
  text: option.label
})

const rawAdditionalFilters = [
  {
    key: "supplierItems",
    label: "Articles fournisseurs",
    dataType: "text",
    sortable: false,
    filter: {
      withSearch: true,
      filterable: true,
      type: "select",
      options: [],
      initialValue: ""
    },
  },
  {
    key: "commercialNames",
    label: "Ingrédient",
    dataType: "text",
    sortable: false,
    filter: {
      withSearch: true,
      filterable: true,
      type: "select",
      options: [],
      initialValue: ""
    }
  },
  {
    key: "internalTags",
    label: "Tag",
    dataType: "text",
    sortable: false,
    strict: true,
    filter: {
      withSearch: true,
      multiple: true,
      strict: true,
      filterable: true,
      type: "select",
      options: [],
      initialValue: []
    }
  }
]


const Recipes = () => {
  const recipes = useSelector(getRecipes)
  const productTypeOptions = useSelector(getProductTypeOptionsSelector)
  const commercialNamesOptions = useSelector(getCommercialNamesOptions)
  const supplierItemsOptions = useSelector(getSupplierItemsOptionsSelector)
  const productTagsOptions = useSelector(getProductTagsOptionsSelector)

  const [data, setData] = useState([])
  const [isCreating, setIsCreating] = useState(false)
  const [openOrderReportRDDialog, setOpenOrderReportRDDialog] = useState(false)
  const [additionalFilters, setAdditionalFilters] = useState(rawAdditionalFilters)

  const additionalFiltersFieldsAddedToRecipes = useRef(false)

  const columns = [
    {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      },
      width: 120
    },
    {
      key: "uniqueCode",
      label: "Code unique",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text"
      },
      width: 100
    },
    {
      key: "name",
      label: "Nom technique",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "commercialName",
      label: "Nom commercial",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "brand",
      label: "Marque",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: ACTIVE_KFC_BRANDS.map((brand) => ({
          key: brand.name,
          value: brand.name,
          label: brand.label
        }))
      },
      render: (brands) => (
        <div className={classes.brandLogoContainer}>
          {brands && brands.split(",").map((brand, index) => {
            const completeBrand = getBrandBy("name", brand)
            return (
              <GridThumbnail
                key={index}
                src={completeBrand.image}
                alt={`${completeBrand.shortname}_logo`}
              />
            )
          })}
        </div>
      ),
      width: 220
    },
    {
      key: "type",
      label: "Type",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: productTypeOptions?.map((productType) => ({
          key: productType.value,
          value: productType.label,
          label: productType.label
        }))
      },
      width: 100
    },
    {
      key: "status",
      label: "Statut",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: RecipeStatus.map((status) => ({
          key: status.value,
          value: status.label,
          label: status.label
        }))
      },
      width: 100
    },
    {
      key: "state",
      label: "Etat",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: [
          { key: "active", value: "active", label: "Actif" },
          { key: "inactive", value: "inactive", label: "Inactif" },
        ]
      },
      render: (state) => <BooleanIcons checked={state === "active"} />,
      width: 100
    },
    {
      key: "updatedAt",
      label: "Date de modification",
      dataType: "date",
      type: "date",
      sortable: true,
      filter: {
        filterable: false,
        type: "date"
      },
      width: 130
    }
  ]

  const dispatch = useDispatch()
  const classes = useStyles()

  const formatRecipeSupplierItems = (ingredients) => {
    if (!ingredients || !ingredients.length) return []
    return ingredients.map((ingredient) => {
      if (!ingredient.supplierItem) return ""
      return `${ingredient.supplierItem.objectId} - ${ingredient.supplierItem.name}`
    })
  }

  const formatRecipeInternalTags = (internalTags) => {
    if (!internalTags || !internalTags.length) return []
    return internalTags.map((internalTag) => {
      return internalTag.name
    })
  }

  const formatRecipeCommercialNames = (ingredients) => {
    if (!ingredients || !ingredients.length) return []
    return ingredients.map((ingredient) => {
      if (!ingredient.supplierItem || !ingredient.supplierItem.commercialName) return ""
      return `${ingredient.supplierItem.commercialName.objectId} - ${ingredient.supplierItem.commercialName.name || ""}`
    })
  }

  const createData = (recipe) => {
    const serializedRecipe = {
      id: recipe.objectId,
      uniqueCode: recipe.uniqueCode,
      name: recipe.name,
      commercialName: recipe.commercialName || "",
      brand: recipe.brands?.join(","),
      type: getProductTypeLabelByProduct(recipe, productTypeOptions),
      status: getRecipeStatusToString(parseInt(recipe.status)),
      state: recipe.isActive === true ? "active" : "inactive",
      commercialNames: formatRecipeCommercialNames(recipe.ingredients),
      supplierItems: formatRecipeSupplierItems(recipe.ingredients),
      internalTags: formatRecipeInternalTags(recipe.internalTag),
      updatedAt: moment(recipe.updatedAt).format(TableDateFormat),
    }

    return serializedRecipe
  }

  const createDataObject = useCallback(() => {
    setData(recipes.map(createData))
  }, [recipes])

  useEffect(() => {
    createDataObject()
  }, [recipes])

  useEffect(() => {
    const updatedFilters = [...additionalFilters]
    for (const filter of updatedFilters) {
      let options = []
      if (filter.key === "supplierItems") {
        options = supplierItemsOptions?.map(mapOptions)
      }
      if (filter.key === "commercialNames") {
        options = commercialNamesOptions?.map(mapOptions)
      }
      if (filter.key === "internalTags") {
        options = productTagsOptions?.map(mapTagOptions)
      }
      filter.filter.options = options
    }
    setAdditionalFilters(updatedFilters)
  }, [commercialNamesOptions, supplierItemsOptions, productTagsOptions])


  const onOpenAdditionalFilters = () => {
    if (!additionalFiltersFieldsAddedToRecipes.current) {
      dispatch(refreshRecipesForAdditionalFilters())
      dispatch(loadAdditionalFiltersForRecipes())
      additionalFiltersFieldsAddedToRecipes.current = true
    }
  }

  const _showRecipe = (recipe) => {
    dispatch(showRecipeTab("general", recipe.id))
  }

  const _toggleCreate = () => {
    setIsCreating(!isCreating)
  }

  const _createProductResume = useCallback(values => dispatch(createProductResume(values)), [])
  const _showAdvancedSearch = useCallback(() => dispatch(showAdvancedSearch()), [])

  const toggleOrderReportRDDialog = () => setOpenOrderReportRDDialog(!openOrderReportRDDialog)

  const moreMenus = [
    {
      onClick: _showAdvancedSearch,
      label: "Accéder à la recherche avancée"
    },
    {
      onClick: toggleOrderReportRDDialog,
      label: "Générer un rapport de commande R&D"
    },
  ]

  return (
    <>
      <DataGrid
        title="Produits internes"
        columns={columns}
        data={data}
        withFilters
        rowLabel="produits internes"
        menus={moreMenus}
        additionalFilters={additionalFilters}
        onRowClick={_showRecipe}
        additionalFiltersPreCallback={onOpenAdditionalFilters}
      />
      <ReportOrderRDDialog open={openOrderReportRDDialog} onClose={toggleOrderReportRDDialog} />
      <ProductResumeCreate
        onClose={_toggleCreate}
        isCreating={isCreating}
        onSaveProductResume={_createProductResume}
      />
      <div className={classes.fab}>
        <Fab color="primary" onClick={_toggleCreate}>
          <Add />
        </Fab>
      </div>
    </>
  )
}

export default Recipes
