import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { COLORS, roundNumber } from "../../../utils"

export const getStepComponentName = (stepComponent, substituteSupplierItems = []) => {
	if (stepComponent.priorSteps) {
		return stepComponent.priorSteps.name
	}

	if (stepComponent.supplierItem) {
		const replacementSupplierItem = substituteSupplierItems.find(substitute => substitute.originalSupplierItem.objectId === stepComponent.supplierItem.objectId)?.replacementSupplierItem
		return replacementSupplierItem ? replacementSupplierItem.name : stepComponent.supplierItem.name
	}
	
	return ""
}

const sx = {
	emptyTableCell: {
		width: "8px",
		padding: 0
	},
	emptyTableHeadCell: {
		borderBottom: "none"
	},
	tableHead: {
		color: "#262626",
		fontSize: 14,
		fontWeight: 500,
		lineHeight: "22px",
		borderBottom: "none"
	},
	tableCell: {
		color: "#555",
		fontSize: 16,
		fontWeight: 400,
		lineHeight: "24px"
	},
	gapCell: {
		color: COLORS.GREY_BACK_BUTTON,
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: 0.8,
	},
	weightLabel: {
		color: "#7C7C7C"
	}
}

const PSEStepComponents = ({
	stepComponents,
	priorStepData = [],
	expectedProduction = 0,
	coeff = 1,
	substitutes,
	isReusableStep = false,
	theoreticalGrossWeight
}) => {
	return (
		<TableContainer sx={{ border: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR, borderRadius: "6px", borderBottom: "none" }}>
			<Table sx={{ minWidth: 650 }} aria-label="pse table">
				<TableHead sx={{ bgcolor: COLORS.HEADER_BLUE }}>
					<TableRow>
						<TableCell sx={{ ...sx.emptyTableCell, ...sx.emptyTableHeadCell }} />
						<TableCell sx={sx.tableHead}>Ingrédients</TableCell>
						<TableCell sx={sx.tableHead}>Poids en entrée</TableCell>
						<TableCell sx={sx.tableHead}>Écart au poids théorique</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{stepComponents.map((stepComponent, index) => {
						let netWeight = roundNumber(stepComponent.grossWeight * expectedProduction * coeff, 3)

						if (isReusableStep) {
							netWeight = roundNumber(theoreticalGrossWeight)
						}
					
						let gap = ""
						if (stepComponent.priorSteps && stepComponent.priorSteps.index) {
							const currentPriorStepData = priorStepData.find(priorStep => priorStep.stepIndex === stepComponent.priorSteps.index)
							if (currentPriorStepData) {
								gap = roundNumber(currentPriorStepData.realNetWeight - netWeight, 3)
								netWeight = currentPriorStepData.realNetWeight
							}
						}

						return (
						<TableRow
							key={stepComponent.index + index}
							sx={{ height: 49 }}
						>
							<TableCell sx={sx.emptyTableCell} />
							<TableCell sx={sx.tableCell}>
								{getStepComponentName(stepComponent, substitutes)}
							</TableCell>
							<TableCell  sx={sx.tableCell}>
								<span>{roundNumber(netWeight, 2)}</span>
								<span style={sx.weightLabel}> kg</span>
							</TableCell>
							<TableCell sx={sx.gapCell}>
								<span>{
									gap > 0 ? "+" + gap :
									gap < 0 ? gap :
										""
								}
								</span>
								<span style={sx.weightLabel}>
									{gap ? " kg" : ""}
								</span>
							</TableCell>
						</TableRow>
					)})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default PSEStepComponents