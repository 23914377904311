import React from "react"
import { makeStyles } from "@mui/styles"
import { LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"
import clsx from "clsx"
import { COLORS } from "../../utils"
import PropTypes from "prop-types"

const useStyles = makeStyles({
  footerRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 6,
    "& > p": {
        margin: 0
    },
  },
  footerRowDivider: {
    borderTop: `1px solid ${COLORS.SITE_INFORMATION_VALUE}`,
  },
  footerRowHypotheticalDivider: {
    borderTop: "1px solid #E6E6E6",
  },
  footerRowUpdated: {
    borderTop: `1px solid ${COLORS.GREEN_LOT_INVENTORY_TEXT}`,
  },
  footerRowSelected: {
    borderTop: `1px solid ${COLORS.WHITE}`,
  },
  footerLabel: {
    fontSize: 8,
    fontWeight: 500,
    textTransform: "uppercase",
    color: COLORS.SITE_INFORMATION_VALUE,
},
footerData: {
    fontSize: 15,
    fontWeight: 500,
},
})

const getFooterLabel = (document, collection, mode) => {
  if (collection === "Lot") {
    if (document.hypothetical) {
      return "Manquante"
    }

    if (mode === LOT_OUTPUT_MODE) {
      return "À sortir"
    }

    return "Disponible"
  }
  if (collection === "SecondaryDLC") {
    return "Disponible"
  }
}

const getFooterData = (document, collection, mode) => {
  if (collection === "Lot") {
    return mode === LOT_OUTPUT_MODE ? document.quantityOutput : document.quantity
  }
    return document.quantity
}

const SelectableLotCardFooterContent = ({ 
  document,
  collection,
  mode,
  footerClassName = null,
  selected = false
 }) => {

  const classes = useStyles()

  const _getDividerClassName = (document) => {
    if (document.isUpdated && !document.isGrey && !selected && !document.hypothetical) {
      return classes.footerRowUpdated
    }
    if (selected) {
      return classes.footerRowSelected
    }
    if (document.hypothetical) {
      return classes.footerRowHypotheticalDivider
    }
    return classes.footerRowDivider
  }

  return (
    <div className={clsx(
      footerClassName || classes.footerRow,
      _getDividerClassName(document),
    )}
    >
      <p className={classes.footerLabel}>
        {getFooterLabel(document, collection, mode)}
      </p>
      <p className={classes.footerData}>
        {getFooterData(document, collection, mode)}
      </p>
    </div>
  )

}

export default SelectableLotCardFooterContent

SelectableLotCardFooterContent.propTypes = {
  document: PropTypes.object.isRequired,
  collection: PropTypes.oneOf(["Lot", "SecondaryDLC"]).isRequired,
  mode: PropTypes.string, // for lot only, not secondaryDLC
  footerClassName: PropTypes.string,
  selected: PropTypes.bool
}