import moment from "moment"
import dayjs from "dayjs"
import Parse from "parse"
import _cloneDeep from "lodash/cloneDeep"
import { orderBy } from "lodash"

import {
  getSitesStockZones,
  getSiteByStockZone,
  getStockZone,
} from "../../parseManager/site/parseSiteManager"
import {
  getLotsEvents,
  updateLot,
  getSubstituteIds,
  getSelectingLots,
  getLotsBySupplierItemsAndStockZones,
  getLot,
  getLotsEventsForOutput,
} from "../../parseManager/lot/parseLotManager"
import { actionWithLoader, downloadFile, onEnter, push } from "../Utils/utils"
import {
  getLotMode,
  getParamsFromPath,
  LOT_DISPATCH_MODE,
  LOT_INVENTORY_MODE,
  LOT_OUTPUT_MODE,
  LOT_RETURN_MODE,
  sortLots,
  getLotInventoryErrorObject,
  getLotsIngredientIds,
  LOT_HISTORY_MODE,
} from "../../utils/lotInventoryUtils"
import { getGroupsIngredients } from "../../parseManager/ingredients/classifications/group/parseGroupManager"
import { getDistributionCentersById } from "../../parseManager/distributionCenters/distributionCentersManager"
import { getServerUrl } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import {
  getSupplierItemHypotheticalTreated,
  getSupplierItemWithId,
} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { getProductTypeOptions } from "../ProductTypes/productTypes"
import { productTypeAllOption } from "../../utils/dispatchUtils"
import { getSiteStoredForLot } from "../../utils/lotsUtils"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"
import { getProductionNeedsByProductionDate, updateProductionNeedProvidedWeight } from "../../parseManager/productionNeeds/parseProductionNeedsManager"
import { generateStockUnitLabel } from "../../utils/orderReception"

export function loadLotFilter(isHistoryMode = false) {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const path = state.routing.locationBeforeTransitions.pathname

    const mode = isHistoryMode ? LOT_HISTORY_MODE : getLotMode(path)
    const sites = await getSitesStockZones()
    const outputDateSelected = JSON.parse(localStorage.getItem("outputDateSelected"))
    const outputTypeSelected = JSON.parse(localStorage.getItem("outputTypeSelected"))
    const siteSelected = JSON.parse(localStorage.getItem("siteSelected"))
    const stockZoneSelected = JSON.parse(localStorage.getItem("stockZoneSelected"))

    dispatch({
      type: "LOT_FILTER_LOADED",
      sites,
      mode,
      outputDateSelected: outputDateSelected ? outputDateSelected.date : null,
      outputTypeSelected,
      siteSelected,
      stockZoneSelected,
      tabValue: mode === LOT_HISTORY_MODE ? 1 : 0,
    })
  })
}

export function validateFilterSelection(values) {
  return actionWithLoader(async (dispatch) => {
    localStorage.setItem(
      "outputDateSelected",
      JSON.stringify({ date: values.date })
    )
    localStorage.setItem(
      "outputTypeSelected",
      JSON.stringify(values.outputType)
    )
    localStorage.setItem("siteSelected", JSON.stringify(values.site))
    localStorage.setItem("stockZoneSelected", JSON.stringify(values.stockZone))

    dispatch({
      type: "LOT_FILTER_DONE",
      stockZone: values.stockZone,
      date: values.date,
      mode: values.mode,
      outputTypeSelected: values.outputType,
      siteSelected: values.site,
      stockZoneSelected: values.stockZone,
    })
  })
}

export function loadLotMain() {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const path = state.routing.locationBeforeTransitions.pathname
    const params = getParamsFromPath(path)
    const mode = getLotMode(path)
    const date = params.date || moment.utc().startOf("day").valueOf()
    let parseStockZone = null

    const rules = (await new Parse.Query("PlanningRules").first()) || {}
    const isSwitchOutputProduction = rules.get("switchOutputProduction")

    if (params.stockZone !== 0) {
      parseStockZone = await getStockZone({
        stockZoneId: params.stockZone,
        toJson: false,
      })
    }

    const querySelect = [
      "events",
      "receptionDate",
      "dlc",
      "lotNumber",
      "quantity",
      "stockZone",
      "orderSupplierItem.name",
      "orderSupplierItem.type",
      "orderSupplierItem.commercialName.group.objectId",
      "orderSupplierItem.units.stock.weight",
      "orderSupplierItem.productType",
      "orderSupplierItem.defaultStockZone",
      "orderSupplierItem.supplierItemId",
    ]
    if (mode === LOT_OUTPUT_MODE) {
      querySelect.push(
        "stockZone.objectId",
        "orderSupplierItem.supplierItemId",
        "orderSupplierItem.type"
      )
    }

    let [
      site,
      groupIngredients,
      unsortedLots,
      outputInventoryData,
      productTypes,
    ] = await Promise.all([
      parseStockZone
        ? getSiteByStockZone(parseStockZone)
        : getSiteStoredForLot(),
      getGroupsIngredients([], ["objectId", "name"]),
      // load all lots with quantity > 0
      getSelectingLots(
        parseStockZone,
        querySelect,
        ["orderSupplierItem.commercialName.group"],
        true,
        mode
      ),
      // load all supplier items for production of the day (by production items or production needs)
      getOutputInventory(date, isSwitchOutputProduction),
      getProductTypeOptions(),
    ])

    dispatch({
      type: "PRODUCT_TYPE_OPTIONS_LOADED",
      productTypeOptions: [productTypeAllOption, ...productTypes],
    })

    // get all output lots based on the events (of the day, quantity = 0)
    let unsortedLotsForOutput = []
    if (mode === LOT_OUTPUT_MODE) {
      unsortedLotsForOutput = await getLotsEventsForOutput({
        stockZone: parseStockZone,
        productionDate: date,
        supplierItemIds: outputInventoryData.map((el) => el.ingredientId),
        include: ["orderSupplierItem.commercialName.group"],
        select: querySelect,
      })
    }

    const allUnsortedLots = [...unsortedLots, ...unsortedLotsForOutput]

    const lots = sortLots(allUnsortedLots, groupIngredients, null, productTypes)

    const currentDate = moment.utc().startOf("day").valueOf()
    // warn the lot DLC 5 days before its dlc
    const warningDate = moment().utc().add(5, "day").startOf("day").valueOf()

    // --------------------------------------------------------------- //
    // ---------------- SUBSTITUTE SUPPLIER ITEM IDS ----------------- //
    // --------------------------------------------------------------- //
    const toOutputSupplierItemIds = new Set(
      outputInventoryData?.map((el) => el.ingredientId)
    )
    const allLotsSupplierItemIds = new Set(getLotsIngredientIds(lots))
    const substituteIds = await getSubstituteIds(
      toOutputSupplierItemIds,
      allLotsSupplierItemIds
    )
    const stockZoneParams =
      params.stockZone != 0 ? [{ objectId: params.stockZone }] : []
    const substituteLots = await getLotsBySupplierItemsAndStockZones(
      Array.from(substituteIds),
      stockZoneParams,
      false
    )
    /** get supplier items that have been treated (closed) **/
    const supplierItemsHypotheticalTreated =
      await getSupplierItemHypotheticalTreated([...toOutputSupplierItemIds])

    dispatch({
      type: "LOT_MAIN_LOADED",
      isSwitchOutputProduction,
      site,
      stockZone: parseStockZone ? parseStockZone.toJSON() : null,
      mode,
      currentDate,
      warningDate,
      groupIngredients,
      lots,
      unsortedLots: allUnsortedLots,
      date,
      outputInventoryData,
      substituteIds,
      substituteLots,
      supplierItemsHypotheticalTreated,
    })
    // set damage report success snackbar if needed
    const localStorage = window.localStorage
    if (localStorage.getItem("showDamageReportNotification")) {
      dispatch({
        type: "LOT_INVENTORY_OPEN_DETAIL_SNACKBAR",
        inventoryOutputSnackBar: {
          open: true,
          duration: 5000,
          type: "success",
          message: "La casse a bien été déclarée",
        },
      })
      localStorage.removeItem("showDamageReportNotification")
    }
  })
}

export function loadLotsEventsMain(filters) {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const path = state.routing.locationBeforeTransitions.pathname
    const params = getParamsFromPath(path)

    const parseStockZone = await getStockZone({
      stockZoneId: params.stockZone,
      toJson: false,
    })
    const site = await getSiteByStockZone(parseStockZone)
    const result = await getLotsEvents({
      stockZone: parseStockZone,
      productionDate: params.date,
      include: ["orderSupplierItem"],
      filters,
      limit: 25
    })
    const lots = result.results
    const lotsEvents = []

    // row for each event of each lot
    for (const lot of lots) {
      const outPutEvents = lot
        .get("events")
        .filter(
          (event) =>
            event.mode === LOT_OUTPUT_MODE &&
            event.productionDate === params.date
        )
      for (const event of outPutEvents) {
        lotsEvents.push({
          lot: lot.toJSON(),
          event,
        })
      }
    }

    // sort data by event date
    const sortedLots = orderBy(lotsEvents, ["event.date"], ["desc"])
    dispatch({
      type: "LOT_SITE_AND_DATE_LOADED",
      site,
      stockZone: parseStockZone.toJSON(),
      date: params.date,
    })

    dispatch({
      type: "LOTS_OUTPUT_EVENTS_LOADED",
      lotsEvents: sortedLots,
    })

    dispatch({
      type: "LOTS_OUTPUT_FILTERS",
      filters: {
        total: result.count,
      },
    })
  })
}

export const loadReturnLotsData = (productionDate, filters) => {
  return actionWithLoader(async (dispatch) => {
    const result = await getLotsEvents({
      productionDate: productionDate,
      include: ["orderSupplierItem"],
      filters,
    })
    const lots = result.results
    const lotsEvents = []

    for (const lot of lots) {
      const outPutEvents = lot
        .get("events")
        .filter(
          (event) =>
            event.mode === LOT_OUTPUT_MODE &&
            event.productionDate === productionDate
        )
      for (const event of outPutEvents) {
        lotsEvents.push({
          lot: lot.toJSON(),
          event,
        })
      }
    }

    const sortedLots = orderBy(lotsEvents, ["event.date"], ["desc"])
    dispatch({
      type: "LOTS_OUTPUT_EVENTS_LOADED",
      mode: LOT_RETURN_MODE,
      lotsEvents: sortedLots,
    })
  })
}

export function addLotToInventory(lot) {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const unsortedLots = state.lotInventory.unsortedLots
    const groupIngredients = state.lotInventory.groupIngredients

    // we need to force it to display in the card list even if the quantity is 0
    const newLot = { ...lot, display: true }

    const productTypes = await getProductTypeOptions()
    const lots = sortLots(unsortedLots, groupIngredients, newLot, productTypes)

    dispatch({
      type: "LOT_INVENTORY_ADD_LOT",
      lots,
    })
  })
}

export function updateLotQuantity(lot, quantity, mode, productionDate) {
  return actionWithLoader(async (dispatch) => {
    try {
      let data
      const typedQuantity = parseFloat(quantity)
      const copy = _cloneDeep(lot)
      const date = moment.utc().valueOf()

      if (mode === LOT_INVENTORY_MODE) {
        data = [
          {
            mode: "INVENTORY",
            user: Parse.User.current().toJSON(),
            date: date,
            quantity: parseFloat(typedQuantity.toFixed(3)),
          },
        ]
      }

      if (mode === LOT_OUTPUT_MODE) {
        data = [
          {
            mode: "OUTPUT",
            user: Parse.User.current().toJSON(),
            date: date,
            quantity: parseFloat(typedQuantity.toFixed(3)),
            productionDate,
          },
        ]

        // for substitution output, we need to keep track of original item missing
        if (lot.isSubstitute) {
          data[0]["originalSupplierItem"] = await getSupplierItemWithId(
            lot.originalSupplierItem.objectId,
            [],
            false
          )
        }
        
        const rules = (await new Parse.Query("PlanningRules").first()) || {}
        const isSwitchOutputProduction = rules.get("switchOutputProduction")

        if (isSwitchOutputProduction) {
          const orderSupplierItemId = lot.originalSupplierItem ? lot.originalSupplierItem.objectId : lot.orderSupplierItem.supplierItemId
          const unitWeight = lot.orderSupplierItem.units.stock.weight
          await updateProductionNeedProvidedWeight(productionDate, orderSupplierItemId, typedQuantity, unitWeight)
        }
      }

      if (mode === LOT_RETURN_MODE) {
        data = [
          {
            mode: "RETURN",
            user: Parse.User.current().toJSON(),
            date: date,
            quantity: parseFloat(typedQuantity.toFixed(3)),
            productionDate,
          },
        ]
      }

      if (mode === LOT_INVENTORY_MODE) {
        copy.quantity = parseFloat(typedQuantity.toFixed(3))
      } else if (mode === LOT_RETURN_MODE) {
        copy.quantity = parseFloat(
          (parseFloat(copy.quantity) + typedQuantity).toFixed(3)
        )
      } else {
        copy.quantity = parseFloat(
          (parseFloat(copy.quantity) - typedQuantity).toFixed(3)
        )
      }

      const newLot = await updateLot(lot.objectId, copy.quantity, [
        ...copy.events,
        ...data,
      ])

      if (mode === LOT_RETURN_MODE) {
        return dispatch({
          type: "LOT_INVENTORY_OPEN_SNACKBAR",
          inventoryOutputSnackBar: {
            open: true,
            duration: 5000,
            type: "success",
            message: "La quantité a été rajoutée au stock",
          },
        })
      }

      if (!lot.isSubstitute) {
        return dispatch({
          type: "LOT_INVENTORY_SELECTION_UPDATED",
          selectedLot: newLot,
          inventoryOutputSnackBar: {
            open: true,
            duration: 5000,
            type: "success",
            message: "La mise à jour a été enregistrée",
          },
        })
      }
    } catch (e) {
      dispatch({
        type: "LOT_INVENTORY_OPEN_DETAIL_SNACKBAR",
        inventoryOutputSnackBar: {
          open: true,
          duration: 5000,
          type: "error",
          message: "Erreur lors de la mise à jour",
        },
      })
    }
  })
}

export function updateLotWithDamageReport(lot, values) {
  return actionWithLoader(async (dispatch) => {
    try {
      const { reason, damagedQuantity, comment, images } = values
      const newEvent = {
        mode: "BROKEN",
        user: Parse.User.current().toJSON(),
        date: moment.utc().valueOf(),
        quantity: parseFloat(damagedQuantity),
        reason,
        comment,
        images,
      }
      const copy = _cloneDeep(lot)
      copy.quantity = parseFloat(
        (parseFloat(copy.quantity) - parseFloat(damagedQuantity)).toFixed(3)
      )
      await updateLot(lot.objectId, copy.quantity, [...copy.events, newEvent])

      const localStorage = window.localStorage
      localStorage.setItem("showDamageReportNotification", true)
    } catch (e) {
      dispatch({
        type: "LOT_DAMAGE_REPORT_TOGGLE_SNACKBAR",
        lotDamageReportSnackBar: {
          open: true,
          duration: 5000,
          type: "error",
          message: "Erreur lors de la déclaration de casse",
        },
      })
    }
  })
}

export function updateHypotheticalLotAsTreated(lot, date) {
  return actionWithLoader(async (dispatch) => {
    try {
      const supplierItem = await new Parse.Query("SupplierItems")
        .equalTo("objectId", lot.orderSupplierItem.supplierItemId)
        .first()

      if (supplierItem) {
        const hypotheticalLotsTreated = supplierItem.get(
          "hypotheticalLotsTreated"
        )
          ? supplierItem.get("hypotheticalLotsTreated")
          : []
        supplierItem.set("hypotheticalLotsTreated", [
          ...hypotheticalLotsTreated,
          { stockZoneId: lot.stockZone.objectId, date: date },
        ])
        await supplierItem.save()
      }
    } catch (e) {
      dispatch({
        type: "LOT_INVENTORY_OPEN_DETAIL_SNACKBAR",
        inventoryOutputSnackBar: {
          open: true,
          duration: 5000,
          type: "error",
          message: "Erreur lors de la mise à jour",
        },
      })
    }
  })
}
/**
 * decrement lots quantity
 * if the current decremented lot is equal to 0, decrement the next lot
 */
export const decrementLotsQuantity = async (lots, volume, idHub) => {
  let quantity = volume
  const lotsCopy = _cloneDeep(lots)
  const hub = await getDistributionCentersById(idHub, [], false)
  const date = moment.utc().valueOf()

  const event = {
    mode: LOT_DISPATCH_MODE,
    user: Parse.User.current().toJSON(),
    date,
  }

  for (let [index, lot] of lotsCopy.entries()) {
    const lotCopy = _cloneDeep(lot)

    if (lots[index].quantity > 0) {
      if (lot.quantity >= quantity) {
        lotsCopy[index].quantity = lotsCopy[index].quantity - quantity
        quantity = quantity - lotsCopy[index].quantity
        event.quantity = lot.quantity

        const newEvent = {
          ...event,
          quantity: lot.quantity,
          hub,
        }
        const newLot = await updateLot(lot.objectId, lot.quantity, [
          ...lotCopy.events,
          newEvent,
        ])

        lotsCopy[index] = {
          ...lotsCopy[index],
          ...newLot,
        }
        break
      } else {
        quantity = quantity - lot.quantity
        lot.quantity = 0

        if (lots[index].quantity !== lot.quantity) {
          const newEvent = {
            ...event,
            quantity: lot.quantity,
            hub,
          }
          const newLot = await updateLot(lot.objectId, lot.quantity, [
            ...lotCopy.events,
            newEvent,
          ])
          lotsCopy[index] = {
            ...lotsCopy[index],
            ...newLot,
          }
        }
      }
    }
  }
  return lotsCopy
}

export function closeInventoryOutputSnackBar(currentType) {
  return actionWithLoader(async (dispatch) => {
    dispatch({
      type: "LOT_INVENTORY_CLOSE_DETAIL_SNACKBAR",
      inventoryOutputSnackBar: {
        open: false,
        type: currentType,
        message: "",
        duration: 1000,
      },
    })
  })
}
export function closeLotInventoryDamageReportSnackBar(currentType) {
  return actionWithLoader(async (dispatch) => {
    dispatch({
      type: "LOT_DAMAGE_REPORT_TOGGLE_SNACKBAR",
      lotDamageReportSnackBar: {
        open: false,
        type: currentType,
        message: "",
        duration: 1000,
      },
    })
  })
}

export function openInventoryOutputSnackBar() {
  return actionWithLoader(async (dispatch) => {
    dispatch({
      type: "LOT_INVENTORY_OPEN_SNACKBAR",
      inventoryOutputSnackBar: {
        open: true,
        type: "success",
        message: "Le lot est déjà présent dans l'inventaire",
        duration: 5000,
      },
    })
  })
}

export function onEnterLotMain(store) {
  return onEnter({
    store,
    actionThunk: loadLotMain,
  })
}

export function onEnterLotDamageReport(store) {
  return onEnter({
    store,
    actionThunk: (params) => {
      return async (dispatch) => {
        const parseStockZone = await getStockZone({
          stockZoneId: params.stockZone,
          toJson: false,
        })
        const site = parseStockZone
          ? await getSiteByStockZone(parseStockZone)
          : await getSiteStoredForLot()
        const lot = await getLot({
          id: params.lotId,
          toJson: true,
          include: ["orderSupplierItem"],
        })
        dispatch({
          type: "LOT_DAMAGE_REPORT_LOADED",
          selectedLot: lot,
          stockZone: parseStockZone ? parseStockZone.toJSON() : null,
          site,
        })
      }
    },
  })
}

export function onEnterLotsEvents(store) {
  return onEnter({
    store,
    actionThunk: loadLotsEventsMain,
  })
}

const loadLotEventsHistory = (params) => {
  return actionWithLoader(async (dispatch) => {
    const lotId = params.lotId
    const lot = await getLot({
      id: lotId,
      include: [
        "supplierItem.supplier",
        "orderSupplierItem",
        "events.originalSupplierItem",
        "stockZone",
      ],
    })
    dispatch({
      type: "SELECTED_LOT_LOADED",
      selectedLot: lot,
    })
  })
}

export const onEnterLotEventsHistory = (store) => {
  return onEnter({
    store,
    actionThunk: (params) => loadLotEventsHistory(params),
  })
}

export function onEnterLotFilter(store, isHistoryMode = false) {
  return onEnter({
    store,
    actionThunk: () => loadLotFilter(isHistoryMode),
  })
}

export function loadDisplayLotsFilterParams() {
  return async (dispatch, getState) => {
    const state = getState()

    // load only data in first time the modal is opened
    if (state.lotInventory.suppliers.length > 0) return

    const suppliers = await getSuppliers({ toJSON: true })

    dispatch({
      type: "LOT_INVENTORY_DISPLAY_FILTER",
      suppliers
    })
  }
}

export const downloadLotsInventoryCsv = (site) => {
  return actionWithLoader(async (dispatch) => {
    try {
      const siteId = site.objectId
      const url = `${getServerUrl()}/lots/extract?siteId=${siteId}`
      const response = await axiosCall("GET", url, null, {
        "Content-Type": "application/json",
      })

      if (response.status === 200 && response.data) {
        const fileName = `Inventaire_${site.name}_extrait le ${dayjs().format(
          "DD-MM-YYYY à HH:mm:ss"
        )}.csv`
        const csvURL = window.URL.createObjectURL(new Blob([response.data]))

        downloadFile(csvURL, fileName)
        return
      }

      dispatch(getLotInventoryErrorObject)
    } catch (err) {
      dispatch(getLotInventoryErrorObject)
    }
  })
}

/**
 *
 * @param {*} site
 * @param {Number} startDate timestamp of the start date
 * @param {Number} endDate timestamp of the end date
 * @returns
 */
export const downloadEventsLotsCsv = (site, startDate, endDate) => {
  return actionWithLoader(async (dispatch) => {
    const startDateStr = dayjs(startDate).format("YYYY-MM-DD")
    const endDateStr = dayjs(endDate).format("YYYY-MM-DD")

    try {
      const siteId = site.objectId
      const url = `${getServerUrl()}/lots/extractEvents?siteId=${siteId}&startDate=${startDateStr}&endDate=${endDateStr}`
      const response = await axiosCall("GET", url, null, {
        "Content-Type": "application/json",
      })

      if (response.status === 200 && response.data) {
        const fileName = `Sorties des productions du ${dayjs(startDate).format(
          "DD-MM-YYYY"
        )} au ${dayjs(endDate).format("DD-MM-YYYY")}_${
          site.name
        }_extrait le ${dayjs().format("DD-MM-YYYY à HH:mm:ss")}.csv`

        const csvURL = window.URL.createObjectURL(new Blob([response.data]))

        downloadFile(csvURL, fileName)
        return
      }

      dispatch(getLotInventoryErrorObject)
    } catch (err) {
      dispatch(getLotInventoryErrorObject)
    }
  })
}

export async function getOutputInventory(date, isSwitchProduction) {
  if (isSwitchProduction) {
    /** NEW WAY OF GETTING OUTPUT, FROM PRODUCTION NEEDS **/
    const productionNeeds = await getProductionNeedsByProductionDate({
      productionDate: date,
    })
    const result = []
    productionNeeds.forEach((productionNeed) => {
      result.push({
        ingredientId: productionNeed.supplierItem.objectId,
        ingredientName: productionNeed.supplierItem.name,
        nbUnit:
          productionNeed.initialNeededWeight - productionNeed.providedWeight,
        storageArea: productionNeed.supplierItem.stockZone
          ? productionNeed.supplierItem.stockZone.name
          : "Inconnue",
        unit: generateStockUnitLabel(productionNeed.supplierItem),
        units: productionNeed.supplierItem.units,
        weight: productionNeed.initialNeededWeight,
        providedWeight: productionNeed.providedWeight,
      })
    })

    return result
  } else {
    /** OLD WAY, GETTING OUTPUT FROM PRODUCTION ITEMS **/
    const url = `${getServerUrl()}/lots/getOutputInventory?date=${date}`
    const response = await axiosCall("GET", url, null, {
      "Content-Type": "application/json",
    })

    if (response.status === 200 && response.data) {
      return response.data
    }

    return []
  }
}

/** Routers **/
export function showLotFilter(mode) {
  if (mode === LOT_OUTPUT_MODE) {
    return push("/lotOutput")
  } else if (mode === LOT_HISTORY_MODE) {
    return push("/lotHistory")
  } else {
    return push("/lotInventory")
  }
}

export function showLotMain(stockZone, mode, date) {
  // stockZone = 0 is for all stock zone options
  if (stockZone || stockZone === 0) {
    if (mode === LOT_OUTPUT_MODE) {
      return push(`/lotOutput/${stockZone}/${date}`)
    } else {
      return push(`/lotInventory/${stockZone}`)
    }
  }
}

export function showLotDamageReport(stockZoneId, lotId) {
  return push(`/lotDamageReport/${stockZoneId}/${lotId}`)
}

export function showLotsEvents(stockZone, date) {
  return push(`/lotOutput/${stockZone}/${date}/events`)
}

export const showLotEventsHistory = (lotId) => {
  return push(`lotInventory/lotEventsHistory/${lotId}`)
}
