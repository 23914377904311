import React, { useEffect, useRef, useState } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  styled,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import { COLORS } from "../../utils"

import Autocomplete from "@mui/material/Autocomplete"
import { replacePackagingsSchema } from "../../utils/yupValidators"
import { formatPackagingsAutocompleteOption } from "../../utils/packaging"
import { useDispatch, useSelector } from "react-redux"
import { getPackagingsSelector } from "../../reducers/Packaging/packagingList"
import { loadPackagings } from "../../actions/Packaging/packaging"

const StyledInputLabel = styled(InputLabel)({
  fontWeight: 400,
  marginBottom: 2,
  fontSize: 18
})

const StyledHelperText = styled(FormHelperText)({
  fontSize: 18,
  marginTop: 4,
  fontWeight: 400
})

const useStyles = makeStyles({
  dialog: {
    maxWidth: 680,
    height: 422,
  },
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
})

const initialValues = {
  originalPackaging: null,
  replacedPackaging: null,
}

export const ReplacePackagingDialog = ({ onClose, open, onConfirm }) => {
  const [packagingOptions, setPackagingOptions] = useState([])

  const packagings = useSelector(getPackagingsSelector)
  const formikRef = useRef(null)
  const dispatch = useDispatch()

  const classes = useStyles()

  useEffect(() => {
    setPackagingOptions(formatPackagingsAutocompleteOption(packagings))
    dispatch(loadPackagings())
  }, [packagings])

  const handleConfirm = () => {
    formikRef.current.submitForm()
    if (!formikRef.current.values.originalPackaging || !formikRef.current.values.replacedPackaging) return
    onClose()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Remplacer un packaging dans toutes les recettes
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={replacePackagingsSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => {
            return (
              <Form>
                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <StyledInputLabel>Indiquez l’article fournisseur que vous souhaitez remplacer : </StyledInputLabel>
                  <Autocomplete
                    id="originalPackaging"
                    name="originalPackaging"
                    options={packagingOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => value && setFieldValue("originalPackaging", value)}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Packaging à remplacer"
                        value={values?.originalPackaging ? values.originalPackaging?.value : ""}
                        error={!!errors.originalPackaging}
                      />
                    )}
                  />
                  {errors.originalPackaging && (
                    <FormHelperText error>
                      {errors.originalPackaging}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <StyledHelperText>Indiquez le packaging qui le remplace dans toutes les recettes</StyledHelperText>
                  <Autocomplete
                    id="replacedPackaging"
                    name="replacedPackaging"
                    options={packagingOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => value && setFieldValue("replacedPackaging", value)}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    error={!!errors.originalSupplierItem}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Packaging de remplacement"
                        value={values?.replacedPackaging ? values.replacedPackaging?.value : ""}
                      />
                    )}
                  />
                  {errors.replacedPackaging && (
                    <FormHelperText error>
                      {errors.replacedPackaging}
                    </FormHelperText>
                  )}
                </FormControl>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions className="flexRow spaceBetween">
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReplacePackagingDialog
