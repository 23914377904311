import { DEFAULT_FILTERS } from "../../utils/constant"

const defaultSate = {
    reusableProductionSteps : [],
    filters: DEFAULT_FILTERS,
    total: 0,
    reusableProductionStep: null,
    supplierItems: []
}

export default function (state = defaultSate, action) {

    switch (action.type) {
        case "REUSABLE_STEPS_LOADED":
            return {
                ...state,
                reusableProductionSteps: action.reusableProductionSteps,
                supplierItems: action.supplierItems
            }
        case "REUSABLE_STEP_LOADED":
            return {
                ...state,
                reusableProductionStep: action.reusableProductionStep,
                isEdtion: action.isEdition,
                isNew: action.isNew
            }
        case "REUSABLE_STEPS_FILTERS_LOADED":
            return {
                ...state,
                filters: action.filters,
            }
        case "REUSABLE_STEPS_TOTAL_LOADED":
            return {
                ...state,
                total: action.total,
            }
        default:
            return {
                ...state
            }
    }
}

// ---------------------------------------- //
// --------------- SELECTOR --------------- //
// ---------------------------------------- //
export const getReusableProductionStepsSelector = (state) => {
    return state.steps.reusableProductionSteps
}

export const getReusableProductionStepSelector = (state) => {
    return state.steps.reusableProductionStep
}

export const getIsEditionReusableProductionStepSelector = (state) => {
    return state.steps.isEdtion
}

export const getIsNewReusableProductionStepSelector = (state) => {
    return state.steps.isNew
}

export const getReusableStepsSupplierItemsSelector = (state) => {
    return state.steps.supplierItems
}