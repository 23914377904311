import React from "react"
import ActionFooter from "../../components/common/ActionFooter"

const ProductionStepExecutionFooter = ({ rightAction }) => {
  return (
    <ActionFooter
      rightAction={rightAction}
    />
  )
}


export default ProductionStepExecutionFooter