import React from "react"
import { create } from "jss"
import preset from "jss-preset-default"
import StylesProvider from "@mui/styles/StylesProvider"
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles"
import createGenerateClassName from "@mui/styles/createGenerateClassName"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { blue, green, orange } from "@mui/material/colors"
import { COLORS } from "../utils"

//-------------------------------//
//-------------- JSS ------------//
//-------------------------------//
// code taken from https://material-ui-next.com/guides/right-to-left/
const jss = create(preset())

// very important for react-await-dialog
const generateClassName = createGenerateClassName()

//-------------------------------------------------------------------//
//--------------------------- contentLayout -------------------------//
//-------------------------------------------------------------------//
const theme = createTheme({
	primary: {
    main: blue,
  },
	typography: {
		useNextVariants: true,
    error: {
      fontSize: 14,
      color: "#F44259",
      lineHeight: "22px"
    },
    line: {
      fontSize: 14
    }
	},
	components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
		MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "16px 16px",
          "@media (max-width: 900px)": {
            padding: "16px 24px"
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const defaultValues = {}
          if (ownerState.color === "error") {
            if (ownerState.variant === "filled") {
              defaultValues.background = "#F44259"
            }
          }

          return defaultValues
        }
      }
    },
		MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const defaultValues = {
            display: "flex",
            alignItems: "center",
          }

          if (ownerState.severity === "success") {
            if (ownerState.variant === "filled") {
              defaultValues.background = green[600]
            }
          }

          if (ownerState.severity === "warning") {
            if (ownerState.variant === "contained") {
              defaultValues.background = "#FFF4E5"
              defaultValues.color = orange[800]
            }
          }

          if (ownerState.severity === "info") {
            if (ownerState.variant === "contained") {
              defaultValues.background = "#E5F6FD"
            }
          }
          
          return defaultValues
        },
        message: ({ ownerState, theme }) => {
          const palletteColor = ownerState.color || ownerState.severity || "info"
          let color = theme.palette[palletteColor].light

          if (ownerState.variant !== "filled") {
            color = COLORS.BLACK800
          } else {
						color = "#fff"
					}

          return {
            display: "flex",
            alignItems: "center",
            color,
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 400,
          }
        },
        icon: ({ ownerState, theme }) => {
					const palletteColor = ownerState.color || ownerState.severity || "info"
          let color = theme.palette[palletteColor].light

          if (ownerState.variant !== "filled") {
            color = theme.palette[palletteColor].main
          } else {
						color = "#fff"
					}

					return {
						color,
						fontSize: 20,
						opacity: 0.9,
						marginRight: theme.spacing(1),
					}
				},
      },
			defaultProps: {
        // The props to change the default for.
        variant: "filled", // No more ripple, on the whole application 💣!
      },
    },
	}
})

const CustomThemeProvider = (props) => {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						{props.children}
					</ThemeProvider>
				</StyledEngineProvider>
			</StylesProvider>
		</LocalizationProvider>
	)
}

export default CustomThemeProvider