import dayjs from "dayjs"

import { TRANSFORMATION_TYPES } from "./supplierItemUtils"
import { composes, roundNumber } from "../utils"

export const STEP_DATES = [
	{ value: -1, label: "J-1" },
	{ value: 0, label: "Jour J" },
	{ value: 1, label: "J+1" },
]

export const END_STEP_WEIGHING = [
	{ value: true, label: "Pesée nécessaire" },
	{ value: false, label: "Ne pas peser" }
]

export const DEFAULT_STEP_DATE = STEP_DATES[1].value

export const PRODUCTION_STEP_EXECUTION_STATUSES = [
	{ value: "TODO", name: "À démarrer" },
	{ value: "LOCKED", name: "En attente" },
	{ value: "TO_TEST", name: "À tester" },
	{ value: "IN_PROGRESS", name: "En cours" },
	{ value: "DONE", name: "Terminé" }
]

export const getPSEStatusLabel = (status) => {
	const currentOption = PRODUCTION_STEP_EXECUTION_STATUSES.find(option => option.value === status)
	if (!currentOption) {
		return ""
	}

	return currentOption.name
}

export const getStepDateLabelByValue = (value) => {
	const currentStepDate = STEP_DATES.find(stepDate => stepDate.value === value)
	return currentStepDate?.label
}

export const getEndStepWeighingLabelByValue = (value) => {
	const currentStepWeight = END_STEP_WEIGHING.find(stepWeight => stepWeight.value === value)
	return currentStepWeight?.label
}

export const formatProductionStepsHoursEditInitialValues = (productionStepExecutions) => {
	const values = productionStepExecutions.map((productionStepExecution) => ({
		objectId: productionStepExecution.objectId,
		theoreticalStartTime: productionStepExecution.theoreticalStartTime ? dayjs(productionStepExecution.theoreticalStartTime).format("HH:mm") : "",
		theoreticalEndTime: productionStepExecution.theoreticalEndTime ? dayjs(productionStepExecution.theoreticalEndTime).format("HH:mm") : "",
		name: productionStepExecution.productionStepSnapshot.name,
		transformationType: TRANSFORMATION_TYPES.find(t => t.value === (productionStepExecution.productionStepSnapshot.transformation || ""))?.label,
		machines: (productionStepExecution.machinesBatch || []).map((machine) => machine.machine.objectId) || [],
		section: productionStepExecution.section.name,
		recipe: `${productionStepExecution.recipe.uniqueCode} - ${productionStepExecution.recipe.commercialName}`,
		status: productionStepExecution.status
	}))

	return {
		productionStepsExecutions: values
	}
}

export const PSE_REGEX_HOURS = new RegExp(/^(?:[01]\d|2[0-3]):[0-5]\d$/)

export const editableHourToTimestamp = (hour, day) => {
	const [hours, minutes] = hour.split(":")
	const date = new Date(day)
	date.setHours(hours)
	date.setMinutes(minutes)
	return date.valueOf()
}

export const sortPSEByUniqueCode = (pseA, pseB) => {
	const uniqueCodeA = pseA.get ? pseA.get("uniqueCode") : pseA.uniqueCode
	const uniqueCodeB = pseB.get ? pseB.get("uniqueCode") : pseB.uniqueCode

	return uniqueCodeA && uniqueCodeB && uniqueCodeA.localeCompare(uniqueCodeB)
}

export const sortPSEBySectionName = (pseA, pseB) => {
	const sectionNameA = pseA.get ? pseA.get("sectionName") : pseA.sectionName
	const sectionNameB = pseB.get ? pseB.get("sectionName") : pseB.sectionName
	return sectionNameA && sectionNameB && sectionNameA.localeCompare(sectionNameB)
}

export const sortPSEByStepNumber = (pseA, pseB) => {
	const stepNumberA = pseA.get ? pseA.get("productionStepSnapshot").stepNumber : pseA.productionStepSnapshot.stepNumber
	const stepNumberB = pseB.get ? pseB.get("productionStepSnapshot").stepNumber : pseB.productionStepSnapshot.stepNumber
	return stepNumberA && stepNumberB && stepNumberA - stepNumberB
}

export const sortPSEByTheoreticalStartTime = (pseA, pseB) => {
	const theoreticalStartTimeA = pseA.get ? pseA.get("theoreticalStartTime") : pseA.theoreticalStartTime
	const theoreticalStartTimeB = pseB.get ? pseB.get("theoreticalStartTime") : pseB.theoreticalStartTime
	// sorting fallbacks if no theoretical times specified
	// (each gets triggered if previous returned 0) 
	if (!theoreticalStartTimeA && !theoreticalStartTimeB) {
		return sortPSEByUniqueCode(pseA, pseB) || sortPSEBySectionName(pseA, pseB) || sortPSEByStepNumber(pseA, pseB)
	}
	if (!theoreticalStartTimeA && theoreticalStartTimeB) {
		return 1
	}
	if (theoreticalStartTimeA && !theoreticalStartTimeB) {
		return -1
	}
	return theoreticalStartTimeA - theoreticalStartTimeB
}

export const sortByTimeField = (pseA, pseB, timeFieldName) => {
	const pseAField = pseA.get ? pseA.get(timeFieldName) : pseA[timeFieldName]
	const pseBField = pseB.get ? pseB.get(timeFieldName) : pseB[timeFieldName]
	if (!pseAField && pseBField) {
		return 1
	}
	if (pseAField && !pseBField) {
		return -1
	}
	return pseAField - pseBField
}

/**
 * @param {Array} productionStepsExecutions 
 * @returns {Array}
 */

export const sortPSEByTimeFields = (productionStepsExecutions) => {
	return [
		...productionStepsExecutions.filter((pse) => ["TODO", "LOCKED"].includes(pse.get("status"))).sort((a, b) => sortPSEByTheoreticalStartTime(a, b, "theoreticalStartTime")),
		...productionStepsExecutions.filter((pse) => pse.get("status") === "IN_PROGRESS").sort((a, b) => sortByTimeField(a, b, "startTime")),
		...productionStepsExecutions.filter((pse) => pse.get("status") === "DONE").sort((a, b) => sortByTimeField(a, b, "endTime")),
		...productionStepsExecutions.filter((pse) => pse.get("status") === "TO_TEST").sort((a, b) => sortByTimeField(a, b, "endTime"))
	]
}

export const sortPEByTimeFields = (packagingExecutions) => {
	return [
		...packagingExecutions.filter((pe) => ["LOCKED", "WEIGHT_TO_VALIDATE", "TODO"].includes(pe.get ? pe.get("status") : pe.status))
			.sort((a, b) => sortByTimeField(a, b, "theoreticalStartTime")),
		...packagingExecutions.filter((pe) => ["IN_PROGRESS", "WASTE_TO_WEIGHT"].includes(pe.get ? pe.get("status") : pe.status))
			.sort((a, b) => sortByTimeField(a, b, "startTime")),
		...packagingExecutions.filter((pe) => (pe.get ? pe.get("status") : pe.status) === "DONE")
			.sort((a, b) => sortByTimeField(a, b, "endTime"))
	]
}

export const formatInterval = (minutes) => {
	const interval = [Math.floor(minutes / 60).toString(), (minutes % 60).toString()]
	return interval[0].padStart(2, "0") + "h" + interval[1].padStart(2, "0")
}

export const getInterval = (from, to) => {
	const [hoursA, minutesA] = from.split(":")
	const [hoursB, minutesB] = to.split(":")
	const timeA = dayjs().hour(hoursA).minute(minutesA)
	const timeB = dayjs().hour(hoursB).minute(minutesB)
	const interval = timeB.diff(timeA, "minutes")

	if (interval < 0) {
		return formatInterval(24 * 60 + timeB.diff(timeA, "minutes"))
	}

	return formatInterval(interval)
}

export const getModalPSETitle = (endStepWeighing, isCooling, isCooking) => {
	if (isCooling) {
		let isCoolingTitle = "Saisissez l'heure à laquelle les produits ont terminé leur refroidissement et leur température"
		if (endStepWeighing !== false) {
			isCoolingTitle += ", et le poids réel en sortie."
		}

		return isCoolingTitle
	}
	if (isCooking) {
		let isCookingTitle = "Saisissez l'heure à laquelle les produits ont terminé leur cuisson et leur température"
		if (endStepWeighing !== false) {
			isCookingTitle += ", et le poids réel en sortie."
		}

		return isCookingTitle
	}

	if (endStepWeighing === false) {
		return "Êtes-vous sûr de vouloir terminer l'étape de production ?"
	}

	return "Saisissez le poids réel en sortie pour terminer l'étape de production."
}


export const getPriorStepData = (productionStepExecution) => {
  const priorStepData = []
  productionStepExecution.priorSteps && productionStepExecution.priorSteps.forEach(priorStep => {
    if (priorStep.realNetWeight && priorStep.step && priorStep.step.productionStepSnapshot) {
      priorStepData.push({
        realNetWeight: priorStep.realNetWeight,
        stepIndex: priorStep.step.productionStepSnapshot.index
      })
    }
  })
}

export const PSE_SUBSTATUSES = {
	/** KFC-2268 hide lotsSelection case temporarily **/
	// lotsSelection: "LOTS_SELECTION",
	stepCompletion: "STEP_COMPLETION",
	temperatureValidation: "TEMPERATURE_VALIDATION",
	weightValidation: "WEIGHT_VALIDATION",
	insufficientWeight: "INSUFFICIENT_WEIGHT"
}

export const PSE_SUBSTATUSES_AND_LABELS = [
	// { value: PSE_SUBSTATUSES.lotsSelection, label: "Sélection des lots" },
	{ value: PSE_SUBSTATUSES.stepCompletion, label: "Réalisation de l'étape" },
	{ value: PSE_SUBSTATUSES.temperatureValidation, label: "Saisie de la température finale" },
	{ value: PSE_SUBSTATUSES.weightValidation, label: "Saisie du poids final" },
]

export const PSE_SUBSTATUSES_AND_LABELS_WITH_INSUFFICIENT_WEIGHT = [
	...PSE_SUBSTATUSES_AND_LABELS,
	{ value: PSE_SUBSTATUSES.insufficientWeight, label: "Quantité insuffisante" },
]

export const getPSESubStatusLabel = (subStatus) => {
	const currentOption = PSE_SUBSTATUSES_AND_LABELS_WITH_INSUFFICIENT_WEIGHT.find(option => option.value === subStatus)
	if (!currentOption) return ""

	return currentOption.label
}

export const isPSEInsufficientWeight = (subStatus) => {
	return subStatus === PSE_SUBSTATUSES.insufficientWeight
}

export const getPSEWeightGap = (productionStepExecution) => {
	return roundNumber(productionStepExecution.netWeight - productionStepExecution.theoreticalNetWeight, 2)
}

export const computeFirstSubStatus = (productionStepExecution) => {
	const applicableSubstatuses = filterPSESubstatuses(productionStepExecution)
	return applicableSubstatuses[0].value
}

export const calculateNextSubStatus = (productionStepExecution) => {
  const applicableSubstatuses = filterPSESubstatuses(productionStepExecution)
	const currentSubStatus = productionStepExecution.subStatus
	const currentSubStatusIndex = applicableSubstatuses.findIndex(substatus => substatus.value === currentSubStatus)
	const nextSubStatus = applicableSubstatuses[currentSubStatusIndex + 1] // 0 if there was none before
	return nextSubStatus ? nextSubStatus.value : null
}

export const filterPSESubstatusesByTransformationType = (transformationType) => {
	return (substatuses) => {
		if (!["COOKING", "COOLING"].includes(transformationType)) {
			return substatuses.filter(substatus => substatus.value !== PSE_SUBSTATUSES.temperatureValidation)
		}
		return substatuses
	}
}

export const filterPSESubStatusesByEndWeighing = (endWeighing) => {
	return (substatuses) => {
		if (!endWeighing) {
			return substatuses.filter(substatus => substatus.value !== PSE_SUBSTATUSES.weightValidation)
		}
		return substatuses
	}
}

export const filterPSESubStatusesBySupplierItem = (hasSupplierItem) => {
	return (substatuses) => {
		if (!hasSupplierItem) {
			return substatuses.filter(substatus => substatus.value !== PSE_SUBSTATUSES.lotsSelection)
		}
		return substatuses
	}
}

export const filterPSESubstatuses = (pse) => {
	// const hasSupplierItem = pse.productionStepSnapshot.stepComponents.some(stepComponent => !!stepComponent.supplierItem)
	const filteredStatuses = composes(
		filterPSESubstatusesByTransformationType(pse.productionStepSnapshot.transformation),
		filterPSESubStatusesByEndWeighing(pse.productionStepSnapshot.endStepWeighing),
		/** KFC-2268 hide lotsSelection case temporarily **/
		// filterPSESubStatusesBySupplierItem(hasSupplierItem)
	)(PSE_SUBSTATUSES_AND_LABELS)
	return filteredStatuses
}

/**
 * change the substatus of a PSE to weightValidation if the substatus is insufficientWeight
 * this is mainly used for the substatus progress bar
 * insufficentWeight is a substatus that is not displayed in the progress bar
 * use weightValidation instead
 * @param {*} pse 
 * @returns 
 */
export const forcePSESubStatusToWeightValidation = (pse) => {
	const newPse = { ...pse }
	if (isPSEInsufficientWeight(newPse.subStatus)) {
		newPse.subStatus = PSE_SUBSTATUSES.weightValidation
	}

	return newPse
}

export const updateSubStatusForLowNetWeight = (productionStepExecution) => {
	const netWeight = productionStepExecution.netWeight
	const theoreticalWeight = productionStepExecution.theoreticalWeight

	if (netWeight < theoreticalWeight * 0.95) {
		productionStepExecution.subStatus = PSE_SUBSTATUSES.weightValidation
	}
	
}

const getFivePercentTheoreticalWeight = (theoreticalWeight) => {
  const newTheoreticalWeight = (5 * theoreticalWeight) / 100

  return newTheoreticalWeight
}

export const getInsufficientWeight = (netWeight, theoreticalWeight) => {
	const weightDiff = theoreticalWeight - netWeight

	const fivePercentWeight = getFivePercentTheoreticalWeight(theoreticalWeight)

  return weightDiff > fivePercentWeight
}

export const handlePSEWeighingMergedError = (theoreticalNetWeight, pseTheoreticalNetWeightFilled, totalTheoreticalNetWeight) => {
	let errorMessage = null
	if (theoreticalNetWeight.length === 0) {
		errorMessage = "Veuillez indiquer la répartition de la quantité par recette en fonction du poids final pesé."
	} else if (pseTheoreticalNetWeightFilled !== 0) {
		if (pseTheoreticalNetWeightFilled < totalTheoreticalNetWeight) {
			errorMessage = "L’ensemble des quantités saisies est inférieur au poids final pesé."
		} else if (pseTheoreticalNetWeightFilled > totalTheoreticalNetWeight) {
			errorMessage = "L’ensemble des quantités saisies est supérieur au poids final pesé."
		}
	}

	return errorMessage
}
