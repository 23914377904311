import React from "react"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"
import GenericBottomBarFooter from "../../components/GenericBottomBarFooter"

const useStyles = makeStyles(() => ({
  footerBlock: {
    flex: "0 0 96px",
    position:"relative",
    bottom:"0",
    width:"100%",
    boxShadow: "0px -1px 2px rgba(0, 0, 0, 0.15)"
  },
  footer: {
    width: "100%",
    height: "96px",
  }
}))

const PackagingExecutionFooter = ({ rightAction = null }) => {

  const classes = useStyles()

  return (
    <Box className={classes.footerBlock}>
      <GenericBottomBarFooter
        className={classes.footer}
        rightAction={rightAction}
      />
  </Box>
  )
}

export default PackagingExecutionFooter