import Parse from "parse"

export const MergeHistory = Parse.Object.extend("MergeHistory")

export const saveMergeHistory = async (reusableStep, stepProductionDate, user = null) => {
	try {
		const mergeHistory = new MergeHistory()
		mergeHistory.set("reusableStep", reusableStep)
		mergeHistory.set("stepProductionDate", stepProductionDate)
		mergeHistory.set("user", user)

		await mergeHistory.save()
	}
	catch (e) {
		console.error("parseManager.mergeHistory.mergeHistory.saveMergeHistory error : ", e)
		return Promise.reject(e)
	}
}
